<template>
  <div
    :class="`${isSelfCheckout || editingTemplate ? '' : 'grey-background'}`"
    style="min-height: 100%"
  >
    <v-container
      fluid
      :class="`${editingTemplate ? 'px-0' : 'px-4 pt-4 pb-8 mt-0 mx-0'} `"
    >
      <v-row class="ma-0 pa-0">
        <v-col
          v-if="!isSelfCheckout"
          sm="12"
          :md="`${editingTemplate ? '0' : '3'}`"
        >
          <v-card
            outlined
            :style="`background-color: white; ${
              editingTemplate ? '' : 'height: 100%'
            }`"
          >
            <v-select
              solo
              v-if="$vuetify.breakpoint.smAndDown || editingTemplate"
              :items="menus"
              hide-details
              item-value="key"
              :value="selectedMenu"
              @change="selectMenu"
            >
              <template v-slot:selection="data">
                <v-card
                  :class="`${
                    isMenuSelected(data.item.key) ? 'menu-active' : 'menu-tabs'
                  } pa-4 d-flex align-start text-start elevation-0 rounded-0`"
                  :disabled="data.item.disabled"
                >
                  <v-icon
                    :color="
                      isMenuSelected(data.item.key) ? 'accentSuite' : 'black'
                    "
                    class="mr-4"
                  >
                    {{ data.item.icon }}
                  </v-icon>
                  <div>
                    <p class="font-body-2 font-weight-bold ma-0 text-none">
                      {{ data.item.title }}
                    </p>
                    <p class="ma-0 text-none text-caption">
                      {{ data.item.subtitle }}
                    </p>
                  </div>
                </v-card>
              </template>
              <template v-slot:item="data">
                <v-card
                  :class="`${
                    isMenuSelected(data.item.key)
                      ? 'menu-active list-mobile-active'
                      : 'menu-tabs'
                  } pa-4 d-flex align-start text-start elevation-0 rounded-0`"
                  style="border-bottom: 1px solid #e0e0e0"
                  :disabled="data.item.disabled"
                  width="100%"
                >
                  <v-icon
                    :color="
                      isMenuSelected(data.item.key) ? 'accentSuite' : 'black'
                    "
                    class="mr-4"
                  >
                    {{ data.item.icon }}
                  </v-icon>
                  <div>
                    <p class="font-body-2 font-weight-bold ma-0 text-none">
                      {{ data.item.title }}
                    </p>
                    <p class="ma-0 text-none text-caption">
                      {{ data.item.subtitle }}
                    </p>
                  </div>
                </v-card>
              </template>
            </v-select>
            <div v-else>
              <div
                v-for="menu in menus"
                :key="menu.key"
                @click="menu.disabled ? showPermissionDialog(menu.key) : ''"
              >
                <v-card
                  :class="`${
                    isMenuSelected(menu.key) ? 'menu-active' : 'menu-tabs'
                  } pa-4 d-flex align-start text-start elevation-0 rounded-0`"
                  style="border-bottom: 1px solid #e0e0e0"
                  @click="selectMenu(menu.key)"
                  :disabled="menu.disabled"
                >
                  <v-icon
                    :color="isMenuSelected(menu.key) ? 'accentSuite' : 'black'"
                    class="mr-4"
                  >
                    {{ menu.icon }}
                  </v-icon>
                  <div>
                    <p class="font-body-2 font-weight-bold ma-0 text-none">
                      {{ menu.title }}
                    </p>
                    <p class="ma-0 text-none text-caption">
                      {{ menu.subtitle }}
                    </p>
                  </div>
                </v-card>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          sm="12"
          :md="`${isSelfCheckout || editingTemplate ? '12' : '9'}`"
        >
          <v-card
            :class="`pb-4 ${isSelfCheckout ? '' : 'larger-height'}`"
            :outlined="!isSelfCheckout && !editingTemplate"
            elevation="0"
            style="background-color: white"
          >
            <div
              :class="`${
                $vuetify.breakpoint.smAndDown || editingTemplate
                  ? 'mr-5'
                  : 'pa-4'
              } ${isEditableMenuSelected ? 'editable-menu' : 'info-menu'}`"
            >
              <CompanyProfileInfo
                ref="company_info"
                v-if="isMenuSelected('company_info')"
                :companyProfile="companyProfile"
                :logoUrl="tagsEdit.logo_url"
                @updateCompanyInfo="companyDataChanged"
                @updateLogoUrl="showImageUploader = true"
                @removeLogoUrl="removeImageUrl"
              />
              <CompanyContactInfo
                ref="contact_info"
                @updateTagsInfo="updateTagsInfo"
                v-if="isMenuSelected('contact_info')"
              />

              <CompanyMarketingInfo
                ref="marketing_info"
                v-if="isMenuSelected('marketing_info')"
                :bannerUrl="tagsEdit.banner_url"
                @updateTagsInfo="updateTagsInfo"
                @updateBannerUrl="showImageUploader = true"
                @removeBannerUrl="removeImageUrl"
              />

              <CompanyCustomInfo
                ref="custom_info"
                @updateTagsInfo="updateTagsInfo"
                @updateCustomTagsInfo="updateCustomTagsInfo"
                v-if="isMenuSelected('custom_info')"
              />

              <CompanyBillingInfo
                ref="billing"
                :isSelfCheckout="isSelfCheckout"
                :companyProfile="companyProfile"
                v-if="isMenuSelected('billing')"
                @updateCompanyInfo="companyDataChanged"
              />

              <CompanyPlansInfo
                ref="plans_info"
                v-if="isMenuSelected('plans_info')"
              />
            </div>
            <div
              v-if="isEditableMenuSelected"
              class="d-flex align-center justify-end mb-1 px-4 pt-4"
              style="border-top: 1px solid #e0e0e0"
            >
              <v-btn
                :x-large="!$vuetify.breakpoint.smAndDown || !editingTemplate"
                elevation="0"
                text
                class="text-none mr-8"
                @click="resetFields()"
                >{{ $t("action.clearFields") }}</v-btn
              >
              <v-menu
                v-if="isEditingTags"
                open-on-hover
                bottom
                left
                offset-overflow
                offset-y
                class="mx-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="accentSuite"
                    class="white--text text-none"
                    x-large
                    elevation="0"
                    :loading="loadingSavingData"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="cantSaveTags"
                  >
                    {{ $t("action.save") }}
                  </v-btn>
                </template>
                <v-list nav class="font-weight-medium" width="400">
                  <v-list-item @click.capture="showConfirmationDialog = true">
                    <v-list-item-icon class="mr-4">
                      <v-icon
                        v-text="'mdi-check-circle-outline'"
                        color="accentSuite"
                      />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ $t("action.saveOnly") }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-wrap font-weight-regular mt-1 caption"
                      >
                        {{ $t("companyProfileForm.saveWithoutReapply") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <div style="position: relative">
                    <v-list-item
                      v-if="!isCompanyMicrosoft"
                      @click="saveAndReapplyTags"
                      :disabled="!companyHasSignActive || !hasSignPermission"
                    >
                      <v-list-item-icon class="mr-4">
                        <v-icon
                          v-text="'mdi-update'"
                          color="accentSuite"
                          :disabled="
                            !companyHasSignActive || !hasSignPermission
                          "
                        />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("action.saveAndReapply")
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          class="text-wrap font-weight-regular mt-1 caption"
                        >
                          {{ $t("companyProfileForm.saveAndReaply") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <div
                      v-if="!companyHasSignActive || !hasSignPermission"
                      class="sign-permission-overlay"
                    >
                      <div class="sign-plan-text">
                        <v-tooltip
                          v-if="!companyHasSignActive || !hasSignPermission"
                          :color="
                            !companyHasSignActive
                              ? 'primarySign'
                              : 'primarySuite'
                          "
                          top
                        >
                          <template v-slot:activator="{ on }">
                            <div
                              class="d-flex justify-center align-center"
                              v-on="on"
                            >
                              <v-icon
                                :color="
                                  !companyHasSignActive
                                    ? 'primarySign'
                                    : 'primarySuite'
                                "
                                >{{
                                  !companyHasSignActive
                                    ? "mdi-crown"
                                    : "mdi-information"
                                }}</v-icon
                              >
                              <span class="ml-2">
                                {{
                                  !companyHasSignActive
                                    ? $t("common.unavailableOnYourPlan")
                                    : $t("common.doNotHavePermission")
                                }}
                              </span>
                            </div>
                          </template>
                          <span
                            :class="`${
                              !companyHasSignActive
                                ? 'black--text'
                                : 'white--text'
                            }`"
                            >{{
                              !companyHasSignActive
                                ? $t("companyProfile.reapplyUnavailablePlan")
                                : $t(
                                    "companyProfile.reapplyUnavailablePermission"
                                  )
                            }}</span
                          >
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </v-list>
              </v-menu>

              <v-btn
                v-else
                @click="updateCompany()"
                :loading="showLoading"
                :x-large="!$vuetify.breakpoint.smAndDown || !editingTemplate"
                elevation="0"
                :disabled="!canSaveData"
                color="accentSuite"
                class="text-none white--text"
                >{{ $t("action.save") }}</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>

      <ImageUploader
        :show="showImageUploader"
        :user="currentUser"
        :token="token"
        :selectedTagImageURL="
          isEditingLogo ? tagsEdit['logo_url'] : tagsEdit['banner_url']
        "
        @close="showImageUploader = false"
        @update="saveUrl"
      />

      <HeaderDialog
        id="reapply-model"
        :title="$t('companyProfile.reapplyDialogTitle')"
        color="primarySuite"
        :show="showReapplyDialog"
        :loading="reapplyLoading"
        width="700"
        :disabledAction="reapplyLoading"
        showCancel
        button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
        :actionText="
          impactedTemplates.length
            ? $t('action.saveAndReapply')
            : $t('action.saveChanges')
        "
        :closeText="$t('action.cancel')"
        @close="showReapplyDialog = false"
        @action="reapplyModels"
      >
        <template v-slot:body v-if="impactedTemplates.length">
          <p>{{ $t("companyProfile.reapplyDialogSubtitle") }}</p>
          <p>
            <small v-html="$t('companyProfile.reapplyHint')"></small>
          </p>
          <v-autocomplete
            v-model="templatesToReapply"
            :items="impactedTemplates"
            :label="$t('companyProfile.affectedModels')"
            multiple
            chips
            outlined
            class="mt-5"
            color="accentSuite"
            dense
            item-value="id"
          >
            <template v-slot:selection="{ attrs, item, selected, parent }">
              <v-chip
                v-bind="attrs"
                :color="`${item.color} lighten-3`"
                class="ma-1"
                small
                :input-value="selected"
              >
                <span class="pr-2">
                  {{ item.name }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                  v-text="'mdi-close-circle'"
                />
              </v-chip>
            </template>

            <template v-slot:item="{ active, item, attrs, on }">
              <template>
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  :color="active ? 'accent lighten-3' : 'white'"
                >
                  <v-list-item-content>
                    <v-list-item-title v-html="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-autocomplete>
          <Alert :prominent="false" color="warning" dense :width="800">
            {{ $t("companyProfile.signatureModelsAlert") }}
          </Alert>
        </template>
        <template v-slot:body v-else>
          <Alert :prominent="false" color="warning" dense :width="800">
            {{ $t("companyProfile.noSignatureModelsAffected") }}
          </Alert>
        </template>
      </HeaderDialog>
      <SimpleDialog
        :show="showConfirmationDialog"
        :title="$t('companyProfile.confirmInfoChanges')"
        :customButtom="true"
        outlined
        color="primarySuite"
        button-class="white--text"
        dense
        showCancel
        :showLoading="loadingSavingData"
        :disabledAction="loadingSavingData"
        :closeText="$t('action.cancel')"
        @close="showConfirmationDialog = false"
        @action="saveOrgUnitTags()"
      >
      </SimpleDialog>

      <!-- MODAL DE FEEDBACK -->
      <HeaderDialog
        v-if="
          renewFeedbackOnSecondTime(currentUser.feedbacks.edit_company_tags) &&
          checkShowFeedback('edit_company_tags')
        "
        ref="form"
        id="user_feedback"
        :title="$t('common.feedbackRatingTitle')"
        :show="showUserFeedback"
        width="600"
        :persistent="false"
        custom-footer
        @close="closeFeedback"
      >
        <template v-slot:body>
          <v-row class="mx-0">
            <v-col class="mx-0">
              <FeedbackRating
                action="edit_company_tags"
                showDismiss
                @close="closeFeedback"
              />
            </v-col>
          </v-row>
        </template>
      </HeaderDialog>
    </v-container>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import {
  renewFeedbackOnSecondTime,
  setNotShowFeedbackToday,
  checkShowFeedback,
  validateCnpj,
} from "@/helpers/services/utils";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { companyTags, editableCompanyTags } from "@//helpers/variables/tags";
import { impactedTemplateIds } from "@/helpers/services/utils";
import CompanyProfileInfo from "@/components/company_profile/CompanyProfileInfo";
import CompanyContactInfo from "@/components/company_profile/companyContactInfo";
import CompanyMarketingInfo from "@/components/company_profile/companyMarketingInfo";
import CompanyCustomInfo from "@/components/company_profile/companyCustomInfo";
import CompanyBillingInfo from "@/components/company_profile/companyBillingInfo";
import CompanyPlansInfo from "../company_profile/companyPlansInfo.vue";
import FeedbackRating from "@/components/forms/FeedbackRating.vue";
import ImageUploader from "@/components/sign/general/ImageUploader.vue";
import { changeDriveURL } from "@/helpers/services/images";

export default {
  directives: { mask },

  name: "CompanyProfileForm",

  props: {
    companyProfile: {
      type: Object,
      default: () => ({}),
    },
    isSelfCheckout: { type: Boolean, default: false },
    startingMenu: { type: String, default: "" },
  },

  components: {
    CompanyProfileInfo,
    CompanyContactInfo,
    CompanyMarketingInfo,
    CompanyCustomInfo,
    CompanyBillingInfo,
    CompanyPlansInfo,
    FeedbackRating,
    ImageUploader,
  },

  data: () => ({
    loadingSavingData: false,
    reapplyLoading: false,
    showLoading: false,
    showReapplyDialog: false,
    showConfirmationDialog: false,
    showUserFeedback: false,
    showImageUploader: false,
    impactedTemplates: [],
    tagsEdit: {},
    customTagsEdit: {},
    modifiedTags: [],
    image_url: "",
    profileInfoFields: [
      "responsible_email",
      "language",
      "phone",
      "site",
      "full_address",
    ],
    errorMessages,
    selectedMenu: "company_info",
    changedData: {},
  }),

  computed: {
    ...mapGetters([
      "token",
      "currentUser",
      "company",
      "allTemplates",
      "hasProfilePermission",
      "hasBillingPermission",
      "hasSignPermission",
      "companyHasSignActive",
      "isCompanyMicrosoft",
    ]),

    editingTemplate() {
      return this.$route.name === "TemplateEdit";
    },

    isCompanyProfile() {
      return this.$route.name === "Company";
    },

    isEditingLogo() {
      return this.selectedMenu === "company_info";
    },

    menus() {
      const menus = [
        {
          title: this.$t("companyProfile.companyInfoTitle"),
          subtitle: this.$t("companyProfile.companyInfoSubtitle"),
          icon: "mdi-store-outline",
          key: "company_info",
          disabled: !this.hasProfilePermission,
          show: true,
        },
        {
          title: this.$t("companyProfile.companyContactInfoTitle"),
          subtitle: this.$t("companyProfile.companyContactInfoSubtitle"),
          icon: "mdi-phone",
          key: "contact_info",
          disabled: !this.hasProfilePermission,
          show: true,
        },
        {
          title: this.$t("companyProfile.companyMarketingInfoTitle"),
          subtitle: this.$t("companyProfile.companyMarketingInfoSubtitle"),
          icon: "mdi-bullhorn-outline",
          key: "marketing_info",
          disabled: !this.hasProfilePermission,
          show: true,
        },
        {
          title: this.$t("companyProfile.companyCustomInfoTitle"),
          subtitle: this.$t("companyProfile.companyCustomInfoSubtitle"),
          icon: "mdi-pencil-outline",
          key: "custom_info",
          disabled: !this.hasProfilePermission,
          show: !this.editingTemplate,
        },
        {
          title: this.$t("companyProfile.companyBillingTitle"),
          subtitle: this.$t("companyProfile.companyBillingSubtitle"),
          icon: "mdi-credit-card-outline",
          key: "billing",
          disabled: !this.hasBillingPermission,
          show: !this.editingTemplate,
        },
        {
          title: this.$t("companyProfile.companyPlansInfoTitle"),
          subtitle: this.$t("companyProfile.companyPlansInfoSubtitle"),
          icon: "mdi-currency-usd",
          key: "plans_info",
          disabled: !this.hasBillingPermission,
          show: !this.editingTemplate,
        },
      ];

      return menus.filter((menuItem) => menuItem.show);
    },

    isEditableMenuSelected() {
      let editable_menus = [
        "company_info",
        "marketing_info",
        "contact_info",
        "billing",
        "custom_info",
      ];

      return editable_menus.includes(this.selectedMenu);
    },

    areProfileFieldsValid() {
      const { responsible_email } = this.changedData;
      const isEmailValid = this.validateEmail(responsible_email);

      return (
        isEmailValid &&
        this.filledProfileRequiredFields &&
        this.haveEditedProfileInfo
      );
    },

    areBillingFieldsValid() {
      const { cnpj, financial_email } = this.changedData;
      const isCnpjValid = validateCnpj(cnpj);
      const isEmailValid = this.validateEmail(financial_email);

      return (
        isCnpjValid && isEmailValid && !this.hasBillingRequiresFieldsNotFilled
      );
    },

    haveEditedProfileInfo() {
      return Object.entries(this.changedData).some(([key, value]) => {
        return (
          this.profileInfoFields.includes(key) &&
          value !== this.companyProfile[key]
        );
      });
    },

    haveEditedBillingInfo() {
      return Object.entries(this.changedData).some(([key, value]) => {
        return (
          [
            "cnpj",
            "address_postal_code",
            "financial_email",
            "address_info",
            "address_number",
            "address_complement",
            "responsible_email",
          ].includes(key) && value !== this.companyProfile[key]
        );
      });
    },

    filledProfileRequiredFields() {
      return this.changedData.responsible_email && this.changedData.phone;
    },

    hasBillingRequiresFieldsNotFilled() {
      const fields = [
        "cnpj",
        "address_postal_code",
        "financial_email",
        "address_info",
        "address_number",
        "address_city",
        "address_state",
      ];
      return Object.entries(this.changedData).some(
        (item) => fields.includes(item[0]) && !item[1]
      );
    },

    canSaveData() {
      if (this.selectedMenu === "billing") {
        return this.areBillingFieldsValid && this.haveEditedBillingInfo;
      }

      if (this.selectedMenu === "company_info") {
        return this.areProfileFieldsValid;
      }

      return true;
    },

    cantSaveTags() {
      const payload = this.createPayload();
      const areImageInvalid = this.image_url.length > 1490;

      return Object.keys(payload).length === 0 || areImageInvalid;
    },

    isEditingTags() {
      return (
        this.selectedMenu === "marketing_info" ||
        this.selectedMenu === "contact_info" ||
        this.selectedMenu === "custom_info" ||
        this.tagsEdit.logo_url != this.company.logo_url
      );
    },
  },

  methods: {
    ...mapActions(["completeMission", "getSignatureTemplates"]),

    ...mapMutations([
      "setCompany",
      "setSelectedClient",
      "setSnackBar",
      "setPermissionDialog",
      "setLocale",
    ]),

    renewFeedbackOnSecondTime,
    setNotShowFeedbackToday,
    checkShowFeedback,

    showPermissionDialog(key) {
      if (["billing", "plans_info"].includes(key)) {
        this.setPermissionDialog({
          show: true,
          status: "USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE",
          app: "billing",
        });
      } else {
        this.setPermissionDialog({
          show: true,
          status: "USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE",
          app: "company_profile",
        });
      }
    },

    resetFields() {
      this.tagsEdit = {};
      this.image_url = "";
      this.getLogoUrl();
      this.getBannerUrl();
      this.$refs[this.selectedMenu].resetFields();
    },

    getLogoUrl() {
      this.$set(this.tagsEdit, "logo_url", this.company.logo_url);
    },

    getBannerUrl() {
      this.$set(this.tagsEdit, "banner_url", this.company.banner_url);
    },

    updateURL(tag) {
      this.tagsEdit[tag] = changeDriveURL(this.tagsEdit[tag]);
    },

    saveUrl(url) {
      if (this.isEditingLogo) {
        this.$set(this.tagsEdit, "logo_url", url);
        this.updateURL("logo_url");
      } else {
        this.$set(this.tagsEdit, "banner_url", url);
        this.updateURL("banner_url");
      }
      this.$forceUpdate();
    },

    removeImageUrl() {
      if (this.isEditingLogo) {
        this.$set(this.tagsEdit, "logo_url", "");
      } else {
        this.$set(this.tagsEdit, "banner_url", "");
      }
      this.$forceUpdate();
    },

    updateCustomTags() {
      this.customTagsEdit = JSON.parse(
        JSON.stringify(this.company.custom_tags)
      );
    },

    updateTags() {
      this.tagsEdit = companyTags.reduce(
        (a, e) => (this.company[e] ? (a[e] = this.company[e]) : -1, a),
        {}
      );
    },

    async saveOrgUnitTags(value = false) {
      this.loadingSavingData = true;
      let url = process.env.VUE_APP_API_BASE_URL + "/company/tags";
      let payload = this.createPayload();

      let auth = {
        headers: {
          Authorization: this.token,
        },
      };

      const snackbarConfig = { show: true };
      await this.$axios
        .put(url, payload, auth)
        .then((response) => {
          snackbarConfig.message = successMessages.saves_changes;
          if (value === false) {
            this.setSnackBar(snackbarConfig);
          }
          this.setCompany(response.data);
          this.updateTags();
          this.completeMission("edit_company_tags");
          this.updateCustomTags();
          this.loadingSavingData = false;
          this.showConfirmationDialog = false;
        })
        .catch((err) => {
          snackbarConfig.message = errorMessages.unknown;
          snackbarConfig.color = "error";
          this.setSnackBar(snackbarConfig);
          console.error("saveOrgUnitTags(): ", err);
          this.loadingSavingData = false;
          this.showConfirmationDialog = false;
        })
        .finally(() => {
          this.showUserFeedback = true;
        });

      // TODO: Salvar dados de saveOrgUnitTags e updateCompany em um endpoint só: Refatoração
      if (this.haveEditedProfileInfo) {
        this.updateCompany();
      }
    },

    async saveAndReapplyTags() {
      this.impactedTemplates = impactedTemplateIds(
        this.allTemplates,
        this.modifiedTags
      );
      if (this.impactedTemplates) {
        this.templatesToReapply = this.impactedTemplates.map(({ id }) => id);
      }
      this.showReapplyDialog = true;
    },

    createPayload() {
      let payload = {};

      Object.keys(this.tagsEdit).forEach((tag) => {
        let tagLabel = editableCompanyTags[tag]?.label;
        if (this.tagsEdit[tag] != this.company[tag]) {
          if (tag.match(/social_media/) && tag != "social_media_whatsapp") {
            payload[tag] = this.formatedURL(this.tagsEdit[tag]);
          } else {
            payload[tag] = this.tagsEdit[tag];
          }

          if (tagLabel) {
            tagLabel = tagLabel.replace(/[*[\]]/g, "");
            this.modifiedTags.push(tagLabel);
          }
        } else if (tagLabel) {
          tagLabel = tagLabel.replace(/[*[\]]/g, "");

          this.modifiedTags = this.modifiedTags.filter(
            (value) => value !== tagLabel
          );
        }
      });

      const { custom_tags } = this.company;

      let customTagsEdited = false;

      Object.keys(this.customTagsEdit).forEach((tag) => {
        const tagLabel = this.customTagsEdit[tag].tag.replace(/[*[\]]/g, "");
        if (this.customTagsEdit[tag]?.value != custom_tags[tag]?.value) {
          if (!payload.custom_tags) {
            payload.custom_tags = {};
          }

          payload.custom_tags[tag] = this.customTagsEdit[tag];

          customTagsEdited = true;

          this.modifiedTags.push(tagLabel);
        } else {
          this.modifiedTags = this.modifiedTags.filter(
            (value) => value !== tagLabel
          );
        }
      });

      if (!customTagsEdited) {
        delete payload.custom_tags;
      }

      return payload;
    },

    closeFeedback() {
      this.showUserFeedback = false;
      this.setNotShowFeedbackToday("edit_company_tags");
    },

    formatedURL(url) {
      if (url.toLowerCase().startsWith("http://")) {
        return url.replace("http://", "https://");
      } else if (url.toLowerCase().startsWith("https://")) {
        return url;
      } else if (url.toLowerCase().startsWith("www.")) {
        return `https://${url}`;
      } else if (url.toLowerCase().startsWith("ww.")) {
        return url.replace("ww.", "https://www.");
      } else if (!url) {
        return url;
      } else {
        return `https://www.${url}`;
      }
    },

    async reapplyModels() {
      this.reapplyLoading = true;
      await this.saveOrgUnitTags(true);
      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/conecta-sign/reapply-signature/batch:async";
      const payload = {
        templates: this.templatesToReapply,
      };

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      const snackbarConfig = { show: true };
      await this.$axios
        .put(url, payload, auth)
        .then(() => {
          snackbarConfig.message = successMessages.reapply_signature_to_users;
          this.setSnackBar(snackbarConfig);
          this.reapplyLoading = false;
          this.showReapplyDialog = false;
        })
        .catch(() => {
          snackbarConfig.message = errorMessages.unknown;
          snackbarConfig.color = "error";
          this.setSnackBar(snackbarConfig);
          this.reapplyLoading = false;
          this.showReapplyDialog = false;
        })
        .finally(() => {
          this.showUserFeedback = true;
        });
    },

    companyDataChanged(data = {}) {
      this.changedData = data;
    },

    mountPayloadWithChangedData() {
      const payload = {};

      for (let key in this.changedData) {
        if (this.company[key] !== this.changedData[key]) {
          payload[key] = this.changedData[key];
        }
      }

      return payload;
    },

    updateTagsInfo(newData) {
      this.tagsEdit = newData;
    },

    updateCustomTagsInfo(newData) {
      this.customTagsEdit = newData;
    },

    selectMenu(menu_key) {
      if (this.isEditableMenuSelected) {
        this.resetFields();
      }
      this.selectedMenu = menu_key;
    },

    isMenuSelected(menu) {
      return this.selectedMenu == menu;
    },

    validateEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailRegex.test(email);
    },

    mutateCompanyState(data) {
      if (
        (this.isSelfCheckout ||
          this.isCompanyProfile ||
          this.editingTemplate) &&
        this.$route.name !== "ResellerNewPlan"
      ) {
        this.setCompany(data);
      } else {
        this.setSelectedClient(data);
      }
    },

    setCompanyLanguage(data) {
      const lang = data.language;
      localStorage.setItem("lang", lang);
      this.setLocale(lang);
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang == "pt-br" ? "pt" : lang;
    },

    async updateCompany() {
      if (!this.canSaveData) {
        this.setSnackBar({
          message: "Preencha os dados obrigatórios",
          show: true,
          color: "error",
        });
        return;
      }

      this.showLoading = true;

      let url =
        this.companyProfile.key == this.company.key
          ? `${process.env.VUE_APP_API_BASE_URL}/company`
          : `${process.env.VUE_APP_API_BASE_URL}/company/${this.companyProfile.key}`;

      const payload = this.mountPayloadWithChangedData();

      let auth = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios
        .put(url, payload, auth)
        .then(({ data }) => {
          this.mutateCompanyState(data);
          this.setCompanyLanguage(data);

          this.setSnackBar({
            message: "Dados atualizados com sucesso",
            show: true,
            color: "success",
          });

          this.changedData = {};
        })
        .catch((error) => {
          this.setSnackBar({
            message: "Erro ao atualizar as informações da empresa",
            show: true,
            color: "error",
          });
          console.error("updateCompany()", error);
        })
        .finally(() => {
          this.showLoading = false;
          this.$emit("close");
          this.showUserFeedback = true;
        });
    },
  },

  mounted() {
    if (this.hasProfilePermission) {
      this.selectedMenu = "company_info";
    } else if (this.hasBillingPermission) {
      this.selectedMenu = "billing";
    }

    this.selectedMenu = this.startingMenu || this.selectedMenu;

    if (this.isSelfCheckout) {
      this.selectedMenu = "billing";
    }

    this.getLogoUrl();
    this.getBannerUrl();
    this.getSignatureTemplates();
  },
};
</script>

<style scoped>
.sign-permission-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.685);
  z-index: 100;
}

.sign-plan-text {
  display: flex;
  align-items: flex-start;
  position: absolute;
  width: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
}

.menu-tabs:hover {
  cursor: pointer;
  color: grey;
  transition: 0.5s ease;
}

.menu-active {
  color: var(--v-accentSuite-base);
  transition: 0.5s ease;
}

.menu-active:hover {
  cursor: pointer;
}

.editable-menu {
  height: calc(100% - 70px);
  overflow-y: auto;
  overflow-x: visible;
}

.info-menu {
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
}

.grey-background {
  background-color: #f1f4f8;
}

.larger-height {
  height: calc(90vh - 50px);
}

.list-mobile-active {
  background-color: #dedbe3 !important;
}
</style>
