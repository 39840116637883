<template>
  <div class="overlay-id">
    <div class="overlay-half"></div>

    <div class="upsell-invite-container">
      <div class="d-flex flex-column align-center justify-center">
        <v-avatar color="white" size="64" class="my-2">
          <v-icon :color="iconColor" size="32" dark v-text="stateIconKey" />
        </v-avatar>
        <div class="mt-3 text-center upsell-title">
          {{ $t(titleKey) }}
        </div>
        <div
          class="my-2 text-center mx-auto upsell-subtitle"
          style="color: #979797; max-width: 375px"
        >
          {{ $t(subtitleKey) }}
        </div>
        <div class="" style="width: 188px">
          <v-btn
            color="accent"
            depressed
            class="btn-action"
            @click="$emit('click:action')"
          >
            {{ $t(actionTextKey) }}
            <v-icon right dark color="#FFF"> ph-arrow-right </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VacationOverlayUpsell",
  props: {
    titleKey: {
      type: String,
      default: () => "userInformations.upsellState.title",
    },
    subtitleKey: { type: String, default: () => "" },
    actionTextKey: { type: String, default: () => "common.knowPlans" },
    stateIconKey: { type: String, default: () => "ph-shield-warning" },
    iconColor: { type: String, default: () => "#5B36C3" },
  },

  methods: {},
};
</script>
<style scoped>
.upsell-title {
  font-size: 20px;
  color: #6d6d6d;
  font-weight: 600;
  line-height: 23px;
}

.upsell-subtitle {
  font-size: 16px;
  color: #818181;
  font-weight: 500;
  line-height: 19px;
}

.btn-upsell-text {
  color: white;
  font-size: 16px;
}

.overlay-id {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 18px;
}

.upsell-invite-container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(207, 207, 207, 20%);
  backdrop-filter: blur(10px);
}

.overlay-half {
  background-color: #eeeeee;
  width: 50%;
  height: 100%;
  opacity: 0.5;
}

.btn-action {
  min-width: 188px !important;
  height: 55px !important;
  margin-top: 1rem;
  color: white;
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
}
</style>
