import { modules } from "@/helpers/variables/modules";

export const signRoutes = [
  {
    path: "/sign",
    name: "SignMain",
    component: () => import("@/views/sign/Main.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Conecta Sign",
        },
      ],
    },
  },
  {
    path: "/sign/template",
    redirect: "/sign/email-signature/templates",
  },
  {
    path: "/sign/email-signature/templates",
    name: "SignTemplates",
    component: () => import("@/views/sign/signature_templates/Templates.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Conecta Sign",
          to: { name: "SignMain" },
        },
        {
          text: "Modelos de assinatura",
        },
      ],
    },
  },
  /* EDITOR DRAG & DROP */
  {
    path: "/sign/email-signature/:key/edit/",
    name: "SignDragInDropEditor",
    component: () => import("@/views/sign/signature_templates/SignEditor.vue"),
    meta: {
      editorMode: true,
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
    },
  },
  {
    path: "/sign/email-signature/new",
    name: "SignSelectEditor",
    component: () =>
      import("@/views/sign/signature_templates/SelectEditorType.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
    },
  },
  {
    path: "/sign/template/:key",
    redirect: "/sign/email-signature/:key",
  },
  {
    path: "/sign/email-signature/:key",
    name: "TemplateEdit",
    component: () =>
      import("@/views/sign/signature_templates/TemplateEditor.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
    },
  },
  {
    path: "/sign/organization-units",
    name: "SignOrganizationUnits",
    redirect: "/sign/users/apply",
  },
  {
    path: "/sign/users/apply",
    name: "ApplySignTemplates",
    component: () => import("@/views/sign/users/ApplyModelToUsers.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Conecta Sign",
          to: { name: "SignMain" },
        },
        {
          text: "Aplicar em massa",
        },
      ],
    },
  },
  {
    path: "/sign/users/sign-automation",
    name: "SignAutomation",
    component: () => import("@/views/sign/users/SignAutomation.vue"),
    meta: {
      requiresAuth: true,
      app: modules.control,
      permission: [modules.control],
      page: modules.sign,
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Conecta Sign",
          to: { name: "SignMain" },
        },
        {
          text: "Aplicação automática",
        },
      ],
    },
  },
  {
    path: "/sign/company-tags",
    name: "SignTags",
    component: () => import("@/views/sign/tags/EditTags.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Conecta Sign",
          to: { name: "SignMain" },
        },
        {
          text: "Tags",
        },
      ],
    },
  },
  {
    path: "/sign/templates/vacation-messages",
    name: "VacationMessageTemplates",
    component: () => import("@/views/sign/vacation/MessageTemplates.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Conecta Sign",
          to: { name: "SignMain" },
        },
        {
          text: "Modelos de mensagens de férias",
        },
      ],
    },
  },
  {
    path: "/sign/templates/vacation-message/new",
    name: "NewVacationTemplate",
    component: () => import("@/views/sign/vacation/NewVacationTemplate.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Conecta Sign",
          to: { name: "SignMain" },
        },
        {
          text: "Modelos de mensagens de férias",
          to: { name: "VacationMessageTemplates" },
        },
        {
          text: "Criar modelo",
        },
      ],
    },
  },
  {
    path: "/sign/templates/vacation-message/:id",
    name: "VacationTemplateEdit",
    component: () => import("@/views/sign/vacation/NewVacationTemplate.vue"),
    meta: {
      requiresAuth: true,
      app: modules.sign,
      permission: [modules.sign],
    },
  },
  {
    path: "/sign/users",
    redirect: "/sign/users/apply",
  },
  {
    path: "/sign/batch-users",
    redirect: "/sign/users/apply",
  },
  {
    path: "/sign/vacation-message",
    name: "VacationMessage",
    component: () => import("@/views/sign/vacation/ApplyVacationMessage.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.sign],
    },
  },
];
