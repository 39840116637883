const state = () => ({
  tokenReseller: localStorage.getItem("reseller") || null,
  token: localStorage.getItem("token") || null,
  isAuthenticating: false,
  showLogoutDialog: false,
  authError: false,
});

const getters = {
  isSimulation: (state) => !!state.tokenReseller,
  tokenReseller: () => localStorage.getItem("reseller"),
  isAuthenticated: (state, getters) =>
    !!getters.currentUser && !!getters.company,
  hasFilledForm: (state, { company }) => company && company.verified_phone,
  isSuspended: (state, { company }) => company && company.suspended,
  isAuthenticating: (state) => state.isAuthenticating,
  token: (state) => (state.token ? "Bearer " + state.token : null),
  showLogoutDialog: (state) => state.showLogoutDialog,
  authError: (state) => state.authError,
};

// actions
const actions = {
  async authentication({ dispatch, commit, getters }, token) {
    if (!token && !getters.token) return false;
    if (token) {
      commit("setToken", token);
    }
    try {
      commit("setIsAuthenticating", true);
      await Promise.all(
        [
          dispatch("getCurrentUser", { root: true }),
          !getters.company && dispatch("getCompany", { root: true }),
          dispatch("getContractedPlans"),
        ].filter((value) => value)
      );
      return getters.isAuthenticated;
    } catch (error) {
      console.error("Vuex: authentication()", error);
      return false;
    } finally {
      commit("setIsAuthenticating", false);
    }
  },

  logoutWithDialog({ commit, dispatch }) {
    return new Promise((resolve) => {
      const MAX_TIME = 20000; //20s
      commit("setShowLogoutDialog", true);

      setTimeout(() => {
        dispatch("logout");
        resolve();
      }, MAX_TIME);
    });
  },

  logout() {
    const nextPage = localStorage.getItem("nextPage");
    localStorage.clear();
    if (nextPage) {
      localStorage.setItem("nextPage", nextPage);
    }
    window.location.href = "/login";
  },

  login({ commit }, auth) {
    const url =
      process.env.VUE_APP_API_BASE_URL +
      (auth.retry ? "/auth/authorizate" : "/auth/callback");

    if (auth.retry) delete auth.retry;

    return this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        commit("setToken", data.token);
        return Promise.resolve(data.token);
      })
      .catch(({ response }) => {
        if (response.data.code) {
          commit("setAuthError", response.data.code);
        } else {
          commit("setAuthError", "UNKNOWN_ERROR");
        }
        return Promise.reject(response.data);
      });
  },
};

// mutations
const mutations = {
  setToken(state, token) {
    localStorage.setItem("token", token);
    state.token = token;
  },
  setTokenReseller(state, tokenReseller) {
    if (tokenReseller) {
      localStorage.setItem("reseller", tokenReseller);
      state.tokenReseller = tokenReseller;
    } else {
      localStorage.removeItem("reseller");
      state.tokenReseller = null;
    }
  },
  setIsAuthenticating(state, isAuthenticating) {
    state.isAuthenticating = isAuthenticating;
  },
  setShowLogoutDialog(state, showLogoutDialog) {
    state.showLogoutDialog = showLogoutDialog;
  },
  setAuthError(state, error) {
    state.authError = error;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
