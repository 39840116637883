<template>
  <div>
    <div class="mt-2 d-flex align-center">
      <h1 class="font-weight-bold" style="font-size: 22px">
        {{ $t("companyProfile.customInfo") }}
      </h1>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="info"
            dark
            v-bind="attrs"
            v-on="on"
            v-text="'mdi-help-circle-outline'"
            class="ml-2"
          />
        </template>
        <span>{{ $t("companyProfile.customInfoTooltip") }}</span>
      </v-tooltip>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="tagLimitExceeded && on">
            <v-btn
              :loading="tagLoading"
              :disabled="tagLoading || tagLimitExceeded"
              small
              color="accentSuite"
              class="mx-2 white--text text-none"
              @click="showNewTagDialog = true"
              elevation="0"
            >
              <v-icon left dark> mdi-plus </v-icon>
              {{ $t("companyProfile.addNewInfo") }}
            </v-btn>
          </div>
        </template>
        <span>{{ $t("companyProfile.customInfoExceeded") }}</span>
      </v-tooltip>
    </div>
    <div class="mt-4 pa-3">
      <div v-for="tag in editableCompanyTagsNames" :key="tag">
        <v-row>
          <v-col cols="12" sm="3" align-self="center">
            <v-card-text class="font-weight-bold body-2 py-0 pl-2"
              >{{ $t(`companyProfile.customInfoTags.${tag}`) }}
            </v-card-text>
            <v-card-text class="grey--text text-caption py-0 pl-2">{{
              companyFixedCustomTags[tag].label
            }}</v-card-text>
          </v-col>

          <v-col class="text-right mt-4" align-self="end" cols="12" sm="8">
            <v-text-field
              color="accentSuite"
              v-model="tagsEdit[tag]"
              class="pt-0 pr-2 my-0 body-2"
              autocomplete="off"
              v-if="companyFixedCustomTags[tag].hasPhoneMask"
              :rules="[
                (v) =>
                  v
                    ? v.length <= 20 || $t('textFieldRules.max20characters')
                    : true,
              ]"
              :hint="$t('companyProfile.phoneHint')"
            />
            <v-form
              ref="uosTagsForm"
              lazy-validation
              onsubmit="return false;"
              v-else
            >
              <v-text-field
                color="grey"
                v-model="tagsEdit[tag]"
                :disabled="companyFixedCustomTags[tag].disabled"
                :type="companyFixedCustomTags[tag].type"
                :placeholder="inputPlaceholder(tag)"
                class="pt-0 pr-2 my-0 body-2"
                :rules="tagRules"
              />
            </v-form>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row v-for="tag in customTagsEdit" :key="tag.name" class="mt-4">
          <v-col cols="12" sm="3" align-self="center">
            <v-card-text class="font-weight-bold body-2 py-0 pl-2"
              >{{ tag.name }}
            </v-card-text>
            <v-card-text class="grey--text text-caption py-0 pl-2"
              >{{ tag.tag }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="grey"
                    small
                    class="mr-5 no-background-hover"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      tagNameSelected = tag.name;
                      deleteTagDialog = true;
                    "
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("action.delete") }}</span>
              </v-tooltip></v-card-text
            >
          </v-col>
          <v-col
            v-if="false"
            cols="12"
            sm="3"
            align-self="end"
            class="px-0 mx-0 d-flex align-end"
          >
            <div>
              <v-card-text class="font-weight-medium body-2 py-0 ml-1"
                >{{ tag.name }}
              </v-card-text>
              <v-card-text class="grey--text text--darken-2 body-2 py-0 ml-1">{{
                tag.tag
              }}</v-card-text>
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="grey"
                  class="mr-5 no-background-hover"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    tagNameSelected = tag.name;
                    deleteTagDialog = true;
                  "
                >
                  <v-icon class="mt-3">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("action.delete") }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="8" align-self="end">
            <v-text-field
              :placeholder="tag.name"
              v-model="tag.value"
              color="grey"
              class="pt-0 my-0 body-2"
              :rules="tagRules"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <HeaderDialog
      id="custom-tag-dialog"
      :title="$t('companyProfile.newCustomTag')"
      color="primarySuite"
      :show="showNewTagDialog"
      :loading="tagLoading"
      width="700"
      :disabledAction="disableCustomTagDialog"
      showCancel
      :actionText="$t('action.confirm')"
      :closeText="$t('action.cancel')"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      @close="closeTagDialog"
      @action="addNewCustomTag"
    >
      <template v-slot:body v-if="showNewTagDialog">
        <v-row :class="$vuetify.breakpoint.smAndUp ? '' : 'd-flex flex-column'">
          <v-col>
            <v-text-field
              :rules="rules"
              :label="$t('common.name')"
              v-model="handleNewCustomTagName"
              @update:error="tagError = $event"
              :error-messages="tagErroMessages"
            >
              <template v-slot:message="{ message }">{{ message }}</template>
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              disabled
              :label="$t('common.representation')"
              :placeholder="customTag"
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>
    <ConfirmationDialog
      :show="deleteTagDialog"
      :action-text="$t('companyProfile.deleteCustomTag')"
      :loading="tagLoading"
      width="auto"
      @close="closeTagDialog"
      @ok="deleteCustomTag"
      :reversible="false"
      dense
      :message="
        $t('companyProfile.confirmDeleteCustomTag', { tag: tagNameSelected })
      "
    />
  </div>
</template>

<script>
import { companyTags, companyFixedCustomTags } from "@//helpers/variables/tags";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { mask } from "vue-the-mask";
import { mapGetters, mapMutations } from "vuex";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";

export default {
  name: "CompanyCustomInfo",
  directives: { mask },
  components: { ConfirmationDialog },
  data() {
    return {
      errorMessages,
      loading: false,
      tagsEdit: {},
      companyFixedCustomTags,
      companyTags,
      showNewTagDialog: false,
      tagLoading: false,
      customTagName: "",
      tagError: false,
      tagErroMessages: [],
      customTagsEdit: {},
      tagNameSelected: "",
      deleteTagDialog: false,
    };
  },

  watch: {
    tagsEdit: {
      deep: true,
      handler(newVal) {
        this.$emit("updateTagsInfo", newVal);
      },
    },
    customTagsEdit: {
      deep: true,
      handler(newVal) {
        this.$emit("updateCustomTagsInfo", newVal);
      },
    },
  },

  beforeMount() {
    this.getCompanyInfo();
  },

  computed: {
    ...mapGetters([
      "token",
      "company",
      "contentWidth",
      "usersNumber",
      "users",
      "currentUser",
      "usersCustomTagKeys",
      "usersCustomTags",
      "allTemplates",
    ]),

    tagRules() {
      return [
        (v) =>
          String(v).length <= 1490 ||
          this.$t("textFieldRules.max1490characters"),
      ];
    },

    rules() {
      return [
        (v) => !!v || this.$t("textFieldRules.requiredField"),
        (v) =>
          /^[a-zA-Z0-9\s]+$/.test(v) ||
          this.$t("textFieldRules.specialCharacters"),
        (v) => v.length <= 25 || this.$t("textFieldRules.max25characters"),
        (v) =>
          v.trim().length > 0 || this.$t("textFieldRules.noSpaceCharacters"),
      ];
    },

    tagLimitExceeded() {
      return Object.keys(this.company.custom_tags).length >= 5;
    },

    tagLengthError() {
      const customTagsError = Object.values(this.customTagsEdit).some(
        (tag) => tag.value.length > 1490
      );

      if (customTagsError) {
        return customTagsError;
      }

      const tagsError = Object.values(this.tagsEdit).some(
        (tag) => String(tag).length > 1490
      );

      return tagsError;
    },

    disableCustomTagDialog() {
      return (
        this.tagError ||
        !this.customTagName ||
        this.customTagName === this.tagNameSelected
      );
    },

    customTagKey() {
      return this.tagNameSelected.toUpperCase().replace(/ /g, "_");
    },

    customTag() {
      return `[*${this.customTagName}*]`.toUpperCase().replace(/ /g, "_");
    },

    handleNewCustomTagName: {
      get() {
        return this.customTagName;
      },
      set(name) {
        if (this.tagErroMessages) {
          this.tagErroMessages = [];
        }

        this.customTagName = name;
      },
    },

    editableCompanyTagsNames() {
      return Object.keys(this.companyFixedCustomTags);
    },
  },

  methods: {
    ...mapMutations(["setSnackBar", "setCompany"]),

    inputPlaceholder(tag) {
      return companyFixedCustomTags[tag].placeholder
        ? this.$t(`companyProfile.customInfoTags.${tag}`)
        : "";
    },

    async deleteCustomTag() {
      this.tagLoading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/custom-tags?tag_key=${this.customTagKey}`;

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };
      this.$axios
        .delete(url, auth)
        .then((response) => {
          this.setCompany(response.data);
          this.setSnackBar({
            message: successMessages.delete_tag_success,
            show: true,
          });
        })
        .catch(({ response: { data } }) => {
          this.setSnackBar({
            message: errorMessages[data.code],
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.updateTags();
          this.updateCustomTags();
          this.closeTagDialog();
          this.tagLoading = false;
        });
    },

    async addNewCustomTag() {
      this.tagLoading = true;

      const url = process.env.VUE_APP_API_BASE_URL + "/company/custom-tags";

      const payload = {
        name: this.customTagName,
        tag: this.customTag,
      };

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios
        .post(url, payload, auth)
        .then((response) => {
          this.setCompany(response.data);
          this.updateTags();
          this.updateCustomTags();
          this.closeTagDialog();
          this.setSnackBar({
            message: successMessages.new_tag_success,
            show: true,
          });
        })
        .catch(({ response: { data } }) => {
          this.setSnackBar({
            message: errorMessages[data.code],
            show: true,
            color: "error",
          });

          this.tagErroMessages = [errorMessages[data.code]];
        })
        .finally(() => {
          this.tagLoading = false;
        });
    },

    closeTagDialog() {
      this.tagError = false;
      this.customTagName = "";
      this.tagNameSelected = "";
      this.showNewTagDialog = false;
      this.deleteTagDialog = false;
      this.tagErroMessages = [];
    },

    resetFields() {
      this.updateTags();
      this.updateCustomTags();
    },

    async getCompanyInfo() {
      this.loading = true;
      const snackbarConfig = { show: true };
      if (this.company) {
        this.updateCustomTags();
        this.updateTags();
      } else {
        snackbarConfig.message = errorMessages.unknown;
        snackbarConfig.color = "error";
        this.setSnackBar(snackbarConfig);
      }

      this.loading = false;
    },

    updateCustomTags() {
      this.customTagsEdit = JSON.parse(
        JSON.stringify(this.company.custom_tags)
      );
    },

    updateTags() {
      this.tagsEdit = companyTags.reduce(
        (a, e) => (this.company[e] ? (a[e] = this.company[e]) : -1, a),
        {}
      );
    },
  },
};
</script>
