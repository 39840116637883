<template>
  <v-dialog
    v-model="show"
    @keydown.esc="$emit(`close`)"
    fullscreen
    hide-overlay
    height="100%"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
  >
    <v-card tile>
      <v-toolbar dark color="primary" flat tile max-height="65">
        <v-btn icon dark @click="close()">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
        <div class="ml-4 d-flex flex-column">
          <v-toolbar-title class="">
            <div class="text-sm-body-2">{{ $t("userInformations.title") }}</div>
            <div>{{ user.email }}</div>
          </v-toolbar-title>
        </div>
      </v-toolbar>

      <v-card tile class="elevation-0 transparent overflow-y-auto">
        <UserTags
          :user="user"
          @update="update"
          @update:user="$emit('update:user', $event)"
          @close="close"
        ></UserTags>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import UserTags from "@/components/sign/tags/UserTags.vue";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { mapMutations } from "vuex";

export default {
  name: "DialogEditUserTags",
  components: { UserTags },
  props: {
    user: { type: Object, required: true, default: () => {} },
    show: { type: Boolean, required: true },
  },
  methods: {
    ...mapMutations(["setSnackBar"]),

    update({
      user,
      hasSucceeded = true,
      color = "error",
      message = errorMessages.tags_update,
      timeout = 5000,
    }) {
      const snackbarConfig = { show: true, timeout };
      if (hasSucceeded) {
        snackbarConfig.message = `As Informações do usuário ${user.name} foram atualizadas`;
        this.setSnackBar(snackbarConfig);
        this.close(true);

        return;
      }

      snackbarConfig.message = message;
      snackbarConfig.color = color;
      this.setSnackBar(snackbarConfig);
      this.close();
    },

    close(hasEdited = false) {
      this.$emit("close", hasEdited);
    },
  },
};
</script>
