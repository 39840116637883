<template>
  <ResponsibleMenu
    :show-hide="showHide"
    content-class="pa-0"
    window-width="160"
    :button-icon="'mdi-view-column-outline'"
    :button-text="mobileView ? $t('common.customizeColumns') : false"
    @update:show="showHide = $event"
  >
    <v-card-title class="text-subtitle-1 font-weight-bold mx-1 mb-0 pb-2">
      {{ $t("common.customizeColumns") }}
    </v-card-title>
    <v-card-subtitle class="caption pt-1 mx-1 mb-0 pb-2">
      {{ $t("common.customizeColumnsDescription") }}
    </v-card-subtitle>
    <v-list style="height: 52vh; overflow-y: scroll">
      <v-divider />
      <draggable
        :list="headers"
        draggable=".move"
        @start="drag = true"
        @end="drag = false"
      >
        <div
          v-for="(item, i) in headers"
          :key="i"
          :class="`${!item.fixed ? 'move' : ''}`"
        >
          <v-list-item
            v-if="
              item.value !== 'data-table-expand' && item.value !== 'actions'
            "
            :disabled="item.fixed"
            :class="{
              'text-subtitle-2': true,
              'font-weight-regular': true,
              'list-group-item': true,
              'pr-4': true,
              'disabled-item': item.fixed,
              'border-bottom': i < headers.length - 1,
              'py-1': true,
            }"
          >
            <v-list-item-action class="mx-0 px-0">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  v-if="!item.fixed"
                  d="M8.25 13.5C8.25 14.325 7.575 15 6.75 15C5.925 15 5.25 14.325 5.25 13.5C5.25 12.675 5.925 12 6.75 12C7.575 12 8.25 12.675 
                                8.25 13.5ZM6.75 7.5C5.925 7.5 5.25 8.175 5.25 9C5.25 9.825 5.925 10.5 6.75 10.5C7.575 10.5 8.25 9.825 8.25 9C8.25 8.175 7.575 
                                7.5 6.75 7.5ZM6.75 3C5.925 3 5.25 3.675 5.25 4.5C5.25 5.325 5.925 6 6.75 6C7.575 6 8.25 5.325 8.25 4.5C8.25 3.675 7.575 3 6.75 
                                3ZM11.25 6C12.075 6 12.75 5.325 12.75 4.5C12.75 3.675 12.075 3 11.25 3C10.425 3 9.75 3.675 9.75 4.5C9.75 5.325 10.425 6 11.25 
                                6ZM11.25 7.5C10.425 7.5 9.75 8.175 9.75 9C9.75 9.825 10.425 10.5 11.25 10.5C12.075 10.5 12.75 9.825 12.75 9C12.75 8.175 12.075 
                                7.5 11.25 7.5ZM11.25 12C10.425 12 9.75 12.675 9.75 13.5C9.75 14.325 10.425 15 11.25 15C12.075 15 12.75 14.325 12.75 13.5C12.75 
                                12.675 12.075 12 11.25 12Z"
                  fill="#979797"
                />
              </svg>
            </v-list-item-action>
            <v-list-item-action class="mx-0 px-0">
              <v-checkbox
                v-model="item.show"
                :disabled="item.fixed || (!item.show && canNotAddColumn)"
                color="primary"
              />
            </v-list-item-action>
            <v-list-item-content
              :class="
                item.fixed || (!item.show && canNotAddColumn)
                  ? 'grey--text text--darken-1 ml-2'
                  : 'ml-2 link font-weight-regular'
              "
            >
              <v-list-item-title
                v-if="item.fixed || (!item.show && canNotAddColumn)"
                v-text="item.text"
              />
              <v-list-item-title
                v-else
                @click="item.show = !item.show"
                v-text="item.text"
              />
            </v-list-item-content>
            <v-list-item-icon class="my-auto mx-0">
              <v-tooltip bottom nudge-left close-delay="100">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="i > 1 && !item.fixed"
                    icon
                    small
                    @click="moveUp(i)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-text="'mdi-chevron-up'" />
                  </v-btn>
                </template>
                <span>{{ $t("common.moveColumnUp") }}</span>
              </v-tooltip>
              <v-tooltip bottom nudge-left close-delay="100">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="i < headers.length - 1 && !item.fixed"
                    icon
                    small
                    @click="moveDown(i)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-text="'mdi-chevron-down'" />
                  </v-btn>
                </template>
                <span>{{ $t("common.moveColumnDown") }}</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </div>
      </draggable>
    </v-list>
    <v-divider />
    <v-card-actions class="pb-4 pt-4 px-4 justify-end">
      <v-btn
        color="secondary lighten-2"
        text
        class="elevation-0 text-none text-subtitle-1 font-weight-medium"
        @click="cancel"
      >
        {{ $t("action.cancel") }}
      </v-btn>
      <v-btn
        color="accent"
        class="elevation-0 text-none text-subtitle-1 ml-4 px-6 font-weight-medium"
        @click="changeView"
      >
        {{ $t("action.apply") }}
      </v-btn>
    </v-card-actions>
  </ResponsibleMenu>
</template>
<script>
import draggable from "vuedraggable";

import {
  headerClientesTable,
  usersTableHeader,
} from "@/helpers/variables/clients";

import ResponsibleMenu from "@/components/base/ResponsibleMenu.vue";

export default {
  name: "CustomerTableColsSelector",

  props: {
    mobileView: { type: Boolean, default: false },
    usersColsTable: { type: Boolean, default: false },
    tableHeader: { type: String, default: "customersTableCols" },
  },

  components: { draggable, ResponsibleMenu },

  data() {
    return {
      showHide: false,
      headersToShow: this.usersColsTable
        ? usersTableHeader
        : headerClientesTable,
    };
  },

  computed: {
    canNotAddColumn() {
      if (this.usersColsTable) {
        return false;
      }

      const result = this.headersToShow.filter(
        (header) => header.show && !["company.name"].includes(header.value)
      ).length;

      return result >= 8;
    },

    headers: {
      get() {
        return this.headersToShow.filter((item) => !item.hidden);
      },
      set(value) {
        this.headersToShow = value;
      },
    },
  },

  methods: {
    cancel() {
      const savedHeadersKeys = this.headersToShow.map((item) => item.value);

      this.headers = this.headers.sort((a, b) => {
        return (
          savedHeadersKeys.indexOf(a.value) - savedHeadersKeys.indexOf(b.value)
        );
      });

      this.showHide = false;
    },

    changeView() {
      localStorage.setItem(this.tableHeader, JSON.stringify(this.headers));
      this.$emit("change:table-cols", this.headers);
      this.showHide = false;
    },

    moveUp(from) {
      let to = from - 1;
      let cutOut = this.headers.splice(from, 1)[0];
      this.headers.splice(to, 0, cutOut);
    },

    moveDown(from) {
      let to = from + 1;
      let cutOut = this.headers.splice(from, 1)[0];
      this.headers.splice(to, 0, cutOut);
    },

    getMissingColumns(preferenceColumns = []) {
      const keys = preferenceColumns.map((column) => column.value);

      const missingColumns = this.headersToShow.filter(
        (column) => !keys.some((key) => key === column.value)
      );

      return missingColumns;
    },

    setCurrentSelected() {
      const preferenceColumns =
        JSON.parse(localStorage.getItem(this.tableHeader)) || [];

      const allColumnsSize = this.headersToShow.length;

      if (allColumnsSize !== preferenceColumns.length) {
        const missingColumns = this.getMissingColumns(preferenceColumns);
        const insertIndex = preferenceColumns.length - 1;
        preferenceColumns.splice(insertIndex, 0, ...missingColumns);
      }

      this.headers = preferenceColumns;
    },
  },

  beforeMount() {
    this.setCurrentSelected();
  },
};
</script>
