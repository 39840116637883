<template>
  <v-container class="pa-0 my-0" style="max-width: 1160px">
    <div :class="`${!hasConectaControl ? 'overlay-container pl-4 pt-2' : ''}`">
      <div>
        <v-card-text class="d-flex flex-row align-center px-0 pt-0 mt-5 mb-2">
          <h2 class="font-weight-bold">
            {{ $t("vacationApply.manageAccess.subtitle") }}
          </h2>
          <div class="ms-3">
            <Logo
              noMargins
              full
              width="140"
              height="30"
              product="conecta_control"
            />
          </div>
        </v-card-text>

        <v-card-text class="px-0 pt-0 pb-1 mb-2 text-justify">
          <div
            class="text-body-1 grey--text text--darken-2"
            style="max-width: 800px"
          >
            <p>
              {{ $t("vacationApply.manageAccess.description") }}
            </p>
            <p>{{ $t("vacationApply.manageAccess.question") }}</p>
          </div>
        </v-card-text>
      </div>

      <v-card-title class="pa-0 mt-0">
        {{ $t("common.usersSelecteds") }} ({{ selectedUsersNumber }})
      </v-card-title>

      <Alert
        v-if="!hasSelected"
        :text="false"
        class="mt-4"
        color="error"
        dense
        dark
      >
        {{ $t("common.noUserPleaseSelect") }}
      </Alert>

      <v-card
        class="my-3 rounded"
        flat
        outlined
        style="border-color: rgba(0, 0, 0, 0.42)"
        :loading="loadingSign"
      >
        <div class="pa-2">
          <v-chip
            color="lighten-3"
            pill
            class="ma-1 link"
            v-for="(item, index) in selectedUsersObjects"
            :key="`u${index}`"
          >
            <v-avatar width="30" left>
              <img :src="item.photo || '@/assets/user-default.png'" />
            </v-avatar>
            <span class="px-2">
              {{ item.name }}
            </span>
          </v-chip>
        </div>
      </v-card>

      <v-text-field
        append-icon="mdi-calendar"
        :value="vacationPeriod"
        :label="$t('common.startEndDate')"
        readonly
        :loading="loadingSign"
        hide-details
        outlined
      />
      <VacationOverlayUpsell
        v-if="!hasConectaControl"
        subtitle-key="userInformations.upsellState.vacationControl.default"
        state-icon-key="ph-rocket-launch"
        icon-color="#5B36C3"
        @click:action="goToSelfCheckout"
      />
    </div>

    <v-card-actions class="px-0 mt-4">
      <v-spacer />
      <v-btn
        @click.stop="$emit('skip')"
        :disabled="!hasSelected || loadingSign"
        color="accent"
        class="mx-auto mr-5 px-5"
        large
        outlined
      >
        {{ $t("vacationApply.manageAccess.dontBlock") }}
      </v-btn>
      <v-btn
        @click.stop="applyControlVacation"
        :disabled="!hasSelected || !hasConectaControl || loadingSign"
        :loading="loadingSign"
        color="accent"
        class="mx-auto px-5"
        large
      >
        {{ $t("vacationApply.manageAccess.block") }}
      </v-btn>
    </v-card-actions>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { formatDateWithDayOfWeek } from "@/helpers/services/utils";
import { getAnalytics, logEvent } from "firebase/analytics";
import VacationOverlayUpsell from "@/components/sign/vacation/VacationOverlayUpsell.vue";

export default {
  name: "ManageUserAccess",

  components: {
    VacationOverlayUpsell,
  },

  props: {
    selectedUsers: { type: Array, required: true },
    vacationSettings: { type: Object, required: true },
  },

  computed: {
    ...mapGetters([
      "users",
      "loadingSign",
      "token",
      "hasConectaControl",
      "mainDomain",
      "currentUser",
    ]),

    selectedUsersNumber() {
      return this.selectedUsers.length;
    },

    hasSelected() {
      return this.selectedUsersNumber > 0;
    },

    vacationPeriod() {
      const { start_time, end_time } = this.vacationSettings;
      if (!start_time || !end_time) {
        return null;
      }
      return [start_time, end_time]
        .map((date) => formatDateWithDayOfWeek(date))
        .join(" ~ ");
    },

    selectedUsersObjects() {
      return this.selectedUsers
        .map((email) => this.users.find((user) => user.email == email))
        .filter((value) => value);
    },
  },

  methods: {
    ...mapMutations(["setSignLoading", "setSnackBar"]),

    goToSelfCheckout() {
      const analytics = getAnalytics();
      logEvent(analytics, "go_to_self_checkout", {
        click: "Gerenciar acesso - fluxo de férias",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description:
          "Clicou para conhecer planos na etapa de gerenciar acesso no control",
      });
      this.$router.push("/billing/upgrade");
    },

    async applyControlVacation() {
      this.setSignLoading(true);

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/vacation-access-control`;

      const payload = {
        users: this.selectedUsersObjects.map((user) => user.id_google),
        start_time: this.vacationSettings.start_time,
        end_time: this.vacationSettings.end_time,
      };

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      await this.$axios
        .put(url, payload, auth)
        .then(() => {
          this.setSnackBar({
            show: true,
            color: "success",
            message: this.$t("vacationApply.manageAccess.success"),
          });
          this.$emit("skip");
        })
        .catch(() => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: this.$t("vacationApply.manageAccess.failure"),
          });
          this.$emit("reset", true);
        })
        .finally(() => this.setSignLoading(false));
    },
  },
};
</script>
<style>
.selected-border {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 3px !important;
  border-color: var(--v-primary-base) !important;
}

.overlay-container {
  position: relative;
  width: 100%;
  height: 388px;
}
</style>
