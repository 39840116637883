<template>
  <div>
    <v-card-text class="mx-0 pt-0 px-0" v-if="!newTemplate">
      Configure uma resposta automática de férias para avisar automaticamente
      que o usuário não poderá responder às mensagens de imediato. A resposta
      automática de férias, com as informações que você inseriu, será enviada
      quando o usuário receber um e-mail dentro do período determinado.
    </v-card-text>

    <v-card-text v-if="isConectaSuite && !newTemplate" class="mx-0 pt-0 px-0">
      Para configurar em massa de forma simplificada para toda a organização
      utilize o

      <ProductButton go-to-product product="conecta_sign" small />
    </v-card-text>

    <v-card-text v-if="!newTemplate" class="mx-0 pt-0 px-0"
      >Bloqueie o acesso dos usuários durante o período de férias utilizando o
      <ProductButton
        :href="goToControlUsersPage"
        product="conecta_control"
        small
        target="_blank"
      />
    </v-card-text>

    <v-tooltip v-if="!hideActivator && !newTemplate">
      <template v-slot:activator="{ on, attrs }">
        <v-switch
          v-model="payload.enable_auto_reply"
          :label="`${
            payload.enable_auto_reply
              ? 'Resposta automática de férias ativada'
              : 'Resposta automática de férias desativada'
          }`"
          :color="primaryColor"
          v-bind="attrs"
          v-on="on"
          inset
          class="mx-1 mt-1 mb-2"
        ></v-switch>
      </template>
      <span>{{
        `${
          payload.enable_auto_reply
            ? "Desativar resposta automática de férias"
            : "Ativar resposta automática de férias"
        }`
      }}</span>
    </v-tooltip>

    <v-card-title
      v-if="newTemplate"
      :class="`${$vuetify.breakpoint.xs ? 'mt-6' : 'mt-0'} px-0 pt-0 pb-1`"
    >
      Criar novo modelo
    </v-card-title>

    <div v-if="newTemplate" :class="`${hasConectaSign ? '' : 'upgrade-block'}`">
      <v-switch
        v-model="saveTemplate"
        :label="`Salvar novo modelo em 'Modelos personalizados'?`"
        :color="primaryColor"
        :disabled="!hasConectaSign"
        inset
        class="mx-1 mt-1 mb-2"
        hide-details
      ></v-switch>
      <v-btn
        v-if="!hasConectaSign"
        color="accent"
        elevation="0"
        class="ml-4 text-none font-weight-medium"
        dark
        id="upgrade-button-new-vacation-model"
        @click="goToSelfCheckout"
      >
        <v-icon left v-text="'ph-crown-simple'" />
        <span>{{ $t("common.upgradeCTA") }}</span>
      </v-btn>
    </div>

    <div v-show="payload.enable_auto_reply || newTemplate">
      <v-row align="center" class="px-0 pt-2">
        <v-col cols="12" v-if="!newTemplate">
          <v-menu
            v-model="menu.dates"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                append-icon="mdi-calendar"
                v-model="dateRangeText"
                readonly
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
                :color="secondaryColor"
              >
                <template v-slot:label>
                  Período de férias<span class="red--text">*</span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              :min="todayDataBaseFormat"
              range
              :color="secondaryColor"
            >
              <v-spacer></v-spacer>
              <v-tooltip :disabled="dates.length == 2" top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      text
                      :color="secondaryColor"
                      :disabled="dates.length < 2"
                      @click="confirmDatesVacation()"
                      >OK</v-btn
                    >
                  </div>
                </template>
                <span>Selecione a data de ínicio e fim</span>
              </v-tooltip>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col v-if="saveTemplate" cols="12" xs="12" md="6">
          <v-text-field
            class="mt-0"
            v-model="templateName"
            :color="secondaryColor"
            label="Nome do modelo"
            outlined
            hide-details
          >
            <template v-slot:label>
              Nome do modelo<span class="red--text">*</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" xs="12" :md="saveTemplate ? 6 : 12">
          <v-text-field
            class="mt-0"
            v-model="payload.response_subject"
            :color="secondaryColor"
            label="Assunto*"
            outlined
            hide-details
          >
            <template v-slot:label>
              Assunto<span class="red--text">*</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-card
            outlined
            id="messageEditor"
            :class="`${
              editorIsFocused
                ? isSuitePage
                  ? 'focused-suite-editor'
                  : 'focused-sign-editor'
                : 'blur-editor'
            } rounded`"
            transition="fade-transition"
          >
            <div
              class="caption grey--text text--darken-2"
              style="margin: -10px 0 7px 10px"
            >
              <span class="background px-2">
                <span
                  :class="`${
                    editorIsFocused && !isSuitePage ? 'accent--text' : ''
                  } ${
                    editorIsFocused && isSuitePage ? 'accentSuite--text' : ''
                  }`"
                  >Mensagem</span
                ><span class="red--text">*</span>
              </span>
            </div>
            <v-card
              flat
              tile
              v-if="startingEditor"
              height="160"
              class="d-flex flex-column justify-center align-center transparent"
            >
              <v-progress-circular
                :color="isSuitePage ? 'accentSuite' : 'accent'"
                indeterminate
              />
              <div class="caption font-weight-medium mt-2">
                Carregando editor...
              </div>
            </v-card>

            <Editor
              class="border-lg"
              :api-key="tinymceApiKey"
              v-model="payload.response_body_html"
              model-events="change keydown blur focus paste"
              :init="editorInitSettings"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-checkbox
        v-if="!newTemplate"
        v-model="payload.create_calendar_event"
        label="Adicionar evento de férias na agenda do usuário"
        :color="secondaryColor"
        class="mb-0 py-0"
      ></v-checkbox>
      <v-col
        v-if="!newTemplate && payload.create_calendar_event"
        class="px-0 pt-0 mb-2"
        cols="12"
        xs="12"
        :md="saveTemplate ? 6 : 12"
      >
        <v-text-field
          class="mt-0"
          v-model="payload.calendar_event_name"
          :color="secondaryColor"
          label="Título do evento*"
          outlined
          hide-details
        >
          <template v-slot:label>
            Título do evento<span class="red--text">*</span>
          </template>
        </v-text-field>
      </v-col>
      <v-checkbox
        v-if="!newTemplate"
        v-model="payload.restrict_to_contacts"
        label="Enviar resposta apenas às pessoas que estão nos meus contatos"
        :color="secondaryColor"
        class="my-0 py-0"
      ></v-checkbox>
      <v-checkbox
        v-if="!newTemplate"
        v-model="payload.restrict_to_domain"
        label="Enviar uma resposta somente para as pessoas da sua empresa"
        :color="secondaryColor"
        class="my-0 py-0"
      ></v-checkbox>
      <v-checkbox
        v-if="!newTemplate"
        v-model="payload.forwarding_email_on_vacation"
        :color="secondaryColor"
        class="my-0 py-0"
      >
        <template v-slot:label>
          <span class="mr-2">
            Encaminhar e-mails recebidos para outro usuário
          </span>
          <v-chip
            color="blue"
            dark
            title="Funcionalidade em teste!"
            x-small
            class="font-weight-medium mr-1"
            >Beta
          </v-chip>
          <v-tooltip top max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="18"
                v-text="'mdi-information-slab-circle-outline'"
                color="info"
              />
            </template>
            <span>
              Em alguns casos, o encaminhamento ocorrerá apenas após o usuário
              selecionado confirmar a ação por e-mail.
            </span>
          </v-tooltip>
        </template>
      </v-checkbox>
      <v-col
        v-if="!newTemplate && payload.forwarding_email_on_vacation"
        class="px-0 pt-0 mb-2"
        cols="12"
        xs="12"
        :md="saveTemplate ? 6 : 12"
      >
        <v-autocomplete
          v-model="payload.forwarding_email_on_vacation"
          :items="users"
          label="Encaminhar e-mails para"
          outlined
          color="accentSuite"
          item-text="email"
          hide-selected
        />
      </v-col>

      <v-card-actions class="px-0 pt-6" v-if="newTemplate">
        <v-btn
          large
          color="accent"
          class="text-none text-body-1 font-weight-medium px-6"
          @click="$emit('cancel')"
          outlined
        >
          Cancelar
        </v-btn>
        <v-spacer v-if="!$vuetify.breakpoint.xs" />
        <v-btn
          :disabled="loading || disableSelectMessage"
          large
          :block="$vuetify.breakpoint.xs"
          color="accent"
          class="text-none text-body-1 font-weight-medium px-6"
          :loading="loading"
          @click="selectNewTemplate"
        >
          {{
            saveTemplate
              ? "Salvar e seguir com este modelo"
              : "Usar esta mensagem"
          }}
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import {
  formatDate,
  getVacationDates,
  todayDataBaseFormat,
} from "@/helpers/services/utils";
import { modules } from "@/helpers/variables/modules";
import Editor from "@tinymce/tinymce-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  name: "VacationSettingsForm",
  components: { Editor },
  props: {
    payload: {
      type: Object,
      default: () => {
        return {
          enable_auto_reply: false,
          response_subject: null,
          response_body_plain_text: null,
          response_body_html: null,
          restrict_to_contacts: false,
          restrict_to_domain: false,
          start_time: null,
          end_time: null,
          create_calendar_event: false,
          calendar_event_name: "Férias ⛱️",
          forwarding_email_on_vacation: false,
        };
      },
    },
    newTemplate: { type: Boolean, default: false },
    hideActivator: { type: Boolean, default: false },
    showVacationTemplatesOnlyInSign: { type: Boolean, default: false },
  },
  data() {
    return {
      modules,
      todayDataBaseFormat,
      dates: [],
      menu: {
        dates: null,
      },
      selectedVacationTemplate: { key: "" },
      tab: 0,
      saveTemplate: false,
      templateName: "",
      startingEditor: false,
      editorIsFocused: false,
      tinymceApiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
      loading: false,
    };
  },

  computed: {
    ...mapGetters([
      "secondaryColor",
      "allVacationTemplates",
      "primaryColor",
      "hasConectaSign",
      "allVacationTemplatesWithLabels",
      "users",
      "isSuitePage",
      "hasSignPermission",
      "mainDomain",
      "currentUser",
    ]),

    disableSelectMessage() {
      if (this.saveTemplate) {
        return (
          !this.payload.response_body_html ||
          !this.payload.response_subject ||
          !this.templateName
        );
      }
      return !this.payload.response_body_html || !this.payload.response_subject;
    },

    isConectaSuite() {
      return this.$route.meta.app === modules.suite;
    },

    hasVacationTemplates() {
      return this.allVacationTemplates.length;
    },

    goToControlUsersPage() {
      return (
        process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL +
        "/users?vacation=true"
      );
    },

    dateRangeText() {
      const { start_time, end_time } = this.payload;
      if (!start_time || !end_time) {
        return null;
      }
      return [start_time, end_time]
        .map((date) => this.formatDate(date))
        .join(" - ");
    },

    editorInitSettings() {
      const _this = this;
      return {
        setup: function (editor) {
          editor.on("init", function () {
            _this.editorIsFocused = false;
            _this.startingEditor = false;
            _this.addLinkTipTinyMCE(editor);
          });

          editor.on("focus", function () {
            _this.editorIsFocused = true;
          });

          editor.on("blur", function () {
            _this.editorIsFocused = false;
          });
        },
        height: 200,
        menubar: false,
        paste_as_text: true,
        link_title: false,
        skin: this.$vuetify.theme.dark ? "oxide-dark" : "",
        content_css: this.$vuetify.theme.dark ? "dark" : "",
        table_default_attributes: {
          border: "0",
        },
        mobile: {
          theme: "mobile",
          plugins:
            "insertdatetime media emoticons nonbreaking visualchars advlist anchor autolink charmap code codesample directionality help hr image link lists media preview print table template textpattern visualblocks visualchars",
          toolbar:
            "undo | formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor lineheight | link image | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent hr | removeformat | code",
        },
        language_url:
          "https://firebasestorage.googleapis.com/v0/b/access-control-storage.appspot.com/o/translate%2Fpt_BR_01.js?alt=media&token=430a2f25-59f6-48e2-bcac-822dc9550711",
        language: "pt_BR",
        plugins:
          "template save insertdatetime media emoticons nonbreaking visualchars advlist anchor autolink charmap code codesample directionality help hr image link lists media preview print table template textpattern visualblocks visualchars",
        toolbar:
          "undo redo | formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor lineheight | link image | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent hr | removeformat | code",
      };
    },

    user() {
      if (this.id && this.$route.query.id) {
        const user = this.users.find(
          (user) => user.id_google == this.$route.query.id
        );
        return user ? user : {};
      }
      return {};
    },
  },

  watch: {
    user() {
      if (this.hasConectaSign && this.user.applied_vacation_key) {
        this.templateId = this.user.applied_vacation_key;
      }
    },
  },

  methods: {
    formatDate,

    ...mapActions(["getVacationTemplates", "createNewVacationTemplate"]),
    ...mapMutations(["setSnackBar"]),

    goToSelfCheckout() {
      const analytics = getAnalytics();
      logEvent(analytics, "go_to_self_checkout", {
        click: "escrever modelo férias no fluxo de férias",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: `Clicou para conhecer planos ao criar um novo modelo de férias`,
      });
      this.$router.push("/billing/upgrade");
    },

    async selectNewTemplate() {
      const { response_body_html, response_subject } = this.payload;
      if (this.saveTemplate) {
        this.loading = true;

        const payload = {
          name: this.templateName,
          description: "",
          response_subject,
          content: response_body_html,
        };
        await this.createNewVacationTemplate(payload)
          .then(async ({ data }) => {
            this.setSnackBar({
              color: "success",
              message: `O modelo "${payload.name}" foi salvo junto aos seus modelos personalizados.`,
              show: true,
            });
            await this.getVacationTemplates();
            this.$emit("newTemplateId", data.key);
          })
          .catch(() => {
            this.setSnackBar({
              color: "error",
              message: `Não foi possível salvar o modelo, verifique se todos os campos foram preenchidos.`,
              show: true,
            });
          })
          .finally(() => (this.loading = false));
      } else {
        const template = {
          response_subject: this.payload.response_subject,
          response_body_html: this.payload.response_body_html,
        };
        this.$emit("html", template);
      }
    },

    confirmDatesVacation() {
      this.menu.dates = false;
      [this.payload.start_time, this.payload.end_time] = this.dates.sort();
    },

    addLinkTipTinyMCE(editor) {
      editor.windowManager.oldOpen = editor.windowManager.open;
      editor.windowManager.open = function (t, r) {
        var modal = editor.windowManager.oldOpen.apply(this, [t, r]);
        if (t.title === "Insert/Edit Link") {
          let url = document.getElementsByClassName("tox-form__group")[0];
          let urlTipArea = document.createElement("small");
          let urlTip = document.createTextNode("https://dominio.com.br");
          urlTipArea.style.color = "gray";
          urlTipArea.style.fontStyle = "italic";
          urlTipArea.style.fontSize = "14px";
          urlTipArea.style.margin = "0 0 20px 0";
          urlTipArea.appendChild(urlTip);
          url.appendChild(urlTipArea);
        }
        return modal;
      };
    },

    selectTemplate(vacation_template) {
      this.selectedVacationTemplate = vacation_template;
      this.$emit("selectTemplate", vacation_template);
    },
  },

  async beforeMount() {
    this.startingEditor = true;

    if (
      !this.allVacationTemplates.length &&
      this.hasConectaSign &&
      this.hasSignPermission
    ) {
      await this.getVacationTemplates();
    }

    if (this.showVacationTemplatesOnlyInSign) {
      const templatesTab = 0;
      const newTemplateTab = 1;
      this.tab = this.hasVacationTemplates ? templatesTab : newTemplateTab;
    }

    const { vacationStart, vacationEnd } = getVacationDates();

    if (vacationStart && vacationEnd) {
      this.dates = [vacationStart, vacationEnd];
      this.confirmDatesVacation();
    }
  },
};
</script>

<style>
.selected-card {
  border: 2px solid rgb(52, 169, 236) !important;
}
.blur-editor {
  border-width: 1px !important;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.37) !important;
}
.focused-sign-editor {
  border-width: 2px !important;
  border-style: solid;
  border-color: var(--v-accent-base) !important;
}
.focused-suite-editor {
  border-width: 2px !important;
  border-style: solid;
  border-color: var(--v-accentSuite-base) !important;
}
.cursor {
  cursor: pointer;
}

.upgrade-block {
  display: flex;
  align-items: center;
  background-color: #e9e8ea;
  border-radius: 4px;
  width: fit-content;
  padding: 8px 16px;
}
</style>
