<template>
  <!--TODO: Flexibilizar o uso da propriedade multiple -->
  <v-autocomplete
    v-model="selectedUsers"
    :items="usersOrItems"
    :filter="filter"
    :label="label"
    multiple
    chips
    outlined
    clearable
    :class="className"
    :color="color"
    dense
    :item-text="itemText"
    :item-value="itemValue"
    placeholder="Escolher usuários"
    append-icon="mdi-magnify"
  >
    <template v-slot:selection="{ attrs, item, selected, parent }">
      <v-chip
        v-bind="attrs"
        :color="`${item.color} lighten-3`"
        class="ma-1 link"
        small
        :input-value="selected"
      >
        <v-avatar left>
          <v-img :src="item.photo || userPhoto" />
        </v-avatar>
        <span class="pr-2">
          {{ item.name }}
        </span>
        <v-icon
          small
          @click="parent.selectItem(item)"
          v-text="'mdi-close-circle'"
        />
      </v-chip>
    </template>

    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" :active-class="activeClass">
        <v-list-item-action>
          <v-checkbox
            v-model="attrs.inputValue"
            :color="attrs.inputValue ? color : 'secondary'"
          />
        </v-list-item-action>
        <v-list-item-avatar size="32">
          <v-img :src="item.photo || userPhoto" contain />
        </v-list-item-avatar>
        <v-list-item-content class="pl-4">
          <v-list-item-title
            :class="attrs.inputValue ? titleColor : 'secondary--text'"
            v-html="item.name"
          />
          <v-list-item-subtitle v-html="item.email" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SelectUser",
  props: {
    items: { type: Array },
    filter: { type: Function },
    label: { type: String },
    className: { type: String },
    color: { type: String },
    itemText: { type: String },
    itemValue: { type: String },
    activeClass: { type: String },
  },
  data() {
    return {
      titleColor: "",
      defaultUsers: this.users,
    };
  },
  computed: {
    ...mapGetters(["currentUserPhoto", "users", "getSelectedUsers"]),
    userPhoto() {
      return this.currentUserPhoto || require("@/assets/user-default.png");
    },
    usersOrItems() {
      if (!this.items) {
        return this.users;
      }
      return this.items;
    },
    selectedUsers: {
      get() {
        return this.getSelectedUsers;
      },
      set(newSelectedUsers) {
        this.setSelectedUsers(newSelectedUsers);
      },
    },
  },
  methods: {
    ...mapMutations(["setSelectedUsers"]),
  },
  mounted() {
    this.titleColor = this.color + "--text";
  },
};
</script>
<style>
.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}
</style>
