<template>
  <div :class="`${classStyles}`">
    <v-avatar color="#F1E1F7E5" size="64" class="mt-6">
      <v-icon :color="iconColor" size="32" dark v-text="stateIconKey" />
    </v-avatar>
    <div class="mt-3 poppins-14-21 text-center" style="color: #6d6d6d">
      {{ $t(titleKey) }}
    </div>
    <div
      class="mt-2 poppins-12-18 text-center mx-auto"
      style="color: #979797; max-width: 375px"
    >
      {{ $t(subtitleKey) }}
    </div>
    <div>
      <v-btn
        class="mt-5 px-3"
        color="#BB6BD933"
        depressed
        @click="$emit('click:action')"
      >
        <span
          class="poppins-12-18 text-left"
          style="color: #5b36c3; text-transform: none"
        >
          {{ $t(actionTextKey) }}
        </span>
        <v-icon right dark color="#5B36C3"> ph-arrow-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyStateTableData",
  props: {
    titleKey: { type: String, default: () => "" },
    subtitleKey: { type: String, default: () => "" },
    actionTextKey: { type: String, default: () => "" },
    stateIconKey: { type: String, default: () => "ph-shield-warning" },
    iconColor: { type: String, default: () => "ph-shield-warning" },
    isSelected: { type: Boolean, default: () => false },
    classStyles: { type: String, default: () => "" },
  },

  methods: {},
};
</script>

<style scoped>
.poppins-12-18 {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
</style>
