import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";

const state = () => ({
  vacationTemplates: {
    company_templates: [],
    default_templates: [],
  },
  showVacationMessageBanner: false,
  temporary_start_date: "",
  temporary_end_date: "",
  vacationEditorLayout: "editor_only",
});

const getters = {
  showVacationMessageBanner: (state) => state.showVacationMessageBanner,

  companyVacationTemplates: (state) =>
    state.vacationTemplates.company_templates,

  defaultVacationTemplates: (state) =>
    state.vacationTemplates.default_templates,

  allVacationTemplates: (state) => {
    const templates = [];
    return templates.concat(
      state.vacationTemplates.company_templates,
      state.vacationTemplates.default_templates
    );
  },

  allVacationTemplatesWithLabels: (state) => {
    let templates = [];
    templates.push({ header: "Modelos personalizados" });
    Array.prototype.push.apply(
      templates,
      state.vacationTemplates.company_templates
    );
    templates.push({ divider: true });
    templates.push({ header: "Modelos padrão" });
    Array.prototype.push.apply(
      templates,
      state.vacationTemplates.default_templates
    );
    templates.push({ divider: true });
    return templates;
  },

  temporaryStartDate: (state) => state.temporary_start_date,

  temporaryEndDate: (state) => state.temporary_end_date,

  usersWithVacationSettings(state, getters, rootState, rootGetters) {
    let emails = rootGetters.users.filter((user) => user.applied_vacation_key);
    return emails.map((user) => user.email);
  },
  vacationEditorLayout: (state) => state.vacationEditorLayout,
};

// actions
const actions = {
  createNewVacationTemplate({ commit }, payload) {
    commit("setSignLoading", true, { root: true });

    const url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/vacation/templates`;

    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return this._vm.$axios
      .post(url, payload, auth)
      .finally(() => commit("setSignLoading", false, { root: true }));
  },

  getVacationTemplates({ commit }) {
    commit("setSignLoading", true, { root: true });

    const url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/vacation/templates`;

    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        commit("setVacationTemplates", data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => commit("setSignLoading", false, { root: true }));
  },

  updateVacationInBatch({ commit, dispatch }, config) {
    commit("setSignLoading", true, { root: true });

    const { vacation, vacation_template_key, emails } = config;

    const multiple = emails.length > 1;

    const hasManyUsers = emails.length > 50;

    const url = `${
      process.env.VUE_APP_API_BASE_URL
    }/conecta-sign/vacation/batch${hasManyUsers ? ":async" : ""}`;

    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    const payload = {
      emails: emails,
      vacation_settings: vacation.settings,
      vacation_template_key: vacation_template_key,
    };

    return this._vm.$axios
      .put(url, payload, auth)
      .then(() => {
        if (config.is_delete) {
          commit("setSnackBar", {
            show: true,
            color: "success",
            message: multiple
              ? successMessages.multiple_delete_vacation_message
              : successMessages.delete_vacation_message,
          });
        } else {
          commit("setSnackBar", {
            show: true,
            color: "success",
            message: successMessages.vacation_message,
          });
          const newUrl = window.location.pathname;
          history.pushState({}, "", newUrl);
        }

        dispatch("getUsers", { root: true });
      })
      .catch(() => {
        setTimeout(() => {
          if (config.is_delete) {
            commit(
              "setSnackBar",
              {
                show: true,
                color: "error",
                message: multiple
                  ? errorMessages.multiple_delete_vacation_message
                  : errorMessages.delete_vacation_message,
              },
              { root: true }
            );
          } else {
            commit(
              "setSnackBar",
              {
                show: true,
                color: "error",
                message: errorMessages.vacation_message,
              },
              { root: true }
            );
          }
        }, 1000);
      })
      .finally(() => {
        commit("setSignLoading", false, { root: true });
        commit("setTemporaryVacationStartDate", "");
        commit("setTemporaryVacationEndDate", "");
      });
  },
};

// mutations
const mutations = {
  setVacationTemplates(state, vacation_templates) {
    state.vacationTemplates = vacation_templates;
  },

  setShowVacationMessageBanner(state, value) {
    state.showVacationMessageBanner = value;
  },

  setTemporaryVacationStartDate(state, start_date) {
    state.temporary_start_date = start_date;
  },

  setTemporaryVacationEndDate(state, end_date) {
    state.temporary_end_date = end_date;
  },
  setVacationEditorLayout(state, layout) {
    state.vacationEditorLayout = layout;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
