const state = () => ({
  selectedUsers: [],
  permissionStatus: "",
  showPermissionDialog: false,
  pageToCheckPermission: "conecta_suite",
});

const getters = {
  showPermissionDialog: (state) => state.showPermissionDialog,
  pageToCheckPermission: (state) => state.pageToCheckPermission,

  permissionDialogWithDelay: () =>
    localStorage.getItem("permissionDialog")
      ? JSON.parse(localStorage.getItem("permissionDialog"))
      : null,

  getSelectedUsers: (state) => state.selectedUsers,

  companyHasSign: (state, getters, rootState, rootGetters) => {
    if (rootGetters.activeProducts.includes("conecta_sign")) {
      return "PRODUCT_IN_ACTIVE_PLAN";
    } else if (rootGetters.ativeTrialProducts.includes("conecta_sign")) {
      return "PRODUCT_IN_TRIAL_PLAN";
    } else if (rootGetters.disabledTrialProducts.includes("conecta_sign")) {
      return "PLAN_TRIAL_ENDED";
    }

    return "PRODUCT_DOES_NOT_EXIST_IN_PLANS";
  },

  companyHasSignActive: (state, getters, rootState, rootGetters) => {
    if (
      rootGetters.activeProducts.includes("conecta_sign") ||
      rootGetters.ativeTrialProducts.includes("conecta_sign")
    ) {
      return true;
    }
    return false;
  },

  companyHasSuite: (state, getters, rootState, rootGetters) => {
    if (rootGetters.activeProducts.includes("conecta_suite")) {
      return "PRODUCT_IN_ACTIVE_PLAN";
    } else if (rootGetters.ativeTrialProducts.includes("conecta_suite")) {
      return "PRODUCT_IN_TRIAL_PLAN";
    } else if (rootGetters.disabledTrialProducts.includes("conecta_suite")) {
      return "PLAN_TRIAL_ENDED";
    }

    return "PRODUCT_DOES_NOT_EXIST_IN_PLANS";
  },

  companyHasControl: (state, getters, rootState, rootGetters) => {
    if (rootGetters.activeProducts.includes("conecta_control")) {
      return "PRODUCT_IN_ACTIVE_PLAN";
    } else if (rootGetters.ativeTrialProducts.includes("conecta_control")) {
      return "PRODUCT_IN_TRIAL_PLAN";
    } else if (rootGetters.disabledTrialProducts.includes("conecta_control")) {
      return "PLAN_TRIAL_ENDED";
    }

    return "PRODUCT_DOES_NOT_EXIST_IN_PLANS";
  },

  companyHasTrack: (state, getters, rootState, rootGetters) => {
    if (rootGetters.activeProducts.includes("conecta_track")) {
      return "PRODUCT_IN_ACTIVE_PLAN";
    } else if (rootGetters.ativeTrialProducts.includes("conecta_track")) {
      return "PRODUCT_IN_TRIAL_PLAN";
    } else if (rootGetters.disabledTrialProducts.includes("conecta_track")) {
      return "PLAN_TRIAL_ENDED";
    }

    return "PRODUCT_DOES_NOT_EXIST_IN_PLANS";
  },

  signPermission: (state, getters, rootState, rootGetters) => {
    if (
      ["PRODUCT_IN_ACTIVE_PLAN", "PRODUCT_IN_TRIAL_PLAN"].includes(
        getters.companyHasSign
      )
    ) {
      return rootGetters.hasSignPermission
        ? "ACCESS_ALLOWED"
        : "USER_IS_NOT_ADMIN";
    } else {
      return getters.companyHasSign;
    }
  },
  suitePermission: (state, getters, rootState, rootGetters) => {
    if (
      ["PRODUCT_IN_ACTIVE_PLAN", "PRODUCT_IN_TRIAL_PLAN"].includes(
        getters.companyHasSuite
      )
    ) {
      return rootGetters.hasSuitePermission
        ? "ACCESS_ALLOWED"
        : "USER_IS_NOT_ADMIN";
    } else {
      return getters.companyHasSuite;
    }
  },
  controlPermission: (state, getters, rootState, rootGetters) => {
    if (
      ["PRODUCT_IN_ACTIVE_PLAN", "PRODUCT_IN_TRIAL_PLAN"].includes(
        getters.companyHasControl
      )
    ) {
      return rootGetters.hasControlPermission
        ? "ACCESS_ALLOWED"
        : "USER_IS_NOT_ADMIN";
    } else {
      return getters.companyHasControl;
    }
  },
  trackPermission: (state, getters, rootState, rootGetters) => {
    if (
      ["PRODUCT_IN_ACTIVE_PLAN", "PRODUCT_IN_TRIAL_PLAN"].includes(
        getters.companyHasTrack
      )
    ) {
      return rootGetters.hasTrackPermission
        ? "ACCESS_ALLOWED"
        : "USER_IS_NOT_ADMIN";
    } else {
      return getters.companyHasTrack;
    }
  },

  hasSuitePagePermission: (_state, _getters, _rootState, rootGetters) => {
    const permission = rootGetters?.currentUser?.permission || {};
    const isAdmin = rootGetters.isAdmin;
    return {
      inventory: permission.conecta_sign == "admin" || isAdmin,
      company_profile: permission.company_profile == "admin" || isAdmin,
      reseller:
        (permission.reseller == "admin" || isAdmin) && rootGetters.isReseller,
      manage_admins: permission.manage_admins == "admin" || isAdmin,
      billing: permission.billing == "admin" || isAdmin,
      reports: permission.reports === "admin" || isAdmin,
      users:
        permission.conecta_suite === "admin" ||
        permission.conecta_sign === "admin" ||
        isAdmin,
      contact_us: true,
      workspace_diagnosis: true,
    };
  },

  hasSelfChekoutPermission: (state, getters, rootState, rootGetters) =>
    rootGetters.currentUser.permission.billing == "admin" ||
    rootGetters.isAdmin,

  userPermissions: (state, getters, rootState, rootGetters) => {
    return rootGetters.currentUser.permission;
  },

  hasProductPermission: (state, getters) => {
    return {
      conecta_sign: getters.signPermission,
      conecta_suite: getters.suitePermission,
      conecta_track: getters.trackPermission,
      conecta_control: getters.controlPermission,
    };
  },

  permissionStatus: (state) => state.permissionStatus,
};

// actions
const actions = {};

// mutations
const mutations = {
  setSelectedUsers(state, selectedUsers) {
    state.selectedUsers = selectedUsers;
  },

  setPermissionDialog(state, { show, status, app }) {
    state.showPermissionDialog = show;
    state.permissionStatus = status;
    state.pageToCheckPermission = app;
  },

  resetPermissionDialog(state) {
    state.showPermissionDialog = false;
    setTimeout(() => {
      state.permissionStatus = "";
      state.pageToCheckPermission = "conecta_suite";
    }, 100);
  },

  saveAccessDialogInfo(state, info) {
    localStorage.setItem("permissionDialog", JSON.stringify(info));
  },

  clearDelayedAccessDialog() {
    localStorage.removeItem("permissionDialog");
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
