<template>
  <div>
    <v-navigation-drawer
      :value="true"
      color="#331D6E"
      class="pa-1 pt-0 overflow-visible"
      app
      :width="expand ? sidebarExpanded : sidebarSmall"
      :permanent="isSmallScreen"
      :temporary="expand && isSmallScreen"
      v-click-outside="temporaryClickOutside"
    >
      <!-- Ícone flutuante no topo direito -->
      <div style="position: absolute">
        <v-btn
          color="white"
          tile
          depressed
          :min-width="28"
          :height="28"
          class="pa-0 expand-button"
          :style="expand ? 'left: 282px;' : 'left: 52px;'"
          @click="toggleMenu"
        >
          <v-icon
            color="#10131A"
            v-text="expand ? 'ph-caret-left' : 'ph-caret-right'"
          />
        </v-btn>
      </div>

      <div
        color="#331D6E"
        class="logo-container link d-flex align-center justify-space-between mx-1"
      >
        <v-img
          max-width="34"
          class="logo"
          src="@/assets/appslogos/icons/icon-suite.png"
          @click="toggleMenu"
        />

        <v-img
          v-if="expand"
          contain
          max-width="170"
          src="@/assets/appslogos/full/ConectaSuiteBrancoFull.png"
          @click="controlCard"
        />

        <v-icon v-if="expand" color="white" right class="" @click="controlCard">
          ph-caret-up-down
        </v-icon>
      </div>

      <div
        @mouseover="rail ? (expand = true) : ''"
        @mouseleave="rail ? (expand = false) : ''"
        style="max-height: calc(100% - 100px); height: calc(100% - 100px)"
      >
        <div :class="`list-container ${expand ? '' : 'hide-scroll'}`">
          <!-- MENUS -->
          <v-list nav dense dark class="pt-0" :disabled="disableNavigation">
            <div style="height: 12px; width: 24px"></div>
            <div v-for="group in menu" :key="group.title">
              <v-subheader
                v-if="expand && group.title"
                class="mt-3 group-title"
                :style="`${group?.opacity ? 'opacity: 60%' : ''}`"
              >
                {{ group.title }}
              </v-subheader>

              <div
                v-for="item in group.menus"
                v-show="!item.hidden"
                :key="item.title"
              >
                <v-list-group
                  :style="`${item.opacity ? 'opacity: 60%' : ''}`"
                  v-if="item.submenu"
                  :class="`${
                    isActiveGroup(item) || isHiddenPage(item)
                      ? 'active-group'
                      : ''
                  } ma-0 pa-0`"
                >
                  <template v-slot:activator>
                    <v-list-item class="ma-0 pa-0">
                      <v-list-item-icon class="mr-3" style="max-width: 24px">
                        <v-icon v-text="item.icon" class="mt-2" />
                      </v-list-item-icon>

                      <v-list-item-content class="py-4 sidebar-menu-item">
                        <v-list-item-title
                          v-if="expand"
                          class="sidebar-menu-item title-item"
                          v-text="item.title"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <div v-for="(subItem, i) in item.submenu" :key="i">
                    <v-list-item
                      :style="`${group.opacity ? 'opacity: 60%' : ''}`"
                      v-show="!subItem.hidden"
                      @click="execute(subItem.tag, subItem.to, subItem.title)"
                      :class="` ${
                        isActiveItem(subItem) ? `active-submenu-item` : ''
                      } py-0 my-0`"
                    >
                      <v-list-item-icon :class="`${expand ? 'ml-4 mr-3' : ''}`">
                        <v-icon v-if="subItem.icon" v-text="subItem.icon" />
                        <img
                          v-else
                          :src="subItem.logo"
                          height="22"
                          class="logo-item pa-1"
                        />
                      </v-list-item-icon>

                      <v-list-item-title
                        :class="`title-item white--text ${
                          isActiveItem(subItem) ? 'active-item-text' : ''
                        }`"
                        v-text="subItem.title"
                      />
                      <v-list-item-action
                        v-if="subItem.beta"
                        class="my-0"
                        style="min-width: auto !important"
                      >
                        <v-chip
                          color="blue"
                          dark
                          :title="$t('common.availableIndefinitely')"
                          x-small
                          class="font-weight-medium"
                          >Beta
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                </v-list-group>

                <v-list-item
                  v-else-if="!item.submenu"
                  @click="execute(item.tag, item.to, item.title)"
                  :class="`${
                    isActiveItem(item) || isHiddenPage(item)
                      ? 'active-item'
                      : ''
                  } py-0 my-1`"
                  :style="`${item.opacity ? 'opacity: 60%' : ''}`"
                >
                  <v-list-item-icon class="mr-3">
                    <v-icon v-if="item.icon" v-text="item.icon" />
                    <img
                      v-if="item.logo"
                      :src="item.logo"
                      contain
                      height="24"
                    />
                  </v-list-item-icon>
                  <v-list-item-title
                    :class="`title-item white--text ${
                      isActiveItem(item) ? 'active-item-text' : ''
                    }`"
                    v-text="item.title"
                  />
                  <v-list-item-action
                    v-if="item.beta"
                    class="my-0"
                    style="min-width: auto !important"
                  >
                    <v-chip
                      color="blue"
                      dark
                      :title="$t('common.availableIndefinitely')"
                      x-small
                      class="font-weight-medium"
                      >Beta
                    </v-chip>
                  </v-list-item-action>
                  <v-list-item-action
                    v-else-if="item.new"
                    class="my-0"
                    style="min-width: auto !important"
                  >
                    <v-chip
                      color="success"
                      dark
                      x-small
                      class="font-weight-medium"
                      >{{ $t("common.new") }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </div>
          </v-list>

          <!-- UPSELL -->
          <div
            class="cta-box"
            v-if="!hasIntermediaryPartner && expand && !isValidEnterpriseAnnual"
          >
            <v-icon
              v-if="!$vuetify.breakpoint.mobile"
              color="white"
              size="50"
              class="mb-4"
            >
              ph-fill ph-rocket-launch
            </v-icon>

            <p class="white--text cta-description-text">
              {{ ctaCall }}
            </p>

            <!-- TODO: FUNCIONALIDADE DE PARTICIPAR DE UM GRUPO DE FEEDBACK -->
            <v-btn
              @click="
                execute('billing', '/billing/upgrade', 'Contratar um plano')
              "
              class="upgrade-btn"
              elevation="0"
              block
              max-height="35"
            >
              <span class="text-capitalize upgrade-btn-text">
                {{
                  companyPlanName == "enterprise"
                    ? $t("sidebar.feedbackGroupCTA")
                    : $t("common.upgradeCTA")
                }}</span
              >
              <v-icon color="#5B36C3" right>ph-arrow-right</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- FOOTER ITEMS -->
        <v-list nav dense dark class="mt-2" :disabled="disableNavigation">
          <v-divider class="border-opacity-25 mb-3" color="#896bbe" />

          <v-list-item
            v-if="!hasIntermediaryPartner"
            @click="
              execute('billing', '/billing/upgrade', 'Contratar um plano')
            "
            class="py-0 mb-1 mt-4"
            :style="`${this.disableNavigation ? 'opacity: 60%' : ''}`"
          >
            <v-list-item-icon class="mr-3">
              <v-icon v-text="'ph-gift'" />
            </v-list-item-icon>
            <v-list-item-title
              class="title-item"
              v-text="$t('sidebar.plans')"
            />
          </v-list-item>

          <v-list-item
            v-if="hasConectaSupport"
            @click="initSupportChat"
            class="py-0 my-1"
            :style="`${disableNavigation ? 'opacity: 60%' : ''}`"
          >
            <v-list-item-icon class="mr-3">
              <v-icon v-text="'ph-headset'" />
            </v-list-item-icon>
            <v-list-item-title
              class="title-item"
              v-text="$t('techDiagnosis.chatSupport')"
            />
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { goToControl, initFreshChatGlobal } from "@/helpers/services/utils";
import { TRIAL } from "@/helpers/variables/backendConstants";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      rail: false,
      sidebarExpanded: "300",
      sidebarSmall: "72",
    };
  },
  computed: {
    ...mapGetters([
      "isReseller",
      "isConecta",
      "company",
      "hasIntermediaryPartner",
      "sidebar",
      "currentUser",
      "hasConectaSupport",
      "mainDomain",
      "companyFreshChatConfig",
      "conectaNuvemResellerToken",
    ]),

    companyPlanName() {
      const { current_plan } = this.company || {};
      if (!current_plan) return "standard";
      if (current_plan.status == TRIAL) return "";

      return current_plan.plan.name.toLowerCase();
    },

    ctaCall() {
      const { current_plan } = this.company || {};

      if (!current_plan) return this.$t("sidebar.standard");
      if (current_plan.status == TRIAL) return this.$t("sidebar.standard");

      const planName = this.companyPlanName || "standard";

      if (planName.includes("basic")) return this.$t("sidebar.basic");
      else if (planName.includes("standard"))
        return this.$t("sidebar.standard");
      else if (planName.includes("security"))
        return this.$t("sidebar.security");
      else if (planName.includes("enterprise"))
        return this.$t("sidebar.enterprise");
      else return this.$t(`sidebar.${planName}`);
    },

    isConectaSignEditor() {
      const path = this.$route.path;
      let query = path.split("/");
      let pathWithoutKey = `/${query[1]}/${query[2]}`;
      if (query[3] === "vacation-message") {
        pathWithoutKey += `/${query[3]}s`;
      }
      return pathWithoutKey;
    },

    sidebarWidth() {
      return this.$vuetify.breakpoint.lgAndUp;
    },

    menu() {
      return [
        {
          title: "",
          menus: [
            {
              title: this.$t("sidebar.home"),
              icon: "ph-house",
              tag: "",
              to: "/",
              opacity: this.disableNavigation,
            },
            {
              title: this.$t("sidebar.install"),
              icon: "ph-puzzle-piece",
              tag: "",
              to: "/install",
              hidden: !this.isConecta && !this.isDevConecta && this.company, // TODO: Remover quando for disponibilizar
              opacity: false,
            },
            {
              title: this.$t("sidebar.patnerPanel"),
              icon: "ph-bank",
              tag: "reseller",
              hiddenRoutes: [
                "/partner/billing/transactions",
                "/partner/customer",
                "/partner/customer/plans/new",
              ],
              value: false,
              hidden: !this.isReseller,
              opacity: this.disableNavigation,
              submenu: [
                {
                  icon: "ph-address-book-tabs",
                  title: this.$t("sidebar.clients"),
                  to: "/partner/customers",
                  opacity: this.disableNavigation,
                },
                {
                  icon: "ph-credit-card",
                  title: this.$t("sidebar.billing"),
                  to: "/partner/billing",
                  opacity: this.disableNavigation,
                },
                {
                  icon: "ph-gift",
                  title: this.$t("sidebar.plans"),
                  to: "/admin/plans/edit",
                  hidden: !this.isConecta,
                  opacity: this.disableNavigation,
                },
                {
                  icon: "ph-gear",
                  title: this.$t("sidebar.services"),
                  to: "/admin/services/edit",
                  hidden: !this.isConecta,
                  opacity: this.disableNavigation,
                },
                {
                  icon: "ph-bell",
                  title: this.$t("sidebar.notificationCenter"),
                  to: "/admin/notifications",
                  hidden: !this.isConecta,
                  opacity: this.disableNavigation,
                },
                {
                  icon: "ph-user-circle-dashed",
                  title: this.$t("sidebar.clientsBans"),
                  to: "/admin/ban",
                  hidden: !this.isConecta,
                  opacity: this.disableNavigation,
                },
              ],
            },
            {
              title: this.$t("sidebar.createUser"),
              icon: "ph-user-plus",
              tag: "conecta_suite",
              to: "/onboarding-new-user",
              opacity: this.disableNavigation,
            },
            {
              title: this.$t("sidebar.removeUser"),
              icon: "ph-user-minus",
              tag: "conecta_suite",
              to: "/offboarding-user",
              opacity: this.disableNavigation,
            },
          ],
        },
        {
          title: this.$t("sidebar.toManage").toUpperCase(),
          opacity: this.disableNavigation,
          menus: [
            {
              title: this.$t("tableHeaders.users"),
              icon: "ph-users",
              to: "/users",
              tag: "conecta_suite",
              hiddenRoutes: ["/user"],
              opacity: this.disableNavigation,
            },
            {
              title: this.$t("sidebar.vacation"),
              icon: "ph-island",
              tag: "conecta_suite",
              to: "/sign/vacation-message",
              opacity: this.disableNavigation,
            },
            {
              title: this.$t("sidebar.company"),
              icon: "ph-buildings",
              tag: "company",
              value: false,
              opacity: this.disableNavigation,
              submenu: [
                {
                  title: this.$t("sidebar.permissions"),
                  icon: "ph-lock-key",
                  to: "/settings/user-permissions",
                  tag: "manage_admins",
                  opacity: this.disableNavigation,
                },
                {
                  title: this.$t("sidebar.profile"),
                  icon: "ph-buildings",
                  to: "/settings/profile",
                  tag: "company_profile",
                  opacity: this.disableNavigation,
                },
                {
                  title: this.$t("sidebar.inventory"),
                  icon: "ph-clipboard",
                  to: "/inventory",
                  tag: "inventory",
                  beta: true,
                  opacity: this.disableNavigation,
                },
                {
                  title: this.$t("sidebar.reports"),
                  icon: "ph-chart-line-up",
                  tag: "reports",
                  to: "/reports",
                  opacity: this.disableNavigation,
                },
                {
                  title: this.$t("sidebar.controlShifts"),
                  logo: require("@/assets/appslogos/icons/icon-control.png"),
                  tag: "conecta_control",
                  opacity: this.disableNavigation,
                },
              ],
            },
          ],
        },
        {
          title: this.$t("companyProfile.email").toUpperCase(),
          opacity: this.disableNavigation,
          menus: [
            {
              icon: "ph-article-medium",
              title: this.$t("sidebar.emailSignatures"),
              to: "/sign/email-signature/templates",
              opacity: this.disableNavigation,
            },
            {
              icon: "ph-chat-text",
              title: this.$t("sidebar.vacationMessages"),
              to: "/sign/templates/vacation-messages",
              opacity: this.disableNavigation,
            },
            {
              icon: "ph-clock-clockwise",
              title: this.$t("sidebar.signAutomation"),
              to: "/sign/users/sign-automation",
              opacity: this.disableNavigation,
            },
          ],
        },
      ];
    },

    isSmallScreen() {
      return (
        this.$vuetify.breakpoint.mobile || this.$vuetify.breakpoint.mdAndDown
      );
    },

    expand: {
      get() {
        return this.sidebar;
      },
      set(value) {
        this.setSidebar(value);
      },
    },

    isValidEnterpriseAnnual() {
      if (!this.company) return false;
      return (
        this.companyPlanName === "enterprise" &&
        this.company.current_plan.status === "ACTIVE"
      );
    },

    disableNavigation() {
      const routeName = this.$route.name;
      return !this.company && routeName == "Install";
    },
  },

  watch: {
    sidebarWidth() {
      if (this.sidebarWidth) {
        this.setSidebarMini(true);
      } else {
        this.setSidebarMini(false);
      }
    },

    expand(isVisible) {
      if (isVisible) {
        this.closeControlCard();
      }
    },

    isSmallScreen() {
      if (this.isSmallScreen) {
        this.rail = false;
        this.expand = true;
        this.setSidebarMini(false);
        this.closeControlCard();
      } else {
        this.rail = !this.expand;
      }
    },
  },

  methods: {
    ...mapMutations([
      "setSidebarMini",
      "setSidebar",
      "closeControlCard",
      "toggleControlCard",
      "setOpenedChat",
      "setFloatingGuide",
      "setChatWidgetOpened",
      "setSignatureSuccessHintScreen",
    ]),
    ...mapActions(["verifyHubspotToken"]),

    controlCard() {
      this.toggleControlCard();
    },

    async initSupportChat() {
      if (this.conectaNuvemResellerToken === this.company.reseller_token) {
        initFreshChatGlobal(
          this.currentUser,
          this.companyFreshChatConfig,
          this.hasConectaSupport
        );

        return;
      }
      await this.verifyHubspotToken();
      this.setOpenedChat();
      this.setFloatingGuide(false);
    },

    toggleMenu() {
      if (!this.isSmallScreen) {
        this.rail = !this.rail;
      }
      this.expand = !this.expand;
      this.closeControlCard();
    },

    temporaryClickOutside() {
      if (this.expand && this.isSmallScreen) {
        this.expand = false;
        this.closeControlCard();
      }
    },

    isActiveItem(menu_item) {
      return (
        this.$route.path === menu_item.to ||
        menu_item.to === this.isConectaSignEditor
      );
    },

    isActiveGroup(menu_item) {
      return menu_item.submenu.find((subitem) => this.isActiveItem(subitem))
        ? true
        : false;
    },

    isHiddenPage(menu_item) {
      return (
        menu_item.hiddenRoutes &&
        menu_item.hiddenRoutes.includes(this.$route.path)
      );
    },

    setPath(path) {
      if (this.$router.currentRoute.path !== path) {
        this.$router.push({ path: path });
      }
    },

    execute(menuItem, path, title) {
      if (!this.company) return;
      const { main_domain } = this.company;
      //TODO: remover essa lógica quando houver permissionamento para reports
      const analytics = getAnalytics();
      logEvent(analytics, "navigated_by", {
        menuItem,
        path,
        title,
        navigation_by: "Sidebar",
        main_domain,
      });

      // Se for control, mesmo sem ACCESS_ALLOWED, usuário é redirecionado
      if (menuItem === "conecta_control") {
        goToControl();
      } else if (path === "/users") {
        this.setSignatureSuccessHintScreen();
        this.setPath(path);
      } else {
        if (path.includes("/billing/upgrade")) {
          logEvent(analytics, "go_to_self_checkout", {
            click: "sidebar",
            main_domain: this.mainDomain,
            email: this.currentUser.email,
            description: `Clicou no botão 'Contratar um plano ou serviço' da barra lateral (sidebar)`,
          });
        }

        this.setPath(path);
      }

      if (this.isSmallScreen) this.expand = false;
    },
  },

  beforeMount() {
    this.expand = this.$vuetify.breakpoint.mobile ? false : this.sidebar;
  },
};
</script>
<style scoped>
.rotate-icon {
  transform: rotate(180deg);
}

.list-container {
  overflow-y: auto;
  max-height: calc(100% - 130px);
  position: relative;
}

.list-container::-webkit-scrollbar {
  width: 5px;
}

.list-container::-webkit-scrollbar-thumb {
  background-color: #977ee1;
  border-radius: 4px;
}

.list-container::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.hide-scroll::-webkit-scrollbar {
  display: none !important;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hidden-over {
  overflow: hidden;
}

.active-group {
  background-color: #3f2388;
  border-radius: 5px;
  color: #ddc9ff !important;
}

.active-item {
  box-shadow: 0px 0px 0px 0px #9e7fd4;
  background: #3f2388 !important;
  transition: 0.5s ease;
  border-radius: 12px !important;
}

.active-item-text {
  font-weight: 600 !important;
}

.group-title {
  color: #ffe0ad !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
}

.sidebar-menu-item {
  font-family: "Poppins", sans-serif !important;
  font-size: 1rem;
  font-weight: 500;
}

.cta-box {
  background-color: #704ecf;
  width: 232px;
  min-height: 218px;
  border-radius: 0.75rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  padding: 15px 24px;
  margin-top: 1rem;
}

.rocket-icon {
  margin-bottom: 13px;
}

.title-item {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

.logo-container {
  border-radius: 12px !important;
  border: 1px solid #624b9e8f !important;
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  margin-top: 13px;
  margin-bottom: 23px;
}

.logo {
  background-color: #fff;
  border-radius: 8px;
  width: 34px;
  height: 34px;
  margin-right: 4px;
}

.logo-item {
  background-color: #fff;
  border-radius: 7px;
}

.link:hover {
  cursor: pointer;
}

.upgrade-btn {
  border-radius: 12px;
}

.upgrade-btn-text {
  color: #5b36c3;
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
}

.cta-description-text {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.active-submenu-item {
  box-shadow: 0px 0px 0px 0px #9e7fd4;
  background: #4d3099 !important;
  transition: 0.5s ease;
  color: white;
}

.floating-icon {
  background-color: white;
  border-radius: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
  left: 96%;
  cursor: pointer;
  z-index: 9999;
  display: inline-block !important;
}

.expand-button {
  border-radius: 4.89px;
  top: 80px;
  z-index: 8;
  -webkit-box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: left 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.expand-button:focus {
  color: white !important;
}
</style>

<style>
.v-list-item--active .theme--dark.v-icon {
  color: #fff !important;
}
</style>
