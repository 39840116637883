<template>
  <v-container class="pa-0 my-0" style="max-width: 1160px">
    <v-card-title class="pa-0 mt-0">
      {{ $t("common.usersSelecteds") }} ({{ numberOfUsersSelected }})
    </v-card-title>

    <Alert
      v-if="!hasSelected"
      :text="false"
      class="mt-4"
      color="error"
      dense
      dark
    >
      {{ $t("common.noUserPleaseSelect") }}
    </Alert>

    <v-autocomplete
      v-model="chosenUsers"
      :items="sortedUsers"
      open-on-clear
      clearable
      :loading="loadingSign"
      item-text="name"
      item-value="email"
      :label="hasSelected ? $t('common.usersSelecteds') : $t('common.users')"
      multiple
      chips
      outlined
      class="mt-5"
      color="accent"
      dense
      append-icon=""
      filled
      :rules="rules"
      :filter="customFilter"
      @change="notifyChange"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          color="lighten-3"
          :input-value="selected"
          pill
          class="ma-1 link"
          @click.stop="firstUserSelected = item"
        >
          <v-avatar width="30" left>
            <img :src="item.photo || '@/assets/user-default.png'" />
          </v-avatar>
          <span class="px-2">
            {{ item.name }}
          </span>
          <v-icon
            color="secondary lighten-1"
            @click="parent.selectItem(item)"
            v-text="'mdi-close-circle'"
          />
        </v-chip>
      </template>

      <template v-slot:item="{ item, attrs, on }">
        <template>
          <v-list-item
            v-on="on"
            v-bind="attrs"
            :input-value="itemIsSelected(item)"
            active-class="light-blue lighten-5"
          >
            <v-list-item-action>
              <v-checkbox
                v-model="attrs.inputValue"
                :color="attrs.inputValue ? 'accent' : 'secondary'"
              />
            </v-list-item-action>
            <v-list-item-avatar size="32">
              <v-img :src="item.photo || '@/assets/user-default.png'" contain />
            </v-list-item-avatar>
            <v-list-item-content class="pl-4">
              <v-list-item-title
                :class="attrs.inputValue ? 'accent--text' : 'secondary--text'"
                v-html="item.name"
              />
              <v-list-item-subtitle v-html="item.email" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-autocomplete>

    <div v-if="exceededFreeLimit" class="upgrade-alert py-2 px-4 mb-4">
      <v-icon color="#F39C12" class="mr-4 ml-2">ph-fill ph-warning</v-icon>

      <span class="alert-text">
        <span>Atenção:</span> O plano Basic permite a seleção de apenas um
        usuário. Faça Upgrade e tenha acesso imediato a seleção de múltiplos
        usuários!
      </span>

      <v-spacer></v-spacer>

      <v-btn
        color="accent"
        elevation="0"
        class="ml-4 text-none font-weight-medium"
        dark
        id="upgrade-warning-selet-users-in-vacation"
        @click="goToSelfCheckout"
      >
        <v-icon left v-text="'ph-crown-simple'" />
        <span>{{ $t("common.upgradeCTA") }}</span>
      </v-btn>
    </div>

    <TemplatePreview
      v-if="templateHtml"
      :title="templateName || $t('common.vacationMessages')"
      :subject="templateSubject"
      :html="templateHtml"
      :infoToRender="firstUserSelected"
      show-expand
      hide-bottom
      is-vacation-template
      outlined
    />

    <v-card-actions class="px-0 mt-4">
      <v-spacer />
      <v-btn
        @click.stop="$emit('skip')"
        :disabled="!hasSelected || loadingSign"
        color="accent"
        class="px-5"
        large
        outlined
      >
        {{ $t("action.skip") }}
      </v-btn>

      <v-tooltip top v-if="exceededFreeLimit">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              :disabled="!hasSelected || loadingSign || exceededFreeLimit"
              :loading="loadingSign"
              color="accent"
              class="px-5 ml-2"
              large
            >
              {{ $t("action.applyModel") }}
            </v-btn>
          </div>
        </template>
        <span>Selecione apenas um usuário para aplicar a mensagem!</span>
      </v-tooltip>

      <v-btn
        v-else
        @click.stop="$emit('apply', [...chosenUsers])"
        :disabled="!hasSelected || loadingSign || exceededFreeLimit"
        :loading="loadingSign"
        color="accent"
        class="px-5"
        large
      >
        {{ $t("action.applyModel") }}
      </v-btn>
    </v-card-actions>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { sortByName } from "@/helpers/services/utils";
import { getAnalytics, logEvent } from "firebase/analytics";
import TemplatePreview from "@/components/sign/general/TemplatePreview";
const userLimitWihoutSignInPlan = 1;

export default {
  name: "ConfirmTemplateApply",

  props: {
    selectedUsers: { type: Array, required: true },
    templateSubject: { type: String, required: true },
    templateName: { type: String, default: "" },
    templateHtml: { type: String, required: true },
  },

  components: { TemplatePreview },

  data() {
    return {
      search: "",
      firstUserSelected: null,
      chosenUsers: [...this.selectedUsers],
    };
  },

  computed: {
    ...mapGetters([
      "users",
      "loadingSign",
      "hasConectaSign",
      "mainDomain",
      "currentUser",
    ]),

    rules() {
      if (this.hasConectaSign) return [];

      return [(v) => v.length <= userLimitWihoutSignInPlan || ""];
    },

    numberOfUsersSelected() {
      return this.chosenUsers.length;
    },

    exceededFreeLimit() {
      if (this.hasConectaSign) return false;

      return this.chosenUsers.length > userLimitWihoutSignInPlan;
    },

    hasSelected() {
      return this.numberOfUsersSelected > 0;
    },

    sortedUsers() {
      return sortByName(this.users);
    },

    filteredUsers() {
      return this.search
        ? this.sortedUsers.filter(
            (user) =>
              // Filtra por nome
              user.name.toUpperCase().match(this.search.toUpperCase()) ||
              // Filtra por departamento
              user.departament.toUpperCase().match(this.search.toUpperCase()) ||
              // Filtra por email
              user.email.toUpperCase().match(this.search.toUpperCase())
          )
        : this.sortedUsers;
    },
  },

  methods: {
    goToSelfCheckout() {
      const analytics = getAnalytics();
      logEvent(analytics, "go_to_self_checkout", {
        click: "Alerta de selecionar usuários no fluxo de férias",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: `Clicou para conhecer planos ao selecionar usuários de férias`,
      });
      this.$router.push("/billing/upgrade");
    },

    notifyChange() {
      this.$emit("change:selected-users", this.chosenUsers);
    },

    itemIsSelected(item) {
      return this.chosenUsers.includes(item.email);
    },

    clickedOnUserCard(active, user, toggle) {
      if (active) {
        this.firstUserSelected = user;
      } else {
        toggle();
      }
    },

    customFilter(user, queryText) {
      const text = queryText.toLowerCase();
      return (
        user.name.toLowerCase().includes(text) ||
        user.email.toLowerCase().includes(text)
      );
    },
  },
};
</script>
<style>
.selected-border {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 3px !important;
  border-color: var(--v-primary-base) !important;
}

.upgrade-alert {
  display: flex;
  align-items: center;
  background-color: #fdebd0;
}

.alert-text {
  color: #73510d;
  font-size: 14px;
}
.alert-text span {
  font-weight: 600;
}
</style>
