<template>
  <v-container v-if="user" fluid class="pa-4 pt-0">
    <v-card class="mx-auto transparent" max-width="1520" elevation="0">
      <v-row>
        <!-- USER CARD -->
        <v-col class="col-12">
          <Alert
            v-if="!user.is_gmail_enabled"
            color="warning"
            class="mt-5"
            text
            dense
            style="width: 100%"
          >
            <span
              >Este usuário não possui Gmail ativo no Workspace. Criar email
              secundário ou aplicações que envolvam Gmail, não surtirá efeito.
            </span>
          </Alert>
        </v-col>
        <v-col class="col-12 col-md-3">
          <ComponentCardUser :user="user"></ComponentCardUser>
        </v-col>

        <!-- CONTENT CARD -->
        <v-col class="col-12 col-md-9" v-if="!loading">
          <VacationResponder
            v-if="!isCompanyMicrosoft"
            :id="user.id_google"
            :user="user"
          />
          <Delegate
            v-if="!isCompanyMicrosoft"
            :user-key="user.key"
            :user_data="user"
            :email="user.email"
          />
          <UserGroups
            v-if="!isCompanyMicrosoft"
            class="ms-1 mb-4"
            @addSendAs="addSendAs"
            :user="user"
            outlined
          />
          <EmailAliases
            v-if="!isCompanyMicrosoft"
            class="ms-1"
            :user="user"
            outlined
          />
          <SendAs
            v-if="!isCompanyMicrosoft"
            class="me-1"
            :user="user"
            outlined
            :getUserSendAs="updateSendAs"
          />
          <UserCalendar
            v-if="!isCompanyMicrosoft"
            class="me-1"
            :user="user"
            outlined
          />
          <MigrateEmail v-if="!isCompanyMicrosoft" class="me-1" :user="user" />
          <UserSharedDrives
            v-if="!isCompanyMicrosoft"
            class="me-0 ml-1 mb-4"
            :user="user"
            outlined
          />
          <SignatureUser
            v-if="!isCompanyMicrosoft"
            class="me-1"
            :user="user"
            :id="user.id_google"
            :email="user.email"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
  <v-container v-else class="d-flex justify-center mb-6 my-6">
    <v-col cols="12">
      <div class="text-center">
        <h3>Ops... O ID não foi encontrado.</h3>
      </div>
    </v-col>
  </v-container>
</template>

<script>
import ComponentCardUser from "@/components/user/ComponentCardUser.vue";
import VacationResponder from "@/components/user/VacationResponder.vue";
import Delegate from "@/components/user/Delegate.vue";
import MigrateEmail from "@/components/user/MigrateEmail.vue";
import UserGroups from "@/components/user/UserGroups.vue";
import EmailAliases from "@/components/user/EmailAliases.vue";
import SendAs from "@/components/user/SendAs.vue";
import SignatureUser from "@/components/user/SignatureUser.vue";
import { formatDateAndHour } from "@/helpers/services/utils";
import { findUserById } from "@/helpers/services/getUsers";
import { mapGetters, mapMutations } from "vuex";
import UserCalendar from "@/components/user/UserCalendar.vue";
import UserSharedDrives from "@/components/user/UserSharedDrives.vue";

export default {
  components: {
    VacationResponder,
    Delegate,
    UserGroups,
    EmailAliases,
    SendAs,
    SignatureUser,
    ComponentCardUser,
    MigrateEmail,
    UserCalendar,
    UserSharedDrives,
  },

  data() {
    return {
      user: false,
      loading: false,
      updateSendAs: false,
    };
  },

  computed: {
    ...mapGetters(["isCompanyMicrosoft"]),
  },

  methods: {
    formatDateAndHour,
    findUserById,
    ...mapMutations(["setLoading"]),

    addSendAs() {
      this.updateSendAs = true;
    },
  },

  watch: {
    "$route.query": function () {
      this.loading = true;
      this.user_id = this.$route.query.id;
      this.user = this.findUserById(this.user_id);
      this.loading = false;
    },
  },
  beforeMount() {
    this.user_id = this.$route.query.id;
    this.user = this.findUserById(this.user_id);
  },

  mounted() {
    this.setLoading(false);
  },
};
</script>

<style scoped>
.ajust-name-responsive-style {
  font-size: clamp(1.1rem, 1vw, 1.5rem);
}
</style>
