<template>
  <PageLoading />
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import PageLoading from "@/components/PageLoading.vue";

export default {
  name: "MicrosofCallback",

  components: { PageLoading },

  data: () => ({
    query: {},

    tries: 0,
    retryDelay: 8000,
    messages: ["", " "],
    retryMessages: [
      ["retry.justInstalled"],
      ["retry.ensureScopes", { link: "MARKETPLACE_APP_URL" }],
    ],
    nextPage: "",
  }),

  computed: {
    ...mapGetters(["token"]),
  },

  methods: {
    ...mapActions(["syncUsersAsync"]),
    ...mapMutations(["setToken"]),

    generateToken() {
      const params = Object.assign({}, this.$route.query);
      this.$router.replace({ query: null });

      if (!Object.keys(params).length) {
        this.routerPushNext();
      }

      const url = `${process.env.VUE_APP_API_BASE_URL}/auth/callback/ms`;

      return this.$axios
        .get(url, { params })
        .then(({ data }) => {
          this.setToken(data.token);
          this.syncUsersAsync();
          this.routerPushNext();
        })
        .catch((error) => {
          console.error(error);
          this.routerPushNext();
        })
        .finally(() => {
          this.loadingAuthentication = false;
        });
    },

    routerPushNext() {
      if (this.nextPage) {
        this.$router.push({ name: this.nextPage });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },

  mounted() {
    this.nextPage = localStorage.getItem("nextPage");

    this.generateToken();
  },
};
</script>
