import {
  STATUS,
  SUBSCRIPTIONS,
  partnerType,
  ENTERPRISE_SKU,
  BASIC_SKU,
} from "@/helpers/variables/backendConstants";

import {
  greaterOrEqualToday,
  checkExpiredDate,
  isTokenExpired,
} from "@/helpers/services/utils.js";
import { mergeChildren } from "@/helpers/services/filters";

import { registerUserToHubspotChat } from "@/helpers/hubspot";

import store from "@/store";

const state = () => ({
  company: null,
  control: null,
  products: [],
  plans: [],
  services: [],
  missingScopesSuite: [],
  loadingClientPlans: false,
  loadedClientPlans: false,
  uos: [],
  IsScheduleMeetingSnackbarVisible: false,
  actionsScheduleDialog: false,
  actionsScheduleType: "help",
  groups: [],
  functionalityClicked: undefined,
  companySharedDrives: [],
  chatWidgetOpened: false,
});

const getters = {
  isChatWidgetOpened: (state) => state.chatWidgetOpened,

  isDashTester: (state) => {
    const tags = state.company ? state.company.tag : [];
    return tags.includes("dashTester");
  },
  isSmallClient: (state) => state.company.users_number <= 5,
  groupEmails: (state) => state.groups,
  getActionsScheduleType: (state) => state.actionsScheduleType,
  isReseller: (state) => state.company && state.company.is_reseller,
  responsibleEmail: (state) => state.company.responsible_email,
  mainDomain: (state) => state.company?.main_domain,
  domains: (state) => state.company.domains_list,
  company: (state) => state.company,
  usersNumber: (state) => state.company.users_number,
  hasIntermediaryPartner: (state) =>
    state.company?.reseller_company &&
    state.company.reseller_company.type === partnerType.INTERMEDIARY,
  companyFreshChatConfig: (state) => {
    if (state.company) {
      return {
        domain: state.company.main_domain,
        responsible_email: state.company.responsible_email,
        name: state.company.name,
      };
    }
  },
  IsScheduleMeetingSnackbarVisible: (state) =>
    state.IsScheduleMeetingSnackbarVisible,
  isConectaControlClient: (state) => state.control,
  isConecta: (state) =>
    state.company?.main_domain
      ? state.company.main_domain === "conectanuvem.com.br"
      : false,
  isDevConecta: (state) =>
    state.company?.main_domain
      ? state.company.main_domain === "dev.conectanuvem.com.br"
      : false,
  isCompanyMicrosoft: (state) => state.company?.oauth === "microsoft",
  serviceProvider: (state) => state.company.oauth || "google",
  contractedProducts: (state) => state.products,
  trialPlans: (state) =>
    state.plans.filter((plan) => plan.status === STATUS.TRIAL) || [],
  hasTrialPlans: (state) =>
    state.plans.filter(
      (plan) =>
        plan.valid_status === STATUS.TRIAL &&
        plan.subscription_type === SUBSCRIPTIONS.TRIAL
    ).length > 0,
  contractedPlans: (state) => state.plans,
  hasContractedPlans: (state, getters) =>
    getters.contractedPlans.length > 0 &&
    getters.contractedPlans.some(
      (plan) => STATUS.ACTIVE === plan.status && plan.plan.rank > 0
    ),
  missingScopesSuite: (state) => state.missingScopesSuite,
  loadingClientPlans: (state) => state.loadingClientPlans,
  hasAcceptedTerms: (state) => {
    return Object.keys(state.company.reseller_accepted_terms).length > 0;
  },
  companyHasThisPlan: (state, getters, rootState, rootGetters) => {
    const companyPlans = getters.contractedPlans;
    const planKey = rootGetters.selectedPlanKey;
    return !rootGetters.selectedPlanKey
      ? companyPlans.find((plan) => {
          if (plan.plan.key === planKey) {
            return plan;
          }
        })
      : false;
  },
  doNotHaveCNPJ: (state) => !state.company.cnpj,
  companyLogo: (state) => state.company.logo_url || false,
  companyBanner: (state) => state.company.banner_url || false,
  companyBannerLink: (state) => state.company.banner_link || "",
  plansInTrialToShowSchedule: (state) => {
    const companyPlans = [...state.plans];
    return companyPlans.find((companyPlan) => {
      const { plan, status } = companyPlan;
      return (
        [ENTERPRISE_SKU, BASIC_SKU].includes(plan.sku) && status == STATUS.TRIAL
      );
    });
  },

  hasBasicInTrial: (state) => {
    const companyPlans = [...state.plans];
    return companyPlans.find((companyPlan) => {
      const { plan, status } = companyPlan;
      return BASIC_SKU == plan.sku && status == STATUS.TRIAL;
    });
  },

  hasBasicFreeActive: (state) => {
    return state.plans.find(
      (plan) =>
        plan.status === "ACTIVE" &&
        plan.subscription_type === "FREE" &&
        plan.plan?.sku == BASIC_SKU
    );
  },

  showActionsScheduleDialog: (state) => {
    return state.actionsScheduleDialog;
  },

  showScheduleMeeting: (state, getters) => {
    const hasAnyCompanyPlanInTrialOrActive = getters.activeTrialPlans.length;
    return (
      !hasAnyCompanyPlanInTrialOrActive || !!getters.plansInTrialToShowSchedule
    );
  },

  hasConectaSign: (state) =>
    state.plans.find(
      (plan) =>
        plan.plan.products.find((product) => product.name === "conecta_sign") &&
        ["TRIAL", "ACTIVE"].includes(plan.status)
    )
      ? true
      : false,
  hasSignAutomation: (state) =>
    state.plans.find(
      (plan) =>
        plan.plan.products.find(
          (product) => product.name === "sign_automation"
        ) && ["TRIAL", "ACTIVE"].includes(plan.valid_status)
    )
      ? true
      : false,
  hasConectaControl: (state) =>
    state.plans.find(
      (plan) =>
        plan.plan.products.find(
          (product) => product.name === "conecta_control"
        ) && ["TRIAL", "ACTIVE"].includes(plan.valid_status)
    )
      ? true
      : false,
  hasConectaTrack: (state) =>
    state.plans.find(
      (plan) =>
        plan.plan.products.find(
          (product) => product.name === "conecta_track"
        ) && ["TRIAL", "ACTIVE"].includes(plan.status)
    )
      ? true
      : false,
  organizationalUnits: (state) => state.uos,
  allOrgUnits: (state) => mergeChildren(state.uos),
  hasConectaSupport: (state, getters) =>
    state.company &&
    state.company.reseller_company.is_support_chat_available &&
    !getters.isSmallClient,
  activeTrialPlans: (state) =>
    state.plans.reduce((acc, plan) => {
      if ((plan.status == "ACTIVE") | (plan.status == "TRIAL")) {
        acc.push(plan);
      }
      return acc;
    }, []),
  /**
   * Getter to catch all products available on a active plan
   */
  activeProducts: (state) =>
    state.plans
      .reduce((acc, plan) => {
        if (plan.status === "ACTIVE") {
          acc.push(...plan.plan.products);
        }
        return acc;
      }, [])
      .map((product) => product.name),
  /**
   * Getter to catch all active products available on a trial plan
   */
  ativeTrialProducts: (state) =>
    state.plans
      .reduce((acc, plan) => {
        if (plan.valid_status === STATUS.TRIAL) {
          acc.push(...plan.plan.products);
        }
        return acc;
      }, [])
      .map((item) => item.name),
  /**
   * Getter to catch all expired products available on a trial plan
   */
  disabledTrialProducts: (state) =>
    state.plans
      .reduce((acc, plan) => {
        if (plan.valid_status === STATUS.TRIAL_EXPIRED) {
          acc.push(...plan.plan.products);
        }
        return acc;
      }, [])
      .map((item) => item.name),
  companyServices: (state, getters) =>
    getters.contractedPlans.filter(
      (item) =>
        item.status === STATUS.DISABLED &&
        item.subscription_type === SUBSCRIPTIONS.ONCE
    ),
  hasEndedTrial: (state) => {
    const currentPlanStatus = state.company?.current_plan?.status;
    if (!currentPlanStatus) {
      return true;
    } else if (currentPlanStatus === "TRIAL") {
      return checkExpiredDate(state.company.current_plan.end_trial);
    }
    return false;
  },

  getUsedDomainsToCheckDNS: (state, rootGetters) => {
    const allUserDomains = rootGetters.allEmailsInCompany.map(
      (user) => user.email.split("@")[1]
    );
    const usedDomains = allUserDomains.filter(
      (domain, index) => allUserDomains.indexOf(domain) === index
    );

    return usedDomains;
  },

  functionalityClicked: (state) => state.functionalityClicked,

  companySharedDrives: (state) => state.companySharedDrives,

  currentCompanyPlan(state) {
    return state.company?.current_plan || {};
  },

  companyInTrial(_, { currentCompanyPlan }) {
    return currentCompanyPlan.valid_status === STATUS.TRIAL;
  },

  currentPlanIsBasicFree(_, { currentCompanyPlan }) {
    return currentCompanyPlan.subscription_type === SUBSCRIPTIONS.FREE;
  },

  isSalesChat(_, getters) {
    const { hasContractedPlans, company } = getters;
    return !hasContractedPlans || company.suspended;
  },
};

const actions = {
  syncUos({ commit, getters }) {
    if (getters.isCompanyMicrosoft) return;

    return this._vm.$axios
      .get(process.env.VUE_APP_API_BASE_URL + "/uos/sync", {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setUos", data);
      })
      .catch((error) => {
        console.error("Vuex: syncUos(): ", error);
      });
  },

  getUos({ commit, getters }) {
    if (getters.isCompanyMicrosoft) return;

    return this._vm.$axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/uos`, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setUos", data);
      })
      .catch((err) => {
        console.error("Vuex: getUos(): ", err);
      });
  },

  // Carrega os planos que o cliente tem
  async getContractedPlans({ commit }, reload = false) {
    const url = process.env.VUE_APP_API_BASE_URL + "/company/plans";

    commit("startLoadingPlans");

    await this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setContractedPlans", data);
        if (reload) {
          location.reload();
        }
      })
      .catch((e) => {
        console.error("Vuex: getContractedPlans()", e);
        commit("setContractedPlans", null);
      });
  },

  // TODO: descontinuar esse método e usar o método setSignatureTemplateType
  changeModelType({ getters }, template_id) {
    let url =
      process.env.VUE_APP_API_BASE_URL +
      `/conecta-sign/${template_id}/template-default`;

    let auth = {
      headers: {
        Authorization: getters.token,
      },
    };

    return this._vm.$axios.put(url, {}, auth);
  },

  changeVacationModelType({ getters }, template_id) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/vacation/templates/${template_id}/default`;

    let auth = {
      headers: {
        Authorization: getters.token,
      },
    };

    return this._vm.$axios.patch(url, {}, auth);
  },

  async getCompany({ commit, dispatch }) {
    let endpoint = process.env.VUE_APP_API_BASE_URL + "/company";

    await this._vm.$axios
      .get(endpoint, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        if (data.is_banned) {
          dispatch("logoutWithDialog");
        }
        commit("setCompany", data);
        localStorage.setItem("companyOauth", data.oauth);

        if (
          [SUBSCRIPTIONS.ANNUAL, SUBSCRIPTIONS.FLEX].includes(
            data?.current_plan?.subscription_type
          )
        ) {
          commit(
            "setSelfCheckoutSubscriptionType",
            data.current_plan.subscription_type
          );
        }
      })
      .catch((err) => {
        console.error("Vuex: getCompany()", err);
      });
  },

  async getGoogleWorkspaceGroups({ commit, state }) {
    if (state.groups.length) {
      return;
    }

    const url = `${process.env.VUE_APP_API_BASE_URL}/company/groups`;

    const headers = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    await this._vm.$axios
      .get(url, headers)
      .then(({ data }) => {
        commit("setGroups", data.group_emails);
      })
      .catch((err) => {
        console.error("Vuex: getGoogleWorkspaceGroups()", err);
      });
  },

  async createGoogleWorkspaceGroup({ commit }, group_settings) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/groups`;
    let group = {};

    const headers = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    commit("setLoading", true);

    await this._vm.$axios
      .post(url, group_settings, headers)
      .then(({ data }) => {
        group = data;
        commit("setSnackBar", {
          message: `Grupo criado com sucesso.`,
          show: true,
          color: "success",
          timeout: 8000,
        });
      })
      .catch((err) => {
        console.error("Vuex: createGoogleWorkspaceGroup()", err);
        let message = "Ops, algo deu errado ao criar seu grupo.";
        if (err.response.request.status === 409) {
          message = "Ops, esse endereço de e-mail já está sendo utilizado.";
        }
        commit("setSnackBar", {
          message: message,
          show: true,
          color: "error",
          timeout: 8000,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });

    return group;
  },

  async updateGoogleWorkspaceGroup({ commit }, group_settings) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/groups`;
    let group = {};

    const headers = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    commit("setLoading", true);

    await this._vm.$axios
      .put(url, group_settings, headers)
      .then(({ data }) => {
        group = data;
        commit("setSnackBar", {
          message: `Grupo atualizado com sucesso.`,
          show: true,
          color: "success",
          timeout: 8000,
        });
      })
      .catch((err) => {
        console.error("Vuex: updateGoogleWorkspaceGroup()", err);
        commit("setSnackBar", {
          message: "Ops, algo deu errado ao atualizar seu grupo.",
          show: true,
          color: "error",
          timeout: 8000,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });

    return group;
  },

  async getGroupMembers(_, group_email) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/group/members?group_email=${group_email}`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.get(url, auth);
  },

  async insertGroupOnSharedDrives(_, { payload }) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/groups/shared-drives`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.post(url, payload, auth);
  },

  async deletePermissionOnSharedDrives(_, payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/shared-drives/permission`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.delete(url, {
      ...auth,
      data: payload,
    });
  },

  async addGoogleWorkspaceGroupMember({ commit }, member_settings) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/groups-members`;
    let group_member = {};

    const headers = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    commit("setLoading", true);

    await this._vm.$axios
      .post(url, member_settings, headers)
      .then(({ data }) => {
        group_member = data;
        commit("setSnackBar", {
          message: `Participante adicionado com sucesso.`,
          show: true,
          color: "success",
          timeout: 8000,
        });
      })
      .catch((err) => {
        console.error("Vuex: addGoogleWorkspaceGroupMember()", err);
        commit("setSnackBar", {
          message: "Ops, algo deu errado ao adicionar um participante.",
          show: true,
          color: "error",
          timeout: 8000,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });

    return group_member;
  },

  async updateGoogleWorkspaceGroupMember({ commit }, member_settings) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/groups-members`;
    let group_member = {};

    const headers = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    commit("setLoading", true);

    await this._vm.$axios
      .put(url, member_settings, headers)
      .then(({ data }) => {
        group_member = data;
        commit("setSnackBar", {
          message: `Participante atualizado com sucesso.`,
          show: true,
          color: "success",
          timeout: 8000,
        });
      })
      .catch((err) => {
        console.error("Vuex: updateGoogleWorkspaceGroupMember()", err);
        commit("setSnackBar", {
          message: "Ops, algo deu errado ao atualizar um participante.",
          show: true,
          color: "error",
          timeout: 8000,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });

    return group_member;
  },

  async deleteGoogleWorkspaceGroupMember({ commit }, settings) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/groups-members`;

    const headers = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    commit("setLoading", true);

    await this._vm.$axios
      .delete(url, {
        ...headers,
        data: settings,
      })
      .then(() => {
        commit("setSnackBar", {
          message: `Participante removido com sucesso.`,
          show: true,
          color: "success",
          timeout: 8000,
        });
      })
      .catch((err) => {
        console.error("Vuex: deleteGoogleWorkspaceGroupMember()", err);
        commit("setSnackBar", {
          message: "Ops, algo deu errado ao remover um participante.",
          show: true,
          color: "error",
          timeout: 8000,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  async verifyControlCompanyStatus({ commit, state }) {
    const endpoint =
      "https://us-central1-access-control2.cloudfunctions.net/verify-domain-on-access-control";
    const query_params =
      "?token=CHdjksW$$d23hjs&domain=" + state.company.main_domain;
    const url = endpoint + query_params;

    await this._vm.$axios
      .get(url)
      .then(({ data }) => {
        const status = data.status;
        commit("setControlStatusClient", status);
      })
      .catch((err) => {
        commit("setControlStatusClient", null);
        console.error("Vuex: verifyControlCompanyStatus()", err);
      });
  },

  checkProductPermission({ commit }, product) {
    commit("setLoading", true, { root: true });
    return this._vm.$axios
      .get(process.env.VUE_APP_API_BASE_URL + "/" + product, {
        headers: { Authorization: this._vm.token },
      })
      .finally(() => commit("setLoading", false, { root: true }));
  },

  checkHasProductPermissions({ state }, product_name) {
    const company_plans = state.plans;
    const user = store.state.users.currentUser;
    const permission = user.permission[product_name];
    let product_in_disabled_plan = false;

    for (let company_plan of company_plans) {
      if (
        company_plan.plan.products.some(
          (product) => product.name === product_name
        )
      ) {
        if (company_plan.status == "ACTIVE") {
          if (product_name === "conecta_control") {
            return "SUCCESS"; // verificação de permissão de usuário no control é feito no back
          }

          if (user.is_google_admin || permission === "admin") {
            return "SUCCESS";
          } else {
            return { code: "USER_IS_NOT_ADMIN", hasPermission: false };
          }
        }

        if (company_plan.status === "TRIAL") {
          const isExpiredTrial = !greaterOrEqualToday(company_plan.end_trial);

          if (isExpiredTrial) {
            return { code: "PLAN_TRIAL_ENDED", hasPermission: false };
          } else if (user.is_google_admin || permission === "admin") {
            return "SUCCESS";
          } else {
            return { code: "USER_IS_NOT_ADMIN", hasPermission: false };
          }
        } else {
          product_in_disabled_plan = true;
        }
      }
    }
    if (product_in_disabled_plan) {
      return { code: "PRODUCT_IN_DISABLED_PLAN", hasPermission: false };
    } else {
      return { code: "PRODUCT_DOES_NOT_EXIST_IN_PLANS", hasPermission: false };
    }
  },

  checkInventoryPermission() {
    return this._vm.$axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/company/profile/permission`,
      {
        headers: { Authorization: this._vm.token },
      }
    );
  },
  checkHasInventoryPermission({ dispatch }) {
    return dispatch("checkHasPagePermissions", "inventory");
  },

  checkCompanyProfilePermission() {
    return this._vm.$axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/company/profile/permission`,
      {
        headers: { Authorization: this._vm.token },
      }
    );
  },
  checkHasCompanyProfilePermission({ dispatch }) {
    return dispatch("checkHasPagePermissions", "company_profile");
  },

  checkHasSuitePermission({ dispatch }) {
    return dispatch("checkHasProductPermissions", "conecta_suite");
  },

  async purchaseInterest(ctx, product) {
    const url =
      process.env.VUE_APP_API_BASE_URL +
      `/company-plan/${product}/purchase-interest`;

    return await this._vm.$axios.post(
      url,
      {},
      {
        headers: { Authorization: this._vm.token },
      }
    );
  },

  async verifyHubspotToken({ dispatch, getters }) {
    if (getters.isSmallClient) return;

    let hubToken = JSON.parse(localStorage.getItem("hubspotToken"));

    if (!hubToken || isTokenExpired(hubToken)) {
      hubToken = await dispatch("getUserHubSpotToken");
      localStorage.setItem("hubspotToken", JSON.stringify(hubToken));
    }
    const { currentUser, isSalesChat } = getters;
    registerUserToHubspotChat(currentUser.email, hubToken, isSalesChat);
    dispatch("listenToChatWidget");
  },

  listenToChatWidget({ commit }) {
    window.addEventListener("message", (e) => {
      if (!e.data || "string" !== typeof e.data) {
        return;
      }
      var data = JSON.parse(e.data);
      if (!data.type || "open-change" !== data.type) {
        return;
      }
      if (data.data.isOpen) {
        commit("setChatWidgetOpened");
      } else {
        commit("setChatWidgetOpened", false);
      }
    });
  },

  getScopes({ commit, getters }) {
    if (getters.isCompanyMicrosoft) return;

    let urlSuite = process.env.VUE_APP_API_BASE_URL + "/client/scopes";

    this._vm.$axios
      .get(urlSuite, {
        headers: { Authorization: this._vm.token },
      })
      .then(({ data }) => {
        commit("setMissingScopesSuite", data.missing_scopes);
      })
      .catch(() => commit("setMissingScopesSuite", []));
  },

  getCompanyAddressByCnpj(ctx, cnpj) {
    const url = process.env.VUE_APP_CLOUD_FUNCTION_SEARCH_CNPJ;
    return this._vm.$axios.post(url, { cnpj });
  },

  async functionalityInterest({ state }, { product, functionality }) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/functionality-interest`;

    return await this._vm.$axios.post(
      url,
      {
        product,
        functionality: state.functionalityClicked || functionality,
      },
      {
        headers: { Authorization: this._vm.token },
      }
    );
  },
  async getCompanySharedDrives({ commit, getters }) {
    if (getters.isCompanyMicrosoft) return;

    const url = process.env.VUE_APP_API_BASE_URL + "/company/shared-drives";

    await this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setCompanySharedDrives", data);
        return;
      })
      .catch((err) => {
        console.error("Vuex: getCompanySharedDrives()", err);
        return err.response.data.code;
      });
  },

  checkRunningTasks() {
    const url = `${process.env.VUE_APP_API_BASE_URL}/company/has-task-running`;
    const auth = {
      headers: { Authorization: this._vm.token },
    };
    return this._vm.$axios.get(url, auth);
  },
};

const mutations = {
  setChatWidgetOpened(state, value = true) {
    state.chatWidgetOpened = value;
  },

  setUos(state, uos) {
    state.uos = uos;
  },
  startLoadingPlans(state) {
    state.loadingClientPlans = true;
  },
  setContractedProducts(state, products) {
    state.products = products;
  },
  setContractedPlans(state, plans) {
    state.plans = plans;
    state.loadingClientPlans = false;
    state.loadedClientPlans = true;
  },
  setControlStatusClient(state, status) {
    state.control = status;
  },

  setCompany(state, company) {
    state.company = company;
  },

  setGroups(state, groups = []) {
    state.groups = groups;
  },

  setMissingScopesSuite(state, missingScopesSuite) {
    state.missingScopesSuite = missingScopesSuite;
  },

  setNewUserSignModel(state, templateId) {
    state.company.new_users_signature = templateId;
  },

  SET_MEETING_VISIBILITY(state, status = false) {
    state.IsScheduleMeetingSnackbarVisible = status;
  },

  setActionScheduleDialog(state, config) {
    const { status = false, type = "help" } = config;

    state.actionsScheduleDialog = status;
    state.actionsScheduleType = type;
  },

  setFunctionalityClicked(state, functionality) {
    state.functionalityClicked = functionality;
  },

  setCompanySharedDrives(state, data) {
    state.companySharedDrives = data;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
