<template>
  <PageLoading />
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import PageLoading from "@/components/PageLoading";
export default {
  name: "Auth",
  components: {
    PageLoading,
  },

  async mounted() {
    const { token, tokenReseller } = this.$route.query;

    if (await this.authentication(token)) {
      this.syncUsersAsync();
      this.setTokenReseller(tokenReseller);
      localStorage.setItem("show_onboarding", this.showOnboardingUser);
      if (this.nextPage) {
        const nextPage = this.nextPage;
        localStorage.removeItem("nextPage");
        this.$router.push({ name: nextPage });
      } else {
        this.$router.push({ name: "Home" });
      }
    } else {
      this.logout();
    }
  },

  methods: {
    ...mapActions(["authentication", "syncUsersAsync", "logout"]),
    ...mapMutations(["setTokenReseller"]),
  },

  computed: {
    ...mapGetters(["showOnboardingUser", "nextPage"]),
  },
};
</script>

<style scoped>
.auth {
  background-color: #f5f5f5 !important;
}
</style>
