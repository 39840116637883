<template>
  <v-container
    :fluid="$vuetify.breakpoint.lgAndUp"
    class="fill-height d-flex flex-column"
    style="height: 90vh"
  >
    <v-row class="ma-0 pa-0 mb-6" align="center">
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="5"
        sm="12"
        class="d-flex flex-column align-center pt-0"
      >
        <v-img width="500" :src="successImage" contain />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8" xl="7" class="d-flex flex-column">
        <v-card-title
          class="text-h5 font-weight-bold break-word primary--text text--primary-4"
        >
          {{ title }}
        </v-card-title>

        <v-list>
          <v-list-item v-for="(item, index) in functionalityHints" :key="index">
            <v-list-item-icon>
              <v-icon
                v-text="item.icon ? item.icon : 'ph-fill ph-check-circle'"
                size="25"
                color="success"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="item.title" />
              <v-list-item-subtitle
                class="text-wrap"
                v-if="item.subtitle"
                v-html="item.subtitle"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <InlineFeatureRating
          v-if="
            renewFeedbackOnSecondTime(
              this.currentUser.feedbacks.apply_signature_in_batch
            ) && checkShowFeedback('apply_signature_in_batch')
          "
          class="mx-4 my-4"
          feature-name="Aplicar assinatura de e-mail"
          feature-slug="apply_signature_in_batch"
        />

        <v-card-actions
          :class="`mx-3 mt-6 ${
            $vuetify.breakpoint.smAndDown ? 'flex-column' : ''
          }`"
        >
          <v-btn
            v-if="primaryActionText"
            x-large
            :block="$vuetify.breakpoint.xs"
            color="primary"
            :class="`text-none text-body-1 font-weight-medium pr-8 pl-5  ${
              $vuetify.breakpoint.smAndDown ? 'mb-4' : 'mr-4'
            }`"
            @click="primaryAction"
          >
            <v-icon v-text="primaryActionIcon" left size="20" class="mr-4" />
            {{ primaryActionText }}
          </v-btn>

          <v-btn
            v-if="secondaryActionText"
            x-large
            :block="$vuetify.breakpoint.xs"
            outlined
            color="primary"
            :class="`text-none text-body-1 font-weight-medium px-6`"
            @click="secondaryAction"
          >
            {{ secondaryActionText }}

            <v-icon v-text="'ph-arrow-right'" right class="ml-4" size="20" />
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics";
import { redirectToTheCommercialManagerGoogleCalendar } from "@/helpers/services/utils";

import InlineFeatureRating from "@/components/general/InlineFeatureRating";

import {
  renewFeedbackOnSecondTime,
  checkShowFeedback,
  setNotShowFeedbackToday,
} from "@/helpers/services/utils";

export default {
  name: "SuccessfulAction",

  props: {
    functionality: { type: String, default: "applyNow" },
  },

  components: { InlineFeatureRating },

  data() {
    return {
      successImage: require("@/assets/images/sign_signature.png"),
    };
  },

  computed: {
    ...mapGetters([
      "hasTrialPlans",
      "mainDomain",
      "currentUser",
      "isCompanyMicrosoft",
    ]),

    primaryActionText() {
      if (this.hasTrialPlans) {
        return this.$t("action.talkToTeam");
      } else if (this.functionality == "schedule") {
        if (this.isCompanyMicrosoft) return "";
        return this.$t("applySignature.viewAppointments");
      }

      return this.$t("applySignature.goToUsers");
    },

    secondaryActionText() {
      if (this.hasTrialPlans) {
        return this.$t("action.knowPlans");
      } else if (this.functionality == "schedule") {
        return this.$t("applySignature.goToUsers");
      }

      return this.$t("applySignature.viewEmailSignatures");
    },

    primaryActionIcon() {
      if (this.hasTrialPlans) {
        return "ph-calendar";
      } else if (this.functionality === "schedule") {
        return "ph-list-bullets";
      }

      return "ph-user-list";
    },

    title() {
      const titles = {
        userWillApply: this.$t("applySignature.signatureChangeRequestSent"),
        sendEmail: this.$t("applySignature.signatureSentViaEmailTitle"),
        schedule: this.$t("applySignature.signatureChangeScheduledSuccess"),
        applyNow: this.$t("applySignature.signatureAppliedSuccess"),
      };

      return titles[this.functionality];
    },

    functionalityHints() {
      const hints = {
        userWillApply: [
          {
            title: this.$t("applySignature.exclusiveLinkSignatureChange"),
            subtitle: this.$t("applySignature.exclusiveLinkEmailDescription"),
            show: true,
          },
          {
            title: this.$t("applySignature.fillOutInformation"),
            subtitle: this.$t(
              "applySignature.exclusiveLinkInformationDescription"
            ),
            show: true,
          },
        ],
        sendEmail: [
          {
            title: this.$t("applySignature.signatureSentViaEmail"),
            subtitle: this.$t(
              "applySignature.signatureSentViaEmailDescription"
            ),
            show: true,
          },
        ],
        schedule: [
          {
            title: this.$t("applySignature.signatureChangeScheduled"),
            subtitle: this.$t("applySignature.signatureScheduledDescription"),
            show: true,
          },
        ],
        applyNow: [
          {
            title: this.$t("applySignature.signatureAppliedGmail"),
            subtitle: this.$t("applySignature.signatureDelayDescription"),
            show: true,
          },
          {
            title: this.$t("applySignature.restrictedUserAcceptance"),
            subtitle: this.$t("applySignature.learnMoreRestrictedUsers"),
            show: !this.isCompanyMicrosoft,
          },
        ],
      };

      return hints[this.functionality].filter((item) => item.show);
    },
  },

  methods: {
    ...mapMutations(["setSidebarMini", "setActionFeedBackMode"]),
    renewFeedbackOnSecondTime,
    checkShowFeedback,

    primaryAction() {
      if (this.hasTrialPlans) {
        this.scheduleMeeting();
      } else if (this.functionality == "schedule") {
        this.$router.push({ name: "SignAutomation" });
        this.$emit("close");
      } else {
        this.$emit("close");
      }
    },

    secondaryAction() {
      if (this.hasTrialPlans) {
        setFirebaseEvent(
          this.mainDomain,
          "go_to_self_checkout",
          "after_batch_signature_apply"
        );

        this.$router.push({ name: "BillingUpgradePlan" });
      } else if (this.functionality != "schedule") {
        this.$router.push({ name: "SignTemplates" });
      }

      this.$emit("close");
    },

    scheduleMeeting() {
      setFirebaseEvent(
        this.mainDomain,
        "schedule_meeting",
        "after_batch_signature_apply"
      );
      const clicked_from = "Após aplicar assinatura em massa";

      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },
  },

  mounted() {
    this.setActionFeedBackMode(true);
    this.setSidebarMini(true);
  },

  beforeDestroy() {
    this.setActionFeedBackMode(false);
    setNotShowFeedbackToday("apply_signature_in_batch");
  },
};
</script>
