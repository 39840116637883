<template>
  <v-app-bar
    color="white"
    elevation="0"
    app
    :flat="$vuetify.theme.dark || isTemplateEditor"
    class="px-0"
    clipped-right
    :extended="isTemplateEditor"
    min-height="70"
  >
    <v-app-bar-nav-icon
      v-if="isTemplateEditor || isUpgradePlanPage"
      class="ml-0 pl-0"
    >
      <v-btn icon @click="redirectToHome" :ripple="false">
        <v-icon v-text="'ph-house'" />
      </v-btn>
    </v-app-bar-nav-icon>

    <!-- Search input -->
    <v-autocomplete
      v-if="!isMobile"
      v-model="selectUser"
      class="ml-1 search-users"
      :loading="showLoading || loading"
      :disabled="showLoading || loading"
      :items="listUsersByEmail"
      item-text="email"
      label="Pesquisar usuário"
      color="primary"
      item-color="grey darken-1"
      flat
      hide-no-data
      hide-details
      solo
      prepend-inner-icon="ph-magnifying-glass"
      background-color="#f1f4f8"
    >
      <template v-slot:item="{ on, item }">
        <v-list-item v-on="on" @click="goUserPage(item)">
          <v-list-item-content>
            <v-list-item-title>{{ item.email }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-spacer />

    <v-chip
      large
      outlined
      v-if="hasTrialPlans"
      :class="`${isMobile ? 'mr-2' : 'mr-10'} mt-1`"
    >
      <v-icon class="pa-3 background-shadow" color="#5B36C3" left>
        mdi-calendar-blank-outline</v-icon
      >

      <div class="d-flex justify-center flex-column ml-1">
        <span class="purple-text trial">Teste Grátis</span>
        <span class="caption purple-text-soft">{{ endTrial }}</span>
      </div>
    </v-chip>

    <Missions v-if="!isCellphone" custom-class="mr-5" />

    <!-- BOTÃO DE PESQUISA MOBILE -->
    <v-menu
      v-if="isMobile"
      offset-y
      left
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-btn icon class="mr-2" v-bind="attrs" v-on="{ ...menu }">
          <v-icon color="primary" v-text="'ph-magnifying-glass'" />
        </v-btn>
      </template>
      <v-card class="px-6 pt-4 pb-0">
        <div class="mb-2 font-weight-bold">Pesquisar usuário</div>
        <v-autocomplete
          class="hidden-sm-and-down"
          :loading="showLoading || loading"
          :disabled="showLoading || loading"
          :items="listUsersByEmail"
          item-text="email"
          :filter="customFilter"
          label="Pesquisar usuário"
          color="primary"
          item-color="grey darken-1"
          flat
          hide-no-data
          hide-details
          solo
          outlined
          prepend-inner-icon="ph-magnifying-glass"
          v-model="selectUser"
        >
          <template v-slot:item="{ on, item }">
            <v-list-item v-on="on" @click="goUserPage(item)">
              <v-list-item-content>
                <v-list-item-title>{{ item.email }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template></v-autocomplete
        >

        <v-autocomplete
          class="d-inline d-xs-flex d-md-none mr-8"
          size="700"
          :disabled="showLoading || loading"
          :loading="showLoading || loading"
          :items="users"
          item-text="email"
          :filter="customFilter"
          label="Usuário"
          color="primary"
          item-color="grey darken-1"
          flat
          hide-no-data
          hide-details
          solo
          outlined
          prepend-inner-icon="ph-magnifying-glass"
          v-model="selectUser"
        >
          <template v-slot:item="{ on, item }">
            <v-list-item v-on="on" @click="goUserPage(item)">
              <v-list-item-content>
                <v-list-item-title>{{ item.email }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-card>
    </v-menu>

    <!-- TAREFAS -->
    <v-menu
      offset-y
      left
      :close-on-content-click="false"
      v-model="handleTasksProgress"
      max-height="400"
    >
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon class="mx-0" v-on="{ ...tooltip, ...menu }">
              <v-badge
                :content="1"
                :value="runningDriveProcessPath"
                overlap
                color="red"
              >
                <v-icon color="primary">ph-hourglass</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>{{ $t("common.tasks") }}</span>
        </v-tooltip>
      </template>
      <Tasks v-if="handleTasksProgress" />
    </v-menu>

    <!-- BOTAO DE NOTIFICACOES -->
    <v-menu bottom offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              class="mx-1"
              v-on="{ ...tooltip, ...menu }"
              @click="showCloseNotificationsSidebar()"
            >
              <v-badge
                :content="totalNotificationsUnread"
                :value="totalNotificationsUnread"
                overlap
                color="red"
              >
                <v-icon color="primary" v-text="'ph-bell'" />
              </v-badge>
            </v-btn>
          </template>
          <span>Notificações</span>
        </v-tooltip>
      </template>
    </v-menu>

    <!-- BOTAO DE USUARIOS ONLINE -->
    <v-menu
      bottom
      offset-y
      :close-on-content-click="false"
      v-if="isConecta && !isCellphone"
    >
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              class="pr-1 mx-1"
              v-on="{ ...tooltip, ...menu }"
              @click="toggleOnlineUsersSidebar()"
            >
              <v-badge
                :content="totalOnlineUsers"
                :value="totalOnlineUsers"
                overlap
                color="green"
              >
                <v-icon color="primary">ph-users-three</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Usuários Online</span>
        </v-tooltip>
      </template>
    </v-menu>

    <!-- FOTO E MENU -->
    <v-menu offset-y left :close-on-content-click="false" v-model="userCard">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          :size="showGuideTip ? '45' : '30'"
          class="mx-2"
        >
          <img
            v-if="currentUser?.photo"
            alt="Avatar"
            contain
            :src="currentUser?.photo"
            :class="showGuideTip ? 'green-border' : ''"
          />
          <v-icon
            v-else
            v-text="'ph-user-circle'"
            size="30"
            :alt="currentUser?.username"
            :class="showGuideTip ? 'green-border' : ''"
          />
        </v-avatar>
      </template>

      <v-card width="350" class="py-4">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar size="80">
            <img
              v-if="currentUser?.photo"
              alt="Avatar"
              :src="currentUser?.photo"
            />
            <v-icon
              v-else
              v-text="'ph-user-circle'"
              size="80"
              :alt="currentUser?.name"
            />
          </v-avatar>

          <v-card-title>{{ currentUser?.name }}</v-card-title>
          <v-card-subtitle>{{ currentUser?.email }}</v-card-subtitle>
        </v-card-text>

        <UpgradeButton @closeUserMenu="userCard = false" />

        <CurrentPlanBanner />

        <v-divider v-if="!currentPlan" />

        <v-list-item
          class="mt-2"
          @click="goToCompanyProfile"
          :disabled="disableNavigation"
        >
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon class="mr-2" v-text="'ph-gear'" />
              {{ $t("permissions.company_profile") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          class="feedback-button"
          link
          @click="feedbackButtonClick()"
        >
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon class="mr-2" v-text="'ph-chat-circle-dots'" />
              Enviar sugestão
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="hasConectaSupport" @click="initSupportChat">
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon class="mr-2" v-text="'mdi-face-agent'" /> Suporte via chat
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          class="mb-2"
          @click="openGuide"
          :disabled="disableNavigation"
        >
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon
                class="mr-2"
                v-text="'ph-megaphone-simple'"
                style="transform: scaleX(-1)"
              />
              {{ $t("guide.updates") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item class="mb-2" @click.stop="dialogLogout = true">
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              <v-icon class="mr-2" v-text="'ph-sign-out'" />
              {{ $t("common.exit") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon v-text="'ph-caret-right'" />
          </v-list-item-action>
        </v-list-item>

        <v-divider />

        <v-card-actions class="d-flex align-center justify-center pt-4">
          <v-btn
            x-small
            color="primary"
            class="caption text-none font-weight-medium"
            text
            href="https://conectasuite.com/politica-de-privacidade"
            target="_blank"
          >
            Políticas de privacidade
          </v-btn>
          <v-icon v-text="'mdi-circle-small'" small />
          <v-btn
            x-small
            color="primary"
            class="caption text-none font-weight-medium"
            text
            href="https://conectasuite.com/termos-de-uso"
            target="_blank"
          >
            Termos de uso
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <LogoutDialog :show="dialogLogout" @cancelLogout="cancelLogout" />

    <v-progress-linear
      v-if="loading"
      indeterminate
      absolute
      bottom
      color="white"
    ></v-progress-linear>

    <template
      v-if="isTemplateEditor"
      v-slot:extension
      style="border-top: 2px solid #fff"
    >
      <v-app-bar
        absolute
        dense
        class="mx-0"
        height="40"
        flat
        tile
        elevation="0"
      >
        <v-layout
          class="primary--text text-subtitle-2 text-none align-center justify-end mr-5"
        >
          <span v-if="$vuetify.breakpoint.smAndUp">
            {{ editorAppBarTitle }}
          </span>
          <v-icon
            v-if="$vuetify.breakpoint.lgAndUp"
            v-text="'ph-caret-right'"
            class="mx-3"
            size="20"
          />
          <v-menu v-if="$vuetify.breakpoint.lgAndUp" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="transparent"
                dark
                elevation="0"
                small
                class="primary--text text-none text-subtitle-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-text="'mdi-eye'" left />
                {{ editorOptionsText }}
                <v-icon v-text="'mdi-menu-down'" right />
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="item in Object.values(signEditorLayout)"
                :key="item"
                class="text-subtitle-2"
                link
                @click="handleEditorLayout(item)"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>
        <v-btn
          :to="routesToReturn[$route.name]"
          outlined
          dark
          class="primary--text text-none"
          small
        >
          <v-icon v-text="'mdi-arrow-left'" left /> Voltar para modelos
        </v-btn>
      </v-app-bar>
    </template>
    <div v-if="showGuideTip" class="tip-card">
      <p v-html="$t('guide.tip')"></p>
    </div>
  </v-app-bar>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import LogoutDialog from "@/components/general/LogoutDialog";
import Missions from "@/components/main/dashboard/Missions";
import CurrentPlanBanner from "@/components/billing/CurrentPlanBanner";
import UpgradeButton from "@/components/billing/upgrade/UpgradeButton";
import { links } from "@/helpers/variables/links";
import { initFreshChatGlobal } from "@/helpers/services/utils";

import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
import Tasks from "@/components/main/tasks/Tasks.vue";

export default {
  name: "Navbar",
  components: {
    LogoutDialog,
    CurrentPlanBanner,
    UpgradeButton,
    Missions,
    Tasks,
  },
  data() {
    return {
      userCard: false,
      indexPath: "/",
      product: "Conecta Suite",
      dialogLogout: false,
      showLoading: false,
      selectUser: null,

      signEditorLayout: {
        editor_only: "Somente editor",
        normal: "Editor e visualizador empilhados",
        side_to_side: "Editor e visualizador lado a lado",
      },
      keySignTemplate: {
        "Somente editor": "editor_only",
        "Editor e visualizador empilhados": "normal",
        "Editor e visualizador lado a lado": "side_to_side",
      },
      routesToReturn: {
        VacationTemplateEdit: "/sign/templates/vacation-messages",
        TemplateEdit: "/sign/template",
      },
      links,
    };
  },
  computed: {
    ...mapGetters([
      "isUpgradePlanPage",
      "editorLayout",
      "vacationEditorLayout",
      "totalNotificationsUnread",
      "hasTrialPlans",
      "productName",
      "isSignPage",
      "isTrackPage",
      "isConecta",
      "currentUser",
      "loading",
      "users",
      "company",
      "currentPlan",
      "totalOnlineUsers",
      "mainDomain",
      "hasConectaSupport",
      "showTasksProgress",
      "runningDriveProcessPath",
      "companyFreshChatConfig",
      "conectaNuvemResellerToken",
      "showGuideTip",
    ]),

    endTrial() {
      const { current_plan } = this.company;
      if (!current_plan.end_trial) return 0;

      const dateEndTrial = moment(current_plan.end_trial);
      const diffDate = dateEndTrial.diff(moment().startOf("day"), "days");

      if (dateEndTrial.isAfter(moment(), "day")) {
        return diffDate === 1
          ? `Resta ${diffDate} dia`
          : `Restam ${diffDate} dias`;
      } else if (dateEndTrial.isSame(moment(), "day")) {
        return "Último dia de trial!";
      } else {
        return "Expirado";
      }
    },

    isTemplateEditor() {
      if (!this.$route.name) return;
      return this.$route.name.includes("TemplateEdit");
    },

    editorOptionsText() {
      if (this.isSignatureTemplateEditor) {
        return this.signEditorLayout[this.editorLayout];
      }

      return this.signEditorLayout[this.vacationEditorLayout];
    },

    isSignatureTemplateEditor() {
      if (!this.$route.name) return;
      return this.$route.name === "TemplateEdit";
    },

    editorAppBarTitle() {
      return this.isSignatureTemplateEditor
        ? "Modo edição de assinaturas de e-mail"
        : "Modo edição de modelo de férias";
    },

    listUsersByEmail() {
      let users = new Array();
      this.users.map((item) => users.push(item.email));
      return this.users;
    },

    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },

    isCellphone() {
      return this.$vuetify.breakpoint.xs;
    },

    handleTasksProgress: {
      get() {
        return this.showTasksProgress;
      },
      set(value) {
        this.setShowTasksProgress(value);
      },
    },

    disableNavigation() {
      return !this.company && this.$route.name == "Install";
    },
  },
  watch: {
    "$route.path": function () {
      if (this.isTrackPage) {
        this.page = "track";
        this.product = "Conecta Track";
        this.indexPath = "/track/users";
      } else if (this.isSignPage) {
        this.page = "sign";
        this.product = "Conecta Sign";
        this.indexPath = "/sign";
      } else {
        this.page = "suite";
        this.product = "Conecta Suite";
        this.indexPath = "/";
      }
    },
  },

  methods: {
    ...mapMutations([
      "showCloseNotificationsSidebar",
      "showCloseOnlineUsersSidebar",
      "setEditorLayout",
      "setVacationEditorLayout",
      "feedbackButtonClick",
      "setShowTasksProgress",
      "setFloatingGuide",
      "setOpenedChat",
      "setFloatingGuide",
    ]),

    ...mapActions(["verifyHubspotToken"]),

    openGuide() {
      this.setFloatingGuide(true);
    },

    async initSupportChat() {
      if (this.conectaNuvemResellerToken === this.company.reseller_token) {
        initFreshChatGlobal(
          this.currentUser,
          this.companyFreshChatConfig,
          this.hasConectaSupport
        );
        return;
      }
      await this.verifyHubspotToken();
      this.setOpenedChat();
      this.setFloatingGuide(false);
    },

    redirectToHome() {
      if (this.isUpgradePlanPage) {
        const analytics = getAnalytics();

        logEvent(analytics, "self-checkout", {
          click: `Botão de ir para página inicial`,
          main_domain: this.mainDomain,
          email: this.currentUser?.email,
          description:
            "Clicou no botão para sair do self-checkout e ir para a página inicial",
        });
      }

      this.$router.push({ name: "Home" });
    },

    goToCompanyProfile() {
      if (this.$route.path !== "/settings/profile") {
        this.$router.push({ path: "/settings/profile" });
      }
    },

    handleEditorLayout(item) {
      if (this.isSignatureTemplateEditor) {
        this.setEditorLayout(this.keySignTemplate[item]);
      } else {
        this.setVacationEditorLayout(this.keySignTemplate[item]);
      }
    },

    toggleOnlineUsersSidebar() {
      this.showCloseOnlineUsersSidebar();
    },

    goUserPage(item) {
      if (this.productName == "conecta_track") {
        this.$router.push({
          path: "/track/user",
          query: { id: item.id_google },
        });
      } else if (this.productName == "conecta_sign") {
        this.$router.push({
          path: "/sign/users#",
          query: { email: item.email },
        });
      } else if (this.$route.query.id !== item.id_google) {
        this.$router.push({ path: "/user", query: { id: item.id_google } });
      }
    },

    cancelLogout() {
      this.dialogLogout = false;
    },

    customFilter(item, queryText) {
      const textOne = item.email.toLowerCase().trim();
      const textTwo = item.name.toLowerCase().trim();
      const searchText = queryText.toLowerCase().trim();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    snackbarPurchaseInterest(info) {
      this.$emit("snackbarPurchaseInterest", info);
    },
  },
};
</script>

<style scoped>
.link:hover {
  cursor: pointer;
}

.background-shadow {
  background-color: #f6f3ff;
  border-radius: 100%;
}

.purple-text {
  color: #5b36c3;
}

.purple-text-soft {
  color: #886fce;
}

.trial {
  font-weight: bold;
  font-size: 12px;
}

.search-users {
  border-radius: 16px;
  max-width: 354px;
  border: 1px solid #dbdbdb;
}

.green-border {
  border: 2px solid #12d483;
  padding: 6px;
  border-radius: 100%;
}

.tip-card {
  position: fixed;
  z-index: 9999;
  top: 60px;
  right: 20px;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  background-color: #8165d1;
  width: 270px;
  padding: 20px 14px;
}

.tip-card p {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: white;
  line-height: 22px;
  margin: 0;
}
</style>
