<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <v-card
      tile
      flat
      class="elevation-0 overflow-y-auto ma-auto"
      width="90vw"
      height="calc(90vh - 90px)"
    >
      <v-row class="ma-0 pa-0">
        <v-col align="center" class="d-flex justify-center align-center">
          <v-radio-group
            v-model="selectUser"
            @change="saveDataOnLocalStorage"
            class="me-7"
            :disabled="loading"
          >
            <v-row class="ma-0 pa-0" align="baseline">
              <v-radio
                :label="$t('common.createUser')"
                :value="false"
                class="me-5"
              ></v-radio>
              <v-radio :label="$t('common.selectUser')" :value="true"></v-radio>
            </v-row>
          </v-radio-group>
          <v-autocomplete
            solo
            clearable
            open-on-clear
            v-model="user"
            @change="saveDataOnLocalStorage"
            :items="users"
            :label="$t('common.user')"
            class="mt-4"
            :color="primaryColor"
            dense
            item-text="email"
            item-value="id_google"
            return-object
            :disabled="loading || !selectUser"
            :style="`max-width: ${userInputLength * 11 || 300}px`"
          />
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" @submit.prevent="">
        <v-row class="ma-0 pa-0">
          <v-col align="center" cols="6">
            <v-text-field
              v-model="name"
              :label="`${$t('common.name')} *`"
              :rules="[rules.nameMinSize]"
              :disabled="loading || selectUser"
              @input="saveDataOnLocalStorage"
            />
          </v-col>
          <v-col align="center" cols="6">
            <v-text-field
              v-model="lastName"
              :label="`${$t('common.lastName')} *`"
              :rules="[rules.nameMinSize]"
              :disabled="loading || selectUser"
              @input="saveDataOnLocalStorage"
            />
          </v-col>

          <v-col align="center" cols="12">
            <v-row align="baseline" class="ma-0 pa-0">
              <v-col class="pa-0">
                <v-text-field
                  v-model="emailName"
                  :label="`${$t('common.primaryEmail')} *`"
                  :rules="[rules.required, rules.validEmail]"
                  :disabled="loading || selectUser"
                  @focus="autoCompleteUserName"
                  @input="saveDataOnLocalStorage"
                  @change="saveDataOnLocalStorage"
                />
              </v-col>
              <v-col class="pa-0">
                <v-select
                  v-model="domain"
                  :items="domains"
                  :label="$t('common.domain')"
                  :rules="[rules.required]"
                  :disabled="loading || selectUser"
                  prepend-icon="mdi-at"
                  @change="saveDataOnLocalStorage"
                />
              </v-col>
            </v-row>
          </v-col>
          <!-- IMAGE USER  -->
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <div class="d-flex flex-column justify-center">
                  <span :class="`pb-2 ${selectUser ? 'disabled' : ''}`">
                    {{ $t("onboardingNewUser.userProfilePhoto") }}
                  </span>
                  <FileUploader
                    v-if="!file && !selectUser"
                    @change="file = $event"
                    type="image"
                    :title="$t('onboardingNewUser.userProfilePhoto')"
                    :buttonText="$t('onboardingNewUser.uploadImage')"
                  />
                  <div v-else class="mt-4">
                    <v-file-input
                      v-model="file"
                      outlined
                      @update:error="logoError = $event"
                      accept="image/*"
                      prepend-icon=""
                      color="primary"
                      :label="$t('common.image')"
                      clear-icon="mdi-trash-can-outline"
                      clearable
                      :disabled="loading || selectUser"
                    >
                      <!-- <template #append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="showCropperDialog = true"
                            v-text="'mdi-crop'"
                            color="primary"
                          />
                        </template>
                        <span>Recortar imagem</span>
                      </v-tooltip>
                    </template> -->
                    </v-file-input>
                  </div>
                </div>
              </v-col>

              <!-- UOs  -->
              <v-col cols="6">
                <v-col class="pa-0">
                  <div class="d-flex flex-column justify-center">
                    <span
                      :class="`pb-2 lighten-4 ${selectUser ? 'disabled' : ''}`"
                    >
                      {{ $t("common.organizationUnit") }}
                    </span>
                    <UosPicker
                      returnPath
                      singleChoice
                      :selected="formData.uo"
                      @update="
                        uoSelected = $event;
                        saveDataOnLocalStorage();
                      "
                      :disabled="loading || selectUser"
                    />
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-7">
            <v-divider />
          </v-col>

          <!-- PASSWORD  -->
          <v-col cols="12">
            <span
              :class="`font-weight-bold pr-4 text-h6 ${
                selectUser ? 'disabled' : ''
              }`"
            >
              {{ $t("onboardingNewUser.loginAndPasswordManagement") }}
            </span>
            <TooltipIcon
              color="primary"
              :label="
                hasControl
                  ? $t(
                      'onboardingNewUser.loginAndPasswordManagementDescriptionWithControl'
                    )
                  : $t(
                      'onboardingNewUser.loginAndPasswordManagementDescription'
                    )
              "
              :disabled="loading || selectUser"
              icon="mdi-information"
            />
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="passwordOption"
              :disabled="loading || selectUser"
              @change="saveDataOnLocalStorage"
            >
              <v-row class="ma-0 pa-0" align="baseline">
                <v-col
                  class="ma-0 pa-0"
                  v-for="(option, i) in passwordOptions"
                  :key="i"
                >
                  <v-radio :label="option.label" :value="option.value">
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0 pr-5 pl-3">
            <v-text-field
              v-model="password"
              :label="`${$t('onboardingNewUser.password')} *`"
              :disabled="
                loading || passwordOption !== 'customPassword' || selectUser
              "
              :rules="passwordRules"
              :append-icon="hidePass ? 'mdi-eye-off' : 'mdi-eye'"
              :type="hidePass ? 'password' : 'text'"
              @click:append="hidePass = !hidePass"
              @change="saveDataOnLocalStorage"
            />
            <p class="text-subtitle-2 mt-2" style="width: 100%">
              {{ $t("onboardingNewUser.copyOnNextStep") }}
            </p>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0 pr-3">
            <v-text-field
              v-model="confirmPassword"
              :label="`${$t('onboardingNewUser.confirmPassword')} *`"
              :rules="[rules.equalPasswords]"
              :disabled="
                loading || passwordOption !== 'customPassword' || selectUser
              "
              :append-icon="hideConfirmPass ? 'mdi-eye-off' : 'mdi-eye'"
              :type="hideConfirmPass ? 'password' : 'text'"
              @click:append="hideConfirmPass = !hideConfirmPass"
            />
          </v-col>

          <!-- SWITCHES -->
          <v-col cols="12" class="mt-0 pt-0">
            <v-row>
              <v-col cols="6">
                <v-switch
                  inset
                  v-model="askChangePass"
                  :label="$t('onboardingNewUser.requestPasswordChange')"
                  :disabled="loading || selectUser"
                  @change="saveDataOnLocalStorage"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <!-- <CropImageDialog
        id="crop-image"
        :key="key"
        :image="cropImagePreview"
        :show="showCropperDialog"
        @close="showCropperDialog = false"
        @crop="setCroppedImage"
      /> -->
    </v-card>
    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        :disabled="loading"
        outlined
        @click="$emit('back')"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer />

      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="createUser()"
        :disabled="
          (loading || !checkRequiredFields || !valid) && !(selectUser && user)
        "
        :loading="loading"
      >
        {{ $t("common.advance") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>
  </v-container>
</template>
<script>
// import CropImageDialog from "@/components/general/CropImageDialog.vue";
import FileUploader from "@/components/general/FileUploader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TooltipIcon from "../base/TooltipIcon.vue";
import UosPicker from "../forms/field_pickers/UosPicker.vue";
import { convertImageIntoBase64 } from "@/helpers/services/images";
import { rules } from "@/helpers/services/utils";
import { DEFAULT_PASSWORD_STRENGTH } from "@/helpers/variables/backendConstants";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import {
  generateRandomPassword,
  setUserInFormData,
} from "@/helpers/services/utils";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";

export default {
  name: "CreateUser",
  components: {
    // CropImageDialog,
    FileUploader,
    TooltipIcon,
    UosPicker,
  },

  data() {
    return {
      valid: null,

      name: null,
      lastName: null,
      emailName: null,
      domain: null,
      askChangePass: false,
      useDefaultPass: false,
      passwordOption: "customPassword",

      password: null,
      confirmPassword: null,
      hidePass: true,
      hideConfirmPass: true,
      loading: false,
      file: null,
      logoError: false,
      // showCropperDialog: false,
      key: 0,
      active: [],
      uoSelected: "",
      rules: {
        ...rules,

        equalPasswords: (v) =>
          v === this.password || this.$t("textFieldRules.passwordsDoNotMatch"),

        min_len: (v) =>
          (!!v && v.length >= this.minSize) ||
          `${this.$t("textFieldRules.requiredField")}: ${this.minSize}`,

        nameMinSize: (v) =>
          (!!v && v.length >= 3) ||
          `${this.$t("textFieldRules.requiredField")}: 3`,

        imageRule: (v) =>
          !v || v.size < 2000000 || this.$t("textFieldRules.imageTooLarge"),

        validEmail: (v) =>
          /^[a-zA-Z0-9._%+-]+$/.test(v) ||
          this.$t("textFieldRules.invalidEmail"),
      },
      user: null,
      selectUser: false,
    };
  },

  computed: {
    ...mapGetters([
      "company",
      "domains",
      "organizationalUnits",
      "secondaryColor",
      "companyHasControl",
      "domainGroup",
      "users",
      "primaryColor",
    ]),
    cropImagePreview() {
      if (this.file) {
        const url = URL.createObjectURL(this.file);
        return url;
      }

      return "";
    },
    uos() {
      return [
        {
          name: "Principal",
          path: "/",
          children: this.organizationalUnits,
        },
      ];
    },
    formData() {
      return {
        name: this.name,
        lastName: this.lastName,
        emailName: this.emailName,
        domain: this.domain,
        askChangePass: this.askChangePass,
        useDefaultPass: this.useDefaultPass,
        passwordOption: this.passwordOption,
        uo: this.uoSelected,
        selectUser: this.selectUser,
        user: this.user,
      };
    },
    checkRequiredFields() {
      const password =
        this.password === this.confirmPassword && this.password !== null;

      return (
        this.name && this.lastName && this.emailName && this.domain && password
      );
    },
    passwordOptions() {
      return [
        {
          label: this.$t("onboardingNewUser.createPassword"),
          value: "customPassword",
        },
        {
          label: this.$t("onboardingNewUser.generateAStrongPassword"),
          value: "generatedPassword",
        },
      ];
    },
    hasControl() {
      return (
        ["PRODUCT_IN_TRIAL_PLAN", "PRODUCT_IN_ACTIVE_PLAN"].includes(
          this.companyHasControl
        ) && this.company.control_last_seen !== null
      );
    },
    passwordRules() {
      const passwordStrength =
        this.domainGroup?.password_strength || DEFAULT_PASSWORD_STRENGTH;

      return Object.entries(passwordStrength)
        .filter((entry) => entry[1])
        .map((entry) => this.rules[entry[0]])
        .concat([this.rules.required]);
    },
    minSize() {
      const minLen = this.domainGroup?.password_strength?.min_len || 8;
      return minLen < 8 ? 8 : minLen;
    },
    userInputLength() {
      return (this.users || []).reduce(
        (total, current) =>
          total > current.email.length ? total : current.email.length,
        0
      );
    },
  },
  watch: {
    passwordOption() {
      if (this.passwordOption === this.passwordOptions[1].value) {
        const passwordGenerated = this.generateRandomPassword(10);
        this.password = passwordGenerated;
        this.confirmPassword = passwordGenerated;
      }
    },
    hasControl: {
      async handler(hasControl) {
        if (!hasControl) return;
        await this.getControlCompany();
      },
      immediate: true,
    },
    user(user) {
      this.loadFromUser();
      this.setUserById(user);
    },
    selectUser(selectUser) {
      if (!selectUser) this.reset();
      else if (this.user) this.loadFromUser(this.user);
    },
  },
  methods: {
    ...mapMutations(["setSnackBar", "setUserById", "setUsers"]),
    ...mapActions([
      "createUserOnWorkspace",
      "getControlCompany",
      "getUserById",
    ]),
    convertImageIntoBase64,
    generateRandomPassword,
    setFirebaseEvent,
    saveAnalytics(log) {
      if (this.mainDomain) {
        this.setFirebaseEvent(this.mainDomain, log.event, log.click);
      }
    },

    setFormValuesByLocalStorage() {
      const savedData = localStorage.getItem("formData");
      if (savedData) {
        let formData = JSON.parse(savedData);
        if (formData.user) {
          this.setUsers([...this.users, formData.user]);
        }
        this.name = formData.name;
        this.lastName = formData.lastName;
        this.emailName = formData.emailName;
        this.domain = formData.domain;
        this.askChangePass = formData.askChangePass;
        this.useDefaultPass = formData.useDefaultPass;
        this.passwordOption = formData.passwordOption;
        this.uoSelected = formData.uo;
        this.selectUser = !!formData.user;
        this.user = formData.user;
        this.setUserById(formData.user);
      }
    },

    saveDataOnLocalStorage() {
      localStorage.setItem("formData", JSON.stringify(this.formData));
    },

    autoCompleteUserName() {
      if (!this.emailName && this.name) {
        this.emailName = this.removeAccents(this.name)
          .replace(/\s/gm, "")
          .toLowerCase();
      }
    },
    removeAccents(str = "") {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    // setCroppedImage(image) {
    //   this.image = image;
    //   this.showCropperDialog = false;
    // },
    // forceUpdate() {
    //   this.key += 1;
    // },
    reset() {
      this.$refs.form.reset();
      this.active = [];
      localStorage.removeItem("formData");
    },

    async createUser() {
      this.loading = true;
      if (this.selectUser) {
        const suite_user = this.user;
        this.setUserById(suite_user);
        localStorage.setItem("userId", JSON.stringify(suite_user.id_google));

        localStorage.setItem("userSelected", JSON.stringify(this.user));
        this.$emit("userSelected");

        this.loading = false;
        return;
      }
      let imageBase64 = "";
      let type = "";
      if (this.file) {
        imageBase64 = await this.convertImageIntoBase64(this.file);
        type = this.file.type;
      }
      let payload = {
        family_name: this.lastName,
        given_name: this.name,
        full_name: `${this.name} ${this.lastName}`,
        password: this.password,
        primary_email: this.emailName + "@" + this.domain,
        change_password_at_next_login: !!this.askChangePass,
        org_unit_path:
          this.uoSelected && this.uoSelected.length ? this.uoSelected : "/",
        photo: {
          photoData: imageBase64,
          mimeType: type,
        },
      };

      const log = {
        event: "create_user_onboarding",
        click: "Botão de criar usuário",
      };
      this.saveAnalytics(log);

      this.createUserOnWorkspace(payload)
        .then(async ({ data }) => {
          const { suite_user_created } = data;
          localStorage.setItem(
            "userId",
            JSON.stringify(suite_user_created.id_google)
          );
          await this.getUserById(suite_user_created.id_google);
          setUserInFormData(this.userById);

          this.setSnackBar({
            show: true,
            message: successMessages.user_created,
            color: "success",
          });
          let user = {
            email: payload.primary_email,
            password: this.password,
          };
          localStorage.setItem("userCreated", JSON.stringify(user));
          this.$emit("userCreated");
        })
        .catch((error) => {
          console.error("createUserOnWorkspace: ", error.response);
          const { response } = error;
          const {
            USER_DUPLICATED_ERROR,
            INVALID_DATA,
            INVALID_GOOGLE_ACCESS_TOKEN,
            USERS_NUMBER_LIMIT_EXCEEDED,
          } = errorMessages;
          const mappedErrors = {
            409: USER_DUPLICATED_ERROR,
            412: USERS_NUMBER_LIMIT_EXCEEDED,
            INVALID_GOOGLE_ACCESS_TOKEN,
          };
          const mappedError = mappedErrors[response.status];
          const errorMessage = mappedError
            ? mappedError
            : mappedErrors[response.data.code] || INVALID_DATA;

          this.setSnackBar({
            show: true,
            message: errorMessage,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadFromUser() {
      if (!user) return;

      const user = this.user;
      const emailSplit = user.email.split("@");
      this.name = user.first_name;
      this.lastName = user.last_name;
      this.emailName = emailSplit[0];
      this.domain = emailSplit[1];
      this.uoSelected = user.ou;
    },
  },
  beforeMount() {
    this.setFormValuesByLocalStorage();
    localStorage.removeItem("userGroups");
    localStorage.removeItem("userSelected");
  },
};
</script>
<style scoped>
.treeview-style {
  overflow-x: scroll;
  overflow-y: scroll;
}
.cursor {
  cursor: pointer !important;
}
.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
</style>
