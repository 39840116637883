import { today } from "@/helpers/services/utils";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { getDatabase, ref, onValue } from "firebase/database";
let allCalendars = localStorage?.getItem("allCalendars");

const state = () => ({
  users: [],
  currentUser: {},
  userById: {},
  axiosController: null,
  axiosCanceled: false,
  usersCustomTags: {},
  allCalendars: allCalendars ? JSON.parse(allCalendars) : [],
  calendarsLoading: false,
  userSendAs: {},
  userGroups: {},
  showTasksProgress: false,
  firstSyncUsersStatus: {},
  usersDelegates: [],
});

const getters = {
  currentUser: (state) => state.currentUser,
  usersDelegates: (state) => state.usersDelegates,
  currentUserPhoto: (state) => state.currentUser?.photo,
  userSignPhoto: (state) => state.currentUser?.sign_photo,
  hasAppliedSignature: (state) =>
    state.currentUser.missions.apply_email_signature?.completed_at || false,
  hasProfilePermission: (state, { isAdmin }) =>
    isAdmin || state.currentUser.permission.company_profile === "admin",
  hasBillingPermission: (state, { isAdmin }) =>
    isAdmin || state.currentUser.permission.billing === "admin",
  hasSignPermission: (state, { isAdmin }) =>
    isAdmin || state.currentUser.permission.conecta_sign === "admin",
  hasSuitePermission: (state, { isAdmin }) =>
    isAdmin || state.currentUser.permission.conecta_suite === "admin",
  hasTrackPermission: (state, { isAdmin }) =>
    isAdmin || state.currentUser.permission.conecta_track === "admin",
  hasControlPermission: (state, { isAdmin }) =>
    isAdmin || state.currentUser.permission.conecta_control === "admin",
  hasManageAdminsPermission: (state, { isAdmin }) =>
    isAdmin || state.currentUser.permission?.manage_admins === "admin",
  users: (state) => state.users,
  isAdmin: (state, getters) =>
    getters.isAuthenticated && state.currentUser.is_google_admin,
  showOnboardingUser: (state, getters) =>
    getters.isAuthenticated && state.currentUser.show_onboarding,
  hasUserByIdMainDomain: (state) => state.userById.domain || null,

  userById: (state) => state.userById,

  /* Verificar se a requisição GET foi cancelada */
  usersCanceledResquest: (state) => state.axiosCanceled,

  usersCustomTags: (state) => state.usersCustomTags,

  usersCustomTagKeys: (state) => Object.keys(state.usersCustomTags),

  allCalendars: (state) => state.allCalendars,
  calendarsLoading: (state) => state.calendarsLoading,

  userSendAs: (state) => (id_google) => state.userSendAs[id_google],
  userGroups: (state) => (id_google) => state.userGroups[id_google],
  showTasksProgress: (state) => state.showTasksProgress,
  firstSyncUsersStatus: (state) => state.firstSyncUsersStatus,
};

// actions
const actions = {
  updateVacation({ dispatch, getters }, payload) {
    const { settings, email } = payload;

    const user = getters.users.find((user) => user.email === email);

    const url =
      process.env.VUE_APP_API_BASE_URL + `/user/${user.id_google}/vacation`;
    const auth = { headers: { Authorization: this._vm.token } };

    return this._vm.$axios.patch(url, settings, auth).then(() => {
      dispatch("getUsers", { root: true });
    });
  },

  syncUsers() {
    const token = localStorage.getItem("token");

    let params = {
      jwt_token: token,
    };

    let auth = { headers: { Authorization: this._vm.token } };

    return this._vm.$axios.post(
      process.env.VUE_APP_API_BASE_URL + "/users/sync",
      params,
      auth
    );
  },

  getDelegates({ commit }) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/delegates`;

    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };
    this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        commit("setDelegates", data);
      })
      .catch((error) => {
        console.error("Vuex: getDelegates()", error);
      });
  },

  async getUserById(context, user_id) {
    let url = process.env.VUE_APP_API_BASE_URL + `/users/by_id`;
    const token = this._vm.token;

    await this._vm.$axios
      .get(url, {
        params: {
          id: user_id,
        },
        headers: {
          Authorization: token,
        },
      })
      .then(({ data }) => {
        context.commit("setUserById", data.user);
      })
      .catch((error) => {
        context.commit("setUserById", {});
        console.error("Vuex: getUsersById()", error);
      });
  },

  getUsersByTablePage({ state }, params) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/fetch`;

    if (state.axiosController) {
      state.axiosController.abort();
      state.axiosCanceled = true;
    }

    state.axiosController = new AbortController();

    let config = {
      headers: {
        Authorization: this._vm.token,
      },
      params: params,
      signal: state.axiosController.signal,
    };

    return this._vm.$axios.get(url, config).finally(() => {
      state.axiosController = null;
      state.axiosCanceled = false;
    });
  },

  getSendAsByUser({ commit }, user) {
    const url = process.env.VUE_APP_API_BASE_URL + `/user/sendas`;

    const id_google = user.id_google;
    const params = { id_user: id_google };

    return this._vm.$axios
      .get(url, {
        params: { params },
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        const userSendAs = data.sendAs.map((listItem) => {
          return {
            name: listItem.displayName,
            email: listItem.sendAsEmail,
            default: listItem.isDefault,
            mainUser: listItem.isPrimary,
            signature: listItem.signature,
          };
        });
        commit("setUserSendAs", { userSendAs, id_google });
        return userSendAs;
      })
      .catch((error) => {
        console.error("Vuex: getSendAsByUser()", error);
        const code = error.response?.data?.code;
        if (code === "INVALID_GOOGLE_ACCESS_TOKEN") {
          return code;
        }
        const userSendAs = [
          {
            name: user.name,
            email: user.email,
            mainUser: true,
            signature: user.applied_signature,
          },
        ];
        commit("setUserSendAs", { userSendAs, id_google });
        return userSendAs;
      });
  },

  createUserSendAs({ getters, commit }, data) {
    const url = process.env.VUE_APP_API_BASE_URL + "/user/sendas";
    const auth = { headers: { Authorization: this._vm.token } };
    const id_user = data.id_user;
    const payload = {
      email: data.email,
      name: data.name,
      id_user,
    };
    return this._vm.$axios
      .post(url, payload, auth)
      .then((response) => {
        const responseData = response.data;
        const userSendAs = (getters.userSendAs(id_user) || []).concat([
          {
            name: responseData.displayName,
            email: responseData.sendAsEmail,
            default: responseData.isDefault,
            mainUser: responseData.isPrimary,
            signature: responseData.signature,
          },
        ]);
        commit("setUserSendAs", { userSendAs, id_google: id_user });
        commit("setSnackBar", {
          show: true,
          message: successMessages.apply_24hours,
          color: "success",
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.error("Vuex: createUserSendAs()", error);
        const { code } = error.response.data;

        commit("setSnackBar", {
          show: true,
          message_key: code,
          is_key: true,
          color: "error",
        });
        return Promise.reject(error);
      });
  },

  completeMission({ state, commit }, missionCode) {
    const { currentUser } = state;

    let missions = currentUser.missions;

    const missionToUpdate = missions[missionCode];

    missionToUpdate.completed_at = today;

    missions[missionCode] = missionToUpdate;

    const userUpdated = { ...currentUser, missions };

    commit("setCurrentUser", userUpdated);
  },

  setSignatureToUsers(
    _,
    payload = {
      emails: [],
      template_key: "",
      send_as: false,
    }
  ) {
    let url = `${process.env.VUE_APP_API_BASE_URL}${
      payload.send_as
        ? "/users/sendas/batch:async"
        : "/conecta-sign/signature/batch:async"
    }`;
    let headers = {
      headers: {
        Authorization: this._vm.token,
      },
    };
    if (payload["send_as"]) {
      delete payload["send_as"];
      return this._vm.$axios.post(url, payload, headers);
    } else {
      return this._vm.$axios.put(url, payload, headers);
    }
  },

  sendUserUpdateAndApplyRequest(
    { commit },
    data = {
      payload: { emails: [], template_key: "" },
      messages: { success: "", error: "" },
    }
  ) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/send-update-and-apply`;
    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };
    return this._vm.$axios
      .post(url, data.payload, auth)
      .then(() => {
        commit("setSnackBar", {
          message: data.messages.success,
          show: true,
        });
      })
      .catch((error) => {
        commit("setSnackBar", {
          message: data.messages.error,
          show: true,
          color: "error",
        });
        console.error("sendUserUpdateAndApplyRequest: ", error);
      });
  },

  sendSignatureModelViaEmail(
    { commit },
    data = {
      payload: { emails: [], template_key: "" },
      messages: { success: "", error: "" },
    }
  ) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/send-signature-email`;
    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };
    return this._vm.$axios
      .post(url, data.payload, auth)
      .then(() => {
        commit("setSnackBar", {
          message: data.messages.success,
          show: true,
        });
      })
      .catch((error) => {
        commit("setSnackBar", {
          message: data.messages.error,
          show: true,
          color: "error",
        });
        console.error("sendSignatureModelViaEmail: ", error);
      });
  },

  async getUsers({ commit }) {
    let url = process.env.VUE_APP_API_BASE_URL + "/users";
    await this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setUsers", data);
      })
      .catch((error) => {
        console.error("Vuex: getUsers()", error);
      });
  },

  async getCurrentUser({ commit }) {
    const url = process.env.VUE_APP_API_BASE_URL + "/current-user";
    await this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setCurrentUser", data);
      })
      .catch((err) => {
        commit("setCurrentUser", null);
        console.error("Vuex: getCurrentUser()", err);
      });
  },

  async getUsersMe({ commit }) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/me`;
    await this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        commit("setCurrentUser", data);
      })
      .catch((err) => {
        commit("setCurrentUser", null);
        console.error("Vuex: getUsersMe()", err);
      });
  },

  async getUserHubSpotToken() {
    const url = process.env.VUE_APP_API_BASE_URL + "/users/user/hubspot-token";
    let token = "";
    await this._vm.$axios
      .get(url, {
        headers: {
          Authorization: this._vm.token,
        },
      })
      .then(({ data }) => {
        token = data;
      })
      .catch((err) => {
        console.error("Vuex: getUserHubSpotToken()", err);
      });
    return token;
  },

  async syncUsersAsync(_, payload = {}) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/sync-users-task`;
    const auth = {
      headers: { Authorization: this._vm.token },
    };

    await this._vm.$axios.post(url, payload, auth).catch((err) => {
      console.error("Vuex: syncUsersAsync()", err);
    });
  },

  checkUserPermission({ commit }) {
    commit("setLoading", true, { root: true });
    return this._vm.$axios
      .get(process.env.VUE_APP_API_BASE_URL + "/admin-users/permission", {
        headers: { Authorization: this._vm.token },
      })
      .finally(() => commit("setLoading", false, { root: true }));
  },
  checkHasUserPermission({ dispatch }) {
    return dispatch("checkHasPagePermissions", "manage_admins");
  },

  checkHasUsersListPermission({ dispatch }) {
    return dispatch("checkHasPagePermissions", "users");
  },

  checkBillingPermission() {
    return this._vm.$axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/billing/permission`,
      {
        headers: { Authorization: this._vm.token },
      }
    );
  },
  checkHasBillingPermission({ dispatch }) {
    return dispatch("checkHasPagePermissions", "billing");
  },

  async checkReportsPermission() {
    return this._vm.$axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/reports/permission`,
      {
        headers: { Authorization: this._vm.token },
      }
    );
  },
  checkHasReportsPermission({ dispatch }) {
    return dispatch("checkHasPagePermissions", "reports");
  },

  async createUser({ commit }) {
    let auth = { headers: { Authorization: this._vm.token } };
    try {
      const { data } = await this._vm.$axios.put(
        process.env.VUE_APP_API_BASE_URL + "/users/external-access",
        {},
        auth
      );
      commit("setCurrentUser", data);
    } catch (err) {
      commit("setCurrentUser", null);
      console.error("Vuex: createUser()", err);
    }
  },

  async migrateEmails({ commit }, payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/migrate-emails:async`;
    const auth = { headers: { Authorization: this._vm.token } };
    let responseInfo = undefined;

    await this._vm.$axios
      .post(url, payload, auth)
      .then((response) => {
        responseInfo = response;
        const { data } = response;

        commit("setSnackBar", {
          show: true,
          message:
            data.code == "MIGRATION_IN_QUEUE"
              ? successMessages.migration_in_queue
              : successMessages.email_migration_started,
          color: "success",
        });

        if (data.code != "MIGRATION_IN_QUEUE") {
          commit("setShowTasksProgress", true);
        }
      })
      .catch(({ response }) => {
        responseInfo = response;

        console.error(response);
        commit("setSnackBar", {
          show: true,
          message:
            errorMessages[response.data.code] ||
            errorMessages.MIGRATE_EMAIL_ERROR,
          color: "warning",
        });
      });

    return responseInfo;
  },

  async getUserCalendars(_, key) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/user/${key}/calendars`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.get(url, auth);
  },

  async removeCalendarFromUser(_, payload) {
    const { key, data } = payload;
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/user/${key}/calendars`;
    const headers = { Authorization: this._vm.token };
    return await this._vm.$axios.delete(url, { headers, data });
  },

  getCalendars({ commit, getters }) {
    if (getters.isCompanyMicrosoft) return;

    commit("setCalendarsLoading", true);
    return this._vm.$axios
      .post(
        process.env.VUE_APP_API_BASE_URL + "/users/get-calendars",
        {},
        {
          headers: {
            Authorization: this._vm.token,
          },
        }
      )
      .then(({ data }) => {
        commit("setCalendars", data);
      })
      .catch((error) => {
        console.error("Vuex: getCalendars(): ", error);
      })
      .finally(() => {
        commit("setCalendarsLoading", false);
      });
  },

  async insertCalendars({ commit }, { users, calendars }) {
    commit("setCalendarsLoading", true);
    return this._vm.$axios
      .post(
        process.env.VUE_APP_API_BASE_URL + "/users/insert-calendars",
        { users, calendars },
        {
          headers: {
            Authorization: this._vm.token,
          },
        }
      )
      .then(() => {
        commit(
          "setSnackBar",
          {
            message: successMessages.insert_users_calendars,
            show: true,
          },
          { root: true }
        );
      })
      .catch(({ response: { data } }) => {
        console.error("Vuex: insertCalendars(): ", data);
        commit(
          "setSnackBar",
          {
            message: errorMessages[data.code] || errorMessages.unknown,
            color: "error",
            show: true,
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setCalendarsLoading", false);
      });
  },
  async getUserApplications(_, key) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/${key}/applications`;
    const headers = { Authorization: this._vm.token };
    return await this._vm.$axios.get(url, { headers });
  },
  async syncUserApplications(_, key) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/${key}/sync-applications`;
    const headers = { Authorization: this._vm.token };
    return await this._vm.$axios.post(url, null, { headers });
  },
  async createUserOnWorkspace(_, payload) {
    const url = `${process.env.VUE_APP_SYNC_USERS_SERVICE_API}/workspace/create-user`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.post(url, payload, auth);
  },
  async updateUserSignatureInfo(_, payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/user/update`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.post(url, payload, auth);
  },

  async addSendAs({ commit }, payload) {
    commit("setLoading", true);

    let url = process.env.VUE_APP_API_BASE_URL + "/user/sendas";

    const auth = { headers: { Authorization: this._vm.token } };

    await this._vm.$axios
      .post(url, payload, auth)
      .then(() => {
        commit("setSnackBar", {
          show: true,
          message: successMessages.apply_24hours,
          color: "success",
        });
      })
      .catch((err) => {
        console.error("Vuex: addSendAs()", err);
        const { code } = err.response.data;

        commit("setSnackBar", {
          show: true,
          message_key: code,
          is_key: true,
          color: "error",
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getUserAliasesByUserId(_, user) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/${user.key}/aliases`;
    const auth = { headers: { Authorization: this._vm.token } };

    return this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        const aliases = data.aliases;
        if (aliases)
          return aliases.map((item) => {
            return {
              id: item.id,
              alias: item.alias,
            };
          });
        return [];
      })
      .catch((error) => {
        console.error("Vuex: getUserAliasesByUserId()", error);
        const code = error.response?.data?.code;
        return code;
      });
  },
  getGroupsByUser({ commit }, user) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/${user.key}/groups`;
    const auth = { headers: { Authorization: this._vm.token } };
    const id_google = user.id_google;

    return this._vm.$axios
      .get(url, auth)
      .then((response) => {
        commit("setUserGroups", {
          userGroups: response.data,
          id_google,
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.error("Vuex: getGroupsByUser()", error);
        return Promise.reject(error);
      });
  },
  async insertUserOnGroup(_, payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/insert-on-group`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.post(url, payload, auth);
  },
  async removeUserFromGroup(_, data) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/remove-from-group`;
    const headers = { Authorization: this._vm.token };
    return await this._vm.$axios.delete(url, {
      headers,
      data,
    });
  },
  async sendIntructionsEmail(_, payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/send-instructions:async`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.post(url, payload, auth);
  },
  async getUserSharedDrives(_, key) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/${key}/shared-drives`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.get(url, auth);
  },
  async insertUserOnSharedDrives(_, { key, payload }) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/${key}/shared-drives`;
    const auth = { headers: { Authorization: this._vm.token } };
    return await this._vm.$axios.post(url, payload, auth);
  },

  getSyncUsersStatus({ getters, commit }, company = null) {
    if (!company && !getters.company) return;

    const key = company ? company.key : getters.company.key;

    const db = getDatabase();
    const processPath = `sync_users/${key}`;
    const reference = ref(db, processPath);
    try {
      onValue(reference, (snapshot) => {
        if (snapshot.exists()) {
          commit("setFirstSyncUsersStatus", snapshot.val());
        }
      });
    } catch (err) {
      console.error(err);
    }
  },
};

// mutations
const mutations = {
  setDelegates(state, delegates = []) {
    state.usersDelegates = delegates;
  },

  setCurrentUser(state, user) {
    state.currentUser = user;
    state.usersCustomTags = JSON.parse(JSON.stringify(user.custom_tags));
  },
  setUsers(state, users_data) {
    try {
      localStorage.setItem("users", JSON.stringify(users_data));
    } catch (error) {
      console.error("LocalStorage: setUsers(): ", error);
    }

    if (users_data.length) {
      const firstUser = users_data[0];
      state.usersCustomTags = JSON.parse(JSON.stringify(firstUser.custom_tags));
    }

    state.users = users_data;
  },
  setUserById(state, user) {
    state.userById = user;
  },
  setUsersCustomTags(state, customTags) {
    state.usersCustomTags = JSON.parse(JSON.stringify(customTags));
  },
  setCalendars(state, response) {
    state.allCalendars = Object.values(response)
      .map((item) => item.calendar)
      .sort((a, b) => a.summary.localeCompare(b.summary));
    localStorage?.setItem("allCalendars", JSON.stringify(state.allCalendars));
  },
  setCalendarsLoading(state, payload) {
    state.calendarsLoading = payload;
  },
  setUserSendAs(state, { id_google, userSendAs }) {
    state.userSendAs = {
      ...state.userSendAs,
      [id_google]: userSendAs,
    };
  },
  setUserGroups(state, { id_google, userGroups }) {
    state.userGroups = {
      ...state.userGroups,
      [id_google]: userGroups,
    };
  },
  setShowTasksProgress(state, value) {
    state.showTasksProgress = value;
  },

  setFirstSyncUsersStatus(state, value) {
    state.firstSyncUsersStatus = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
