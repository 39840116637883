import { getDatabase, ref, set, onValue } from "firebase/database";
import {
  sleep,
  getDriveMigrationStatistics,
} from "@/helpers/services/utils.js";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";

import { splitArray } from "@/helpers/services/utils";

const batchSize = 25;

function createHierarchy(rootId, folders) {
  let map = new Map();
  const hierarchy = {};
  for (const folder of folders) {
    map.set(folder.id, { ...folder, children: {} });
  }
  if (!map.has(rootId)) {
    map.set(rootId, {
      id: rootId,
      name: "root",
      mimeType: "application/vnd.google-apps.folder",
      parents: [],
      children: {},
    });
    hierarchy[rootId] = map.get(rootId);
  }
  for (const folder of folders) {
    const parentId = folder.parents[0];
    if (map.has(parentId)) {
      const parent = map.get(parentId);
      parent.children[folder.id] = map.get(folder.id);
    }
  }
  map = null;
  return hierarchy[rootId];
}

function traverseHierarchy(node, flat) {
  flat.push(node);
  const children = node.children;
  if (Object.keys(children || {}).length > 0) {
    for (const childId in children) {
      traverseHierarchy(children[childId], flat);
    }
  }
}

function buildBatches(artifacts) {
  let artifactsSplit = splitArray(
    artifacts.map((artifact) => ({
      ...artifact,
      status: "toDo",
      message: null,
    })),
    batchSize
  );
  const batchesEntries = artifactsSplit.map((artifacts, index) => {
    const id = `batch_${index}`;
    return [
      id,
      {
        id,
        name: `Lote ${index}`,
        status: "toDo",
        artifacts,
      },
    ];
  });
  artifactsSplit = null;
  return Object.fromEntries(batchesEntries);
}

function prepareBatches(artifacts, sharedfoldersCountMap) {
  return artifacts
    .filter(
      (artifact) => artifact.mimeType !== "application/vnd.google-apps.folder"
    )
    .map((artifact) => {
      const parentId = artifact.parents[0];
      const newParentId =
        sharedfoldersCountMap[parentId] || sharedfoldersCountMap.fallback;
      return {
        file: artifact,
        payload: {
          fileId: artifact.id,
          addParents: [newParentId],
          removeParents: artifact.parents.join(","),
        },
      };
    });
}

const state = () => ({
  allDriveProcesses: {},
  isInTransference: false,

  driveUserToken: {},
  migrateDriveUser: null,
  migrateDriveGroup: null,
  admin: null,
  sharedDrive: null,

  driveArtifacts: [],

  foldersCount: 0,
  foldersCreatedCount: 0,
  driveTimeoutId: null,

  runningDriveProcessPath: "",
  runningDriveBatchPath: "",
  driveProcessStatistics: [],

  loadingProcessCreation: false,
  loadingMigration: false,
});

const gapiClient = () => window.gapi?.client;
const setGAPIToken = (token) => gapiClient().setToken({ access_token: token });

const getters = {
  loadingDriveMigration: (state) =>
    state.loadingClient ||
    state.loadingCompanyToken ||
    state.loadingProcessCreation ||
    state.loadingMigration,
  driveProcessesPath: (_, { company }) =>
    `drive_transfer/${company.main_domain}`.replace(/(@|\.|#|\$|\[|\])/g, "_"),
  userDriveBatchesPath: (_, { migrateDriveUser }) =>
    `drive_transfer/batches/${migrateDriveUser.id_google}`,

  currentDriveUserToken: (state) => {
    const current = state.driveUserToken[state.migrateDriveUser?.key];
    if (current && Date.now() - current.time < 1000 * 60 * 5)
      return current.token;
  },

  migrateDriveUser: (state) => state.migrateDriveUser,

  sharedDrive: (state) => state.sharedDrive,

  driveArtifacts: (state) => state.driveArtifacts,

  foldersCount: (state) => state.foldersCount,
  foldersCreatedCount: (state) => state.foldersCreatedCount,
  allDriveProcesses: (state) => state.allDriveProcesses,
  userProcess: (state) =>
    state.allDriveProcesses[state.runningDriveProcessPath.split("/")[2]],

  hasProcessRunning: (state) =>
    Object.values(state.allDriveProcesses).some(
      (process) => process.status === "running"
    ),

  runningDriveProcessPath: (state) => state.runningDriveProcessPath,
  runningDriveBatchPath: (state) => state.runningDriveBatchPath,
  driveProcessStatistics: (state) => state.driveProcessStatistics,
  breakTransference: (state) =>
    !state.runningDriveBatchPath ||
    !state.runningDriveProcessPath ||
    !state.migrateDriveUser?.email ||
    !state.isInTransference,
};

const actions = {
  async getUserTokenDrive({ getters, commit }) {
    if (getters.currentDriveUserToken) {
      return setGAPIToken(getters.currentDriveUserToken);
    }
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/${getters.migrateDriveUser.key}/token`;
    const payload = { scopes: [INCREMENTAL_SCOPES.ADMIN_DRIVE] };
    const auth = {
      headers: {
        Authorization: getters.token,
      },
    };

    await this._vm.$axios
      .post(url, payload, auth)
      .then(({ data }) => {
        commit("setDriveUserToken", {
          userKey: getters.migrateDriveUser.key,
          token: data.token,
        });

        setGAPIToken(getters.currentDriveUserToken);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  async createSharedDrive(_, group) {
    const requestId = (await gapiClient().drive.files.generateIds({ count: 1 }))
      .result.ids[0];
    const params = {
      requestId,
      restrictions: {
        adminManagedRestrictions: false,
        copyRequiresWriterPermission: false,
      },
      name: group,
      capabilities: {
        canReadRevisions: true,
        canCopy: true,
        canShare: true,
        canTrashChildren: true,
        canListChildren: true,
        canDownload: true,
        canRename: true,
        canChangeCopyRequiresWriterPermissionRestriction: true,
        canDeleteDrive: true,
        canManageMembers: true,
        canChangeDriveMembersOnlyRestriction: true,
        canComment: true,
        canChangeDriveBackground: true,
        canEdit: true,
        canRenameDrive: true,
        canDeleteChildren: true,
        canAddChildren: true,
        canChangeDomainUsersOnlyRestriction: true,
      },
      properties: {
        "conecta-suite-drive-migration": "true",
        "conecta-suite-folder-id": group,
      },
      hidden: true,
    };
    let response;
    try {
      response = await gapiClient().drive.drives.create(params);
    } catch (err) {
      console.error(err);
      return err.result;
    }
    return response.result;
  },

  async setPermission(
    { getters },
    {
      role = "organizer" /* organizer, writer */,
      type = "group",
      emailAddress = "",
    }
  ) {
    const params = {
      fileId: getters.sharedDrive.id,
      type,
      emailAddress,
      role,
      useDomainAdminAccess: true,
      supportsAllDrives: true,
    };
    if (type === "user") {
      params.pendingOwner = true;
    }
    let response;
    try {
      response = await gapiClient().drive.permissions.create(params);
    } catch (err) {
      console.error(err);
      return;
    }
    return response.result.role === role;
  },

  async checkPermission({ getters, dispatch }, { group, user }) {
    const params = {
      pageSize: 100,
      fields: "kind,nextPageToken,permissions(id, emailAddress, role, type)",
      fileId: getters.sharedDrive.id,
      type: "group",
      emailAddress: group,
      useDomainAdminAccess: true,
      supportsAllDrives: true,
    };
    let retries = 0;
    let response;
    do {
      try {
        response = await gapiClient().drive.permissions.list(params);
      } catch (err) {
        response = err;
        await sleep(20000);
      } finally {
        retries++;
      }
    } while (response?.result?.error?.code === 403 && retries < 5);
    let groupIsOrganizer = false,
      userIsOrganizer = false;
    for (const { role, type, emailAddress } of response.result.permissions) {
      if (role === "organizer" && type === "group" && emailAddress === group)
        groupIsOrganizer = true;
      if (
        role === "organizer" &&
        type === "user" &&
        emailAddress === user.email
      )
        userIsOrganizer = true;
    }
    if (!groupIsOrganizer) {
      groupIsOrganizer = await dispatch("setPermission", {
        role: "organizer",
        type: "group",
        emailAddress: group,
      });
    }
    if (!userIsOrganizer) {
      userIsOrganizer = await dispatch("setPermission", {
        role: "organizer",
        type: "user",
        emailAddress: user.email,
      });
    }
    return groupIsOrganizer && userIsOrganizer;
  },

  async prepareSharedDrive({ getters, commit, dispatch }, { group, user }) {
    setGAPIToken(getters.companyToken);
    const params = {
      useDomainAdminAccess: true,
      q: `name = '${group}' and memberCount >= 1`,
    };
    let response;
    try {
      response = await gapiClient().drive.drives.list(params);
    } catch (err) {
      console.error(err);
      return;
    }
    const sharedDrive = response.result.drives.length
      ? response.result.drives[0]
      : await dispatch("createSharedDrive", group);
    if (sharedDrive?.error?.code) return sharedDrive;
    await commit("set.sharedDrive", sharedDrive);
    if (!(await dispatch("checkPermission", { group, user }))) return;
    return sharedDrive;
  },

  async listUserFiles({ getters, commit }) {
    setGAPIToken(getters.currentDriveUserToken);
    commit("set.driveArtifacts", []);
    const params = {
      pageSize: 100, // TODO: change to test pagination
      fields: "kind,nextPageToken,files(id, name, mimeType, parents)",
      q: `'me' in owners and trashed = false`,
      pageToken: null,
    };
    let response;
    do {
      try {
        response = await gapiClient().drive.files.list(params);
        commit(
          "set.driveArtifacts",
          getters.driveArtifacts.concat(response.result.files)
        );

        params.pageToken = response.result.nextPageToken;
      } catch (err) {
        console.error(err);
        response = false;
      }
    } while (response?.result?.nextPageToken);
  },

  async listUserSharedfoldersCount({ getters }) {
    setGAPIToken(getters.currentDriveUserToken);
    const params = {
      pageSize: 100,
      fields:
        "kind,nextPageToken,files(id, name, mimeType, parents, properties)",
      driveId: getters.sharedDrive.id,
      q: `mimeType = 'application/vnd.google-apps.folder' and trashed = false and properties has { key='conecta-suite-drive-migration' and value='true' }`,
      corpora: "drive",
      supportsAllDrives: true,
      includeItemsFromAllDrives: true,
      pageToken: null,
    };
    let response;
    let sharedFolders = [];
    do {
      try {
        response = await gapiClient().drive.files.list(params);
        sharedFolders = sharedFolders.concat(response.result.files);
        params.pageToken = response.result.nextPageToken;
      } catch (err) {
        console.error(err);
        response = false;
      }
    } while (response?.result?.nextPageToken);
    return sharedFolders;
  },

  async getRootFolder({ getters }) {
    setGAPIToken(getters.currentDriveUserToken);
    let rootId = null;
    try {
      const response = await gapiClient().drive.files.get({
        fileId: "root",
        supportsAllDrives: true,
      });
      rootId = response.result.id;
    } catch (err) {
      console.error(err);
      const fileNotFound = /File\snot\sfound:\s(.+?)\./;
      if (err.result.code === 404 && fileNotFound.test(err.result.message)) {
        rootId = fileNotFound.exec(err.result.message)[1];
      }
    }
    return rootId;
  },

  async createFolder({ getters, commit }, { id, name, parent }) {
    if (!parent) return;
    setGAPIToken(getters.currentDriveUserToken);
    const params = {
      driveId: getters.sharedDrive.id,
      mimeType: "application/vnd.google-apps.folder",
      name,
      parents: [parent],
      properties: {
        "conecta-suite-drive-migration": "true",
        "conecta-suite-folder-id": id,
      },
      useDomainAdminAccess: true,
      supportsAllDrives: true,
      fields: "kind,id, name, mimeType, parents",
    };
    let response;
    try {
      response = await gapiClient().drive.files.create(params);
      commit("set.foldersCreatedCount", getters.foldersCreatedCount + 1);
    } catch (err) {
      console.error(err);
      return response;
    }
    return response.result;
  },

  async buildFolders({ getters, commit, dispatch }, user) {
    const rootId = await dispatch("getRootFolder");
    const folders = getters.driveArtifacts.filter(
      (artifact) => artifact.mimeType === "application/vnd.google-apps.folder"
    );
    const sharedFolders = await dispatch("listUserSharedfoldersCount");
    const userRootFolder =
      sharedFolders.find(
        (folder) => folder.properties["conecta-suite-folder-id"] == user.email
      ) ||
      (await await dispatch("createFolder", {
        id: user.email,
        name: user.email,
        parent: getters.sharedDrive.id,
      }));
    const hierarchy = createHierarchy(rootId, folders);
    const sharedfoldersCountMap = {
      [rootId]: userRootFolder.id,
      fallback: userRootFolder.id,
    };
    const foldersOrdered = [];
    traverseHierarchy(hierarchy, foldersOrdered);
    foldersOrdered.shift();
    commit("set.foldersCount", foldersOrdered.length);
    for (const folder of foldersOrdered) {
      const parentId = folder.parents[0];
      const newParentId = sharedfoldersCountMap[parentId];
      const result =
        sharedFolders.find(
          (sDFolder) =>
            sDFolder.properties["conecta-suite-folder-id"] == folder.id
        ) ||
        (await await dispatch("createFolder", {
          id: folder.id,
          name: folder.name,
          parent: newParentId,
        }));
      if (result) sharedfoldersCountMap[folder.id] = result.id;
      else return;
    }
    return sharedfoldersCountMap;
  },

  async uploadDriveTransference(
    { getters, commit },
    { group, user, isOffboarding = false }
  ) {
    if (!user?.email || !group || !getters.company?.main_domain) return;

    const userToTransferDriveFiles = {
      name: user.name,
      email: user.email,
      id_google: user.id_google,
      key: user.key,
    };

    const author = {
      id_google: getters.currentUser.id_google,
      name: getters.currentUser.name,
      email: getters.currentUser.email,
    };

    const db = getDatabase();
    const processPath = `${user.email}_${group}`;
    const runningDriveProcessPath =
      `${getters.driveProcessesPath}/${processPath}`.replace(
        /(@|\.|#|\$|\[|\])/g,
        "_"
      );

    commit("set.runningDriveProcessPath", runningDriveProcessPath);

    const reference = ref(db, runningDriveProcessPath);

    const newProcess = {
      key: runningDriveProcessPath,
      name: `Transferência de arquivos de ${user.email} para ${group}`,
      user: userToTransferDriveFiles,
      author: author,
      group: group,
      sharedDrive: getters.sharedDrive,
      status: "toDo",
      createdAt: new Date().getTime(),
      createdBy: getters.currentUser.email,
      totalFiles: getters.driveArtifacts.length,
      transfered: 0,
      failed: 0,
      isOffboarding: isOffboarding,
    };
    set(reference, newProcess);
    commit("updateProcess", newProcess);

    const referenceBatches = ref(db, getters.userDriveBatchesPath);
    set(referenceBatches, buildBatches(getters.driveArtifacts));
  },

  async migrateFileToShared(_, file) {
    const params = {
      supportsAllDrives: true,
      useDomainAdminAccess: true,
    };
    try {
      await gapiClient().drive.files.update({
        ...file.payload,
        ...params,
      });
      file.status = "done";
    } catch (err) {
      console.error(err);
      file.status = "failed";
      try {
        file.message = JSON.stringify(err.result || {});
      } catch {
        file.message = "Não foi possível obter o erro";
      }
    }
    return file;
  },

  async migrateFilesToSharedParallel({ getters, dispatch }, batch) {
    setGAPIToken(getters.currentDriveUserToken);
    return await Promise.all(
      batch.map(async (file) => await dispatch("migrateFileToShared", file))
    );
  },

  setTransferDriveProgress(
    { getters, commit },
    { batch, failed = 0, transfered = 0 }
  ) {
    if (!batch?.id) return;

    const db = getDatabase();
    const batchReference = ref(
      db,
      `${getters.runningDriveBatchPath}/${batch.id}`
    );
    set(batchReference, {
      ...batch,
    });
    const processPath = getters.runningDriveProcessPath;

    const processFailedReference = ref(db, `${processPath}/failed`);
    const processTranferredReference = ref(db, `${processPath}/transfered`);
    let userProcessFailures = getters.userProcess.failed + failed;
    let userProcessTransfered = getters.userProcess.transfered + transfered;

    const processUpdated = {
      ...getters.userProcess,
      failed: userProcessFailures,
      transfered: userProcessTransfered,
    };
    commit("updateProcess", processUpdated);
    commit(
      "set.driveProcessStatistics",
      getDriveMigrationStatistics(getters.userProcess)
    );
    set(processFailedReference, userProcessFailures);
    set(processTranferredReference, userProcessTransfered);
  },

  async runDriveTransference({ getters, commit, dispatch }, { toDoBatches }) {
    commit("set.loadingMigration", true);
    commit(
      "set.driveProcessStatistics",
      getDriveMigrationStatistics(getters.userProcess)
    );

    let batch = toDoBatches[0] || {};

    if (batch?.id) {
      await dispatch("getUserTokenDrive");

      await dispatch("migrateFilesToSharedParallel", batch.artifacts);

      const failures =
        batch.artifacts.filter((artifact) => artifact.status === "failed")
          .length || 0;
      const successes =
        batch.artifacts.filter((artifact) => artifact.status === "done")
          .length || 0;
      const status = "done";
      batch.status = status;
      dispatch("setTransferDriveProgress", {
        batch,
        failed: failures,
        transfered: successes,
      });
      batch = null;
    }

    commit("set.loadingMigration", false);
  },

  async getDriveTransferences({ getters, commit }) {
    if (!getters.company?.main_domain) return;
    const db = getDatabase();
    const reference = ref(db, getters.driveProcessesPath);
    try {
      onValue(reference, (snapshot) => {
        if (snapshot.exists()) {
          commit(
            "set.allDriveProcesses",
            Object.fromEntries(
              Object.entries(snapshot.val()).filter(
                (entry) => entry[1].author.email == getters.currentUser.email
              )
            )
          );
        }
      });
    } catch (err) {
      console.error(err);
    }
  },

  endDriveTransference({ commit, dispatch }) {
    dispatch("setTransferDriveStatus", "done");
    dispatch("getDriveTransferences");
    commit("resetDriveProcess");
  },

  async createDriveTransference(
    { getters, commit, dispatch },
    { group, user, isOffboarding = false }
  ) {
    commit("set.loadingProcessCreation", true);
    commit("set.sharedDrive", null);
    commit("set.foldersCreatedCount", 0);
    commit("set.foldersCount", 0);
    commit("set.migrateDriveUser", user);
    commit("set.migrateDriveGroup", group);
    const scopes = [INCREMENTAL_SCOPES.ADMIN_DRIVE];
    await await dispatch("getCompanyGAPIToken", scopes);
    const result = await dispatch("prepareSharedDrive", { group, user });
    if (result?.error?.code === 403) {
      commit("set.loadingProcessCreation", false);
      return "driveMigration.errors.createSharedDrive";
    }
    if (!result) {
      commit("set.loadingProcessCreation", false);
      return "driveMigration.errors.prepareSharedDrive";
    }
    await await dispatch("getUserTokenDrive");
    await dispatch("listUserFiles");
    if (!getters.driveArtifacts.length) {
      commit("set.loadingProcessCreation", false);
      return "driveMigration.errors.listUserFiles";
    }
    const sharedfoldersCountMap = await dispatch("buildFolders", user);
    if (!sharedfoldersCountMap) {
      commit("set.loadingProcessCreation", false);
      return "driveMigration.errors.buildFolders";
    }
    commit(
      "set.driveArtifacts",
      prepareBatches(getters.driveArtifacts, sharedfoldersCountMap)
    );
    await dispatch("uploadDriveTransference", { group, user, isOffboarding });
    commit("set.loadingProcessCreation", false);
  },

  async startDriveTransference({ getters, commit, dispatch }, { group, user }) {
    if (!user?.email || !group || !getters.company?.main_domain) return;

    commit("setShowTasksProgress", true);
    commit("set.migrateDriveUser", user);
    commit("set.migrateDriveGroup", group);
    commit("set.isInTransference", true);
    commit("set.driveArtifacts", []);

    const processPath = `${user.email}_${group}`;
    const runningDriveProcessPath =
      `${getters.driveProcessesPath}/${processPath}`.replace(
        /(@|\.|#|\$|\[|\])/g,
        "_"
      );
    commit("set.runningDriveProcessPath", runningDriveProcessPath);

    const runningDriveBatchPath = `${getters.userDriveBatchesPath}`;
    commit("set.runningDriveBatchPath", runningDriveBatchPath);

    dispatch("setTransferDriveStatus", "running");
    const db = getDatabase();
    const roomRef = ref(db, runningDriveBatchPath);
    onValue(roomRef, (...args) =>
      dispatch("processDriveChangeHandlerDeb", ...args)
    );
  },

  processDriveChangeHandlerDeb({ commit, dispatch }, payload) {
    commit("clearDriveTimeoutId");

    commit(
      "set.driveTimeoutId",
      setTimeout(() => dispatch("processDriveChangeHandler", payload), 1000 * 3)
    );
  },
  processDriveChangeHandler({ getters, commit, dispatch }, payload) {
    // Data
    let driveBatches = payload.val() || {};

    if (getters.breakTransference) return;

    if (!gapiClient()) {
      return dispatch("processDriveChangeHandlerDeb");
    }

    if (getters.userProcess?.status === "done") {
      driveBatches = null;
      return 1;
    }

    let batches = Object.values(driveBatches || {});

    let toDoBatches = batches.filter((batch) => batch.status === "toDo");
    const batchesMissingCount = toDoBatches.length;

    commit(
      "set.driveProcessStatistics",
      getDriveMigrationStatistics(getters.userProcess)
    );

    driveBatches = null;
    batches = null;

    dispatch("runDriveTransference", {
      toDoBatches,
    });
    if (!batchesMissingCount) {
      dispatch("endDriveTransference");
    }

    if (!batchesMissingCount && getters.userProcess?.isOffboarding) {
      dispatch("setMigrationStatusProgress", {
        email: getters.migrateDriveUser.email,
        app_name: "drive",
        status: "completed",
      });
    }
    toDoBatches = null;
  },

  async leaveDriveProcessRoom({ commit, dispatch }, { status = "toDo" }) {
    if (!["toDo", "done"].includes(status)) return;
    await dispatch("setTransferDriveStatus", status);
    commit("resetDriveProcess");
  },

  setTransferDriveStatus({ getters, commit }, status = "toDo") {
    const db = getDatabase();
    if (!["toDo", "running", "done"].includes(status)) return;
    const path = getters.runningDriveProcessPath;
    const processStatusReference = ref(db, `${path}/status`);
    set(processStatusReference, status);
    const timestamp = new Date().getTime();
    const modifiedAtReference = ref(db, `${path}/modifiedAt`);
    set(modifiedAtReference, timestamp);
    commit("updateProcess", {
      ...getters.userProcess,
      status: status,
      modifiedAt: timestamp,
    });
  },
};

const mutations = {
  ...Object.fromEntries(
    Object.keys(state()).map((key) => [
      `set.${key}`,
      (state, payload) => {
        state[key] = payload;
      },
    ])
  ),
  clearDriveTimeoutId(state) {
    clearTimeout(state.driveTimeoutId);
  },

  setDriveUserToken(state, { userKey, token }) {
    state.driveUserToken = {
      ...state.driveUserToken,
      [userKey]: { token, time: Date.now() },
    };
  },

  updateProcess(state, process) {
    state.allDriveProcesses[state.runningDriveProcessPath.split("/")[2]] =
      process;
  },
  resetDriveProcess(state) {
    state.isInTransference = false;
    state.sharedDrive = null;
    state.loadingMigration = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

// // TODO: Este método é próprio da migração de drive
// async deleteSharedDrive() {
//   const driveId = "0ACh0G8ACn9SjUk9PVA";
//   const files = (
//     await this.client.drive.files.list({
//       driveId,
//       includeItemsFromAllDrives: true,
//       corpora: "drive",
//       supportsAllDrives: true,
//     })
//   ).result.files;
//   for (const file of files) {
//     await this.client.drive.files.delete({
//       fileId: file.id,
//       supportsAllDrives: true,
//     });
//   }
//   await this.client.drive.drives.delete({
//     driveId,
//     supportsAllDrives: true,
//   });
// },
// // TODO: Este método é próprio da migração de drive
// async getSingleFile() {
//   const fileId = "1mX_CIq49kZ5CrfutYi9rMYnSIfU1XTPZ";
//   await this.client.drive.files.get({
//     fileId,
//     supportsAllDrives: true,
//   });
// },
